import React from 'react'
import {
    Box,
    Card,
    CardMedia,
    Checkbox,
    Divider,
    FormControlLabel,
    TextField,
    Typography
}
    from "@mui/material";

import ReactHlsPlayer from '@ducanh2912/react-hls-player';

const Question = ({ id, title, victimType, color, height, src, noBoxes, answers, setAnswers }) => {

    const hurts = [['Financial Hurt', 'Emotional Hurt', 'Psychological Hurt'], ['Spiritual Hurt', "Secondary Hurt", 'Physical Hurt']];

    const handleCheckboxChange = (columnIndex, rowIndex) => (event) => {
        // console.log('handle change');
        let newAnswers = structuredClone(answers);
        newAnswers.checkboxes[id][columnIndex][rowIndex] = event.target.checked;
        setAnswers(newAnswers);
    }

    const handleTextfieldChange = (columnIndex, rowIndex) => event => {
        let newAnswers = structuredClone(answers);
        newAnswers.textfields[id][columnIndex][rowIndex] = event.target.value
        setAnswers(newAnswers);
    }

    return (
        <Box sx={{
            paddingTop: 0,
            paddingLeft: 2,
            paddingRight: 2,
            background: color,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            height: { xs: height, md: "1000px" },
            width: { xs: "100%" },
        }}
            maxWidth="false">
            <Box sx={{ background: "", }}>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", mt: "20px" }}>
                    <Typography
                        sx={{
                            font: "font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 20px/var(--unnamed-line-spacing-20) var(--unnamed-font-family-frutiger-lt-std)",
                            fontSize: "20px",
                            textAlign: "center"
                        }}>
                        {title}
                    </Typography>
                    <Box
                        sx={{ width: { xs: "100%", md: "720px" }, height: { xs: "100%", md: "405px" }, mb: '50px' }}
                    >
                        <ReactHlsPlayer
                            // src="https://unity-dashboard.s3.ap-southeast-1.amazonaws.com/videos/Live/CostofCrime_Ex-Probationer_Experience/CostofCrime_Ex-Probationer_Experiencemediaconvert_8500kbits.m3u8"
                            src={src}
                            autoPlay={false}
                            controls={false}
                            width="100%"
                        />
                    </Box>
                </Box>

                <Box sx={{ width: "100%", background: "", display: 'flex', flexDirection: "column", justifyContent: "left", alignItems: "left" }}>
                    <Typography textAlign={'left'} sx={{ fontWeight: "bold", fontSize: "20px" }}>
                        {victimType}
                    </Typography>
                    <Typography>
                        {victimType === "Secondary Victim" &&
                            <>
                                Identify the type of hurt that the secondary victim suffered and give an example.<span style={{ color: "red" }}>*</span>
                            </>}

                        {victimType === "Indirect/Hidden victims" &&
                            <>
                                Identify the type of hurt that the indirect/hidden victim suffered and give an example.<span style={{ color: "red" }}>*</span>
                            </>}
                        {victimType === "Primary Victim" &&
                            <>
                                Identify the type of hurt that the primary victim suffered and give an example.<span style={{ color: "red" }}>*</span>
                            </>}

                    </Typography>
                </Box>
                <Box sx={{ background: "", mt: "20px", display: "flex", flexDirection: { xs: "column", md: "row" }, justifyContent: "space-between", mb: "20px" }}>
                    <Box sx={{ background: "", display: "flex", flexDirection: "column", width: "100%" }}>
                        {hurts[0].map((hurt, i) => {
                            return (
                                <Box key={`${(0 + 1) * i}`} sx={{ display: "flex", flexDirection: "column" }}>
                                    <FormControlLabel control={<Checkbox checked={answers?.checkboxes[id][0][i]} onChange={handleCheckboxChange(0, i)} />} label={hurt} />
                                    <TextField
                                        disabled={!answers?.checkboxes[id][0][i]}
                                        sx={{ background: "white" }}
                                        onChange={handleTextfieldChange(0, i)}
                                        value={answers?.textfields[id][0][i]}
                                    ></TextField>
                                    <Divider sx={{ height: "15px", width: "0px" }} />
                                </Box>
                            )
                        })}
                    </Box>
                    <Divider sx={{ width: "3.5%" }} />
                    <Box sx={{ background: "", display: "flex", flexDirection: "column", width: "100%" }}>
                        {hurts[1].map((hurt, i) => {
                            return (
                                <Box key={`${(1 + 1) * i}`} sx={{ display: "flex", flexDirection: "column" }}>
                                    <FormControlLabel control={<Checkbox checked={answers?.checkboxes[id][1][i]} onChange={handleCheckboxChange(1, i)} />} label={hurt} />
                                    <TextField
                                        disabled={!answers?.checkboxes[id][1][i]}
                                        sx={{ background: "white" }}
                                        onChange={handleTextfieldChange(1, i)}
                                        value={answers?.textfields[id][1][i]}
                                    ></TextField>
                                    <Divider sx={{ height: "15px", width: "0px" }} />
                                </Box>
                            )
                        })}
                    </Box>
                </Box>
                <span style={{ color: "red" }}>*</span>{noBoxes > 1 ?
                    `Please fill in at least ${noBoxes} boxes.` :
                    'Please fill in at least 1 box.'}
            </Box>
        </Box>
    )
}

export default Question