import React from 'react'
import {
    Box,
    Button,
    Divider,
    Typography,
    } from "@mui/material";
    
import useModal from 'src/helpers/useModal';
import { useNavigate } from "react-router-dom"

const SceneCompletedPopup = ({title, text, allCompleted}) => {

    const navigate = useNavigate();
    const { closeModal } = useModal();

    return (
        <Box sx={{background: '', height:{xs: '220px', md: "fit-content"}, width: '100%', display:"flex", justifyContent:"left", alignItems:"left", flexDirection:"column"}}>
            <Typography sx={{fontWeight: 'bold', fontSize: '22px'}}>
                {title}
            </Typography>
            <Divider sx={{margin:'5px', width:'0px'}}/>
            <Typography sx={{fontSize: {xs: '18px', md: '16px'}}}>
                {text}
            </Typography>
            <Box sx={{background: '', display:{xs: 'none', md: 'flex'}, justifyContent:'center', alignItems:'center', flexDirection: 'column'}}>
                
                <Divider sx={{height:'1px', width: '100%', mt: '15px', mb: '15px', background: '#707070'}}/>
                
                {allCompleted? 
                    (
                        <>
                            <Typography sx={{display: 'flex', textAlign: 'center', color: '#259F00'}}>
                                You have completed all 5 scenes.
                            </Typography>
                            <Typography sx={{display: 'flex', textAlign: 'center', color: '#259F00'}}>
                                Please proceed to the Learn section of Law & Order.
                            </Typography>
                        </>
                        
                    ) : 
                    (
                        <>
                            <Typography sx={{display: 'flex', textAlign: 'center', color: '#259F00'}}>
                                You have completed this scene.
                            </Typography>
                            <Typography sx={{display: 'flex', textAlign: 'center', color: '#259F00'}}>
                                Now let’s go back to choose another scene.
                            </Typography>
                        </>
                    )
                }
            </Box>

            <Box sx={{display:'flex', justifyContent:'center', mt:'20px'}}>
                <Button
                    sx={{fontSize:"16px", width:"181px", height:"39px" }}
                    variant="rounded"
                    fullWidth
                    size="small"
                    onClick={allCompleted?
                        () => {
                            navigate("/players/law-and-order");
                            closeModal()
                        }
                    :
                        () => {
                            navigate("/players/law-and-order/scene-selection")
                            closeModal()
                        }
                    }
                >
                    CONTINUE
                </Button>
            </Box>
            
        </Box>
    )
}

export default SceneCompletedPopup;