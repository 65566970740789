import React from 'react'
import {
    Button,
    Box,
    Typography,
    } from "@mui/material";

import useModal from 'src/helpers/useModal';

import { default as stopSignLogo } from "src/assets/icons/ic-stop-sign.svg";

export const LockedPopup = ({ text }) => {

    const { closeModal } = useModal();

    return (
            <>
                <Box sx={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                    <Box sx={{background: "", display: "flex", justifyContent:"center", height:"45px", width:"45px"}}>
                        <img
                            src={stopSignLogo}
                            alt="Stop Sign"
                        />
                    </Box>
                    <br/>
                    <Typography align="center" fontSize="14pt" sx={{color:"#000000"}}>
                        This section is locked.<br/>
                        Please start with {text}.
                    </Typography>
                    <br></br>
                    <Button
                        color="green"
                        variant="contained"
                        type="submit"
                        sx={{fontSize:"14pt", width:{ xs:"234px", md: "135px"}}}
                        onClick={() => closeModal()}
                    >
                        OK
                    </Button>
                </Box>
            </>
    )
}

export default LockedPopup;