import { React, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
    Box,
    Button,
    Drawer,
    Icon,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Typography,
} from "@mui/material";
import {
    IcBandages,
    IcBandagesInactive,
    IcHome,
    IcLaw,
    IcLawInactive,
    IcLegalHammer,
    IcLegalHammerInactive,
    IcOfficer,
    IcPeoples,
    IcPeoplesInactive,
    IcPolice,
    IcPoliceInactive,
    IcPsychology,
    IcPsychologyInactive,
    IcReport,
    IcReportDocs,
    IcReportDocsInactive,
    IcShakeHand,
    IcShakeHandInactive,
    IcAccount,
    IcUser
} from "src/assets/icons";
import { TbAlertOctagon } from "react-icons/tb";

import useAuth from "src/hooks/useAuth";
import useSession from "src/hooks/useSession";
import { getGameProgressByProbationerId } from "src/services/gameServices";
import {
    getLawAndOrder,
    getLawAndOrderProgress,
} from "src/services/lawandorderServices";
import {
    getVictimImpact,
    getVictimImpactProgress
} from "src/services/victimimpactServices";

const officerMenus = [
    {
        name: "ACCOUNTS",
        menus: [
            {
                name: "Clients",
                icon: IcUser,
                link: "/officers/clients",
                height: "45px",
            },
            {
                name: "Officers",
                icon: IcOfficer,
                link: "/officers/officers",
                height: "45px",
            },
        ],
    },
    {
        name: "DOWNLOAD",
        menus: [
            {
                name: "Reports",
                icon: IcReport,
                link: "/officers/reports",
                height: "45px",
            },
        ],
    },
];

const clientMenus = [
    {
        name: 'MAIN',
        hideTitle: true,
        menus: [
            {
                name: "Home",
                icon: IcHome,
                link: "/players/home",
                height: "32px",
            },
            {
                name: "Profile",
                icon: IcAccount,
                link: "/players/profile",
                height: "32px",
            },
        ],
    },
    {
        name: "EVALUATION QUESTIONAIRE",
        menus: [
            {
                name: "VI & LO Evaluation",
                icon: IcReportDocs,
                iconInactive: IcReportDocsInactive,
                link: "/players/law-and-order/final-questionnaire",
                height: "36px",
            },
        ],
    },
];

const Sidebar = (props) => {

    const { drawerWidth, window, mobileOpen, handleDrawerToggle, user } = props;

    const [viData, setVIData] = useState([]);
    const [loData, setLOData] = useState([]);
    const { auth, setAuth } = useAuth();
    const { session, setSession } = useSession();

    const navigate = useNavigate();
    const location = useLocation();

    const grey = false;

    const container =
        window !== undefined ? () => window().document.body : undefined;

    const handleLogout = () => {
        setAuth(false);
        navigate('/officer-login')
    }

    useEffect(() => {
        const fetchData = async () => {
            const newSession = structuredClone(session);
            // set gameProgress in session context
            const gameProgressResponse = await getGameProgressByProbationerId(auth.probationer.probationerId);
            console.log({ gR: gameProgressResponse.data })
            if (gameProgressResponse.data.success) {
                newSession.gameProgress = gameProgressResponse.data.value.gameProgress;
            }
            // pull Sidebar data
            const viresponse = await getVictimImpact();
            if (viresponse.data.success) {
                setVIData(viresponse.data.value);
            }
            const loresponse = await getLawAndOrder();
            if (loresponse.data.success) {
                setLOData(loresponse.data.value);
            }

            // Get Sidebar VI/LO Progress
            const viProgress = await getVictimImpactProgress(auth.probationer.probationerId);
            // console.log(viProgress);
            if (viProgress.data.success) {
                if (!(viProgress.data == null || viProgress.data.value == null || viProgress.data.value.VI_progress == null)) {
                    newSession.VI_progress = viProgress.data.value.VI_progress;
                } else {
                    newSession.VI_progress = 0;
                }
            }
            const loProgress = await getLawAndOrderProgress(auth.probationer.probationerId);
            if (loProgress.data.success) {
                if (!(loProgress.data == null || loProgress.data.value == null || loProgress.data.value.LO_progress == null)) {
                    newSession.LO_progress = loProgress.data.value.LO_progress;
                } else {
                    newSession.LO_progress = 0;
                }
            }
            await setSession(newSession);
        }
        fetchData();
    }, []);

    const drawer = user === 1 ? ( // player: 0, officer: 1
        <div>
            <Box sx={{ background: "", textAlign: "center", mt: "30px", mb: "40px" }}>
                <Typography sx={{ fontSize: "18px" }} variant="h3" color="white">
                    {'probationer' in auth ? auth.probationer.name : ''}
                </Typography>
                <Button
                    variant="contained"
                    size="medium"
                    onClick={handleLogout}
                    sx={{
                        backgroundColor: "#2F4E91",
                        textTransform: "capitalize",
                        borderRadius: "50px",
                        height: "26px",
                        fontWeight: "600",
                        mt: "10px",
                    }}
                >
                    Logout
                </Button>
                {officerMenus?.slice()?.map((menu) => (
                    <List
                        sx={{ textAlign: "left", mt: "12px", ml: "-15px" }}
                        key={menu.name}
                        subheader={
                            <ListSubheader
                                component="div"
                                sx={{
                                    backgroundColor: "transparent",
                                    color: "#4FAFCD",
                                    lineHeight: "20px",
                                    marginTop: "8px",
                                    marginBottom: "8px",
                                    fontSize: "16px",
                                    marginLeft: "10px",
                                }}
                            >
                                {!menu.hideTitle && menu.name}
                            </ListSubheader>
                        }
                    >
                        {menu?.menus?.map((menu) => (
                            <ListItem
                                sx={{ background: "", height: menu.height, color: grey ? '#2F4E91' : "#FFFFFF" }}
                                key={menu.name}
                                disablePadding
                                selected={menu.link === location.pathname}
                                classes={{ selected: { background: "#FFFFFF" } }}
                            >
                                <ListItemButton
                                    sx={{ height: menu.height, background: "" }}
                                    component={Link}
                                    to={menu.link || ""}
                                >
                                    <ListItemIcon sx={{ display: 'flex', justifyContent: "center", alignItems: 'center', flexWrap: 'wrap', background: "", height: "40px" }}>
                                        <Icon style={{ color: 'red', display: "flex", justifyContent: "center", alignItems: "center", width: "35px", height: "35px", background: "" }}>
                                            <img src={grey ? menu.iconInactive : menu.icon} width="33px" alt="User Icon" />
                                        </Icon>
                                    </ListItemIcon>
                                    <ListItemText sx={{ background: "" }} primary={menu.name} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                ))}

            </Box>
            {/* <a
                href="https://form.gov.sg/64eebbf58591b60012440bd4"
                style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                    color: "#4FAFCD",
                    fontSize: "14px",
                    marginLeft: 10
                }}
            >Report an issue</a> */}

            <ListItem
                sx={{ background: "", height: 45, color: grey ? '#2F4E91' : "#FFFFFF" }}

                disablePadding

                classes={{ selected: { background: "#FFFFFF" } }}
            >
                <ListItemButton
                    sx={{ height: 45, background: "" }}
                    component={Link}
                    to={"https://form.gov.sg/64eebbf58591b60012440bd4"}
                >
                    <ListItemIcon sx={{ display: 'flex', justifyContent: "center", alignItems: 'center', flexWrap: 'wrap', background: "", height: "40px" }}>
                        <TbAlertOctagon style={{ color: 'red', display: "flex", justifyContent: "center", alignItems: "center", width: "35px", height: "35px", background: "" }} />
                    </ListItemIcon>
                    <ListItemText sx={{ background: "" }} primary={"Report an issue"} />
                </ListItemButton>
            </ListItem>
        </div>
    )
        : (
            <div>
                <Box sx={{ background: "", textAlign: "center", mt: "30px", mb: "40px" }}>
                    <Typography sx={{ fontSize: "18px" }} variant="h3" color="white">
                        {'probationer' in auth ? auth.probationer.name : ''}
                    </Typography>
                    <Button
                        variant="contained"
                        size="medium"
                        onClick={handleLogout}
                        sx={{
                            backgroundColor: "#2F4E91",
                            textTransform: "capitalize",
                            borderRadius: "50px",
                            height: "26px",
                            fontWeight: "600",
                            mt: "10px",
                        }}
                    >
                        Logout
                    </Button>
                    <List
                        sx={{ textAlign: "left", mt: "12px", ml: "-15px" }}
                        subheader={
                            <ListSubheader
                                component="div"
                                sx={{
                                    backgroundColor: "transparent",
                                    color: "#4FAFCD",
                                    lineHeight: "20px",
                                    marginTop: "8px",
                                    marginBottom: "8px",
                                    fontSize: "16px",
                                    marginLeft: "10px",
                                }}
                            >
                            </ListSubheader>
                        }
                    >
                        {clientMenus[0]?.menus?.slice()?.map(menu => (
                            <ListItem
                                sx={{ background: "", height: menu.height, color: grey ? '#2F4E91' : "#FFFFFF" }}
                                key={menu.name}
                                disablePadding
                                selected={menu.link === location.pathname}
                                classes={{ selected: { background: "#FFFFFF" } }}
                            >
                                <ListItemButton
                                    sx={{ height: menu.height, background: "" }}
                                    component={Link}
                                    to={menu.link || ""}
                                >
                                    <ListItemIcon sx={{ display: 'flex', justifyContent: "center", alignItems: 'center', flexWrap: 'wrap', background: "", height: "40px" }}>
                                        <Icon style={{ color: 'red', display: "flex", justifyContent: "center", alignItems: "center", width: "35px", height: "35px", background: "" }}>
                                            <img src={grey ? menu.iconInactive : menu.icon} width="33px" alt="User Icon" />
                                        </Icon>
                                    </ListItemIcon>
                                    <ListItemText sx={{ background: "" }} primary={menu.name} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                        <ListSubheader
                            component="div"
                            sx={{
                                backgroundColor: "transparent",
                                color: "#4FAFCD",
                                lineHeight: "20px",
                                marginTop: "8px",
                                marginBottom: "8px",
                                fontSize: "16px",
                                marginLeft: "10px",
                            }}
                        >
                            VICTIM IMPACT
                        </ListSubheader>
                        {viData?.slice(0, 'VI_progress' in session ? session.VI_progress : 1)?.map(section => (
                            <ListItem
                                sx={{ background: "", color: grey ? '#2F4E91' : "#FFFFFF" }}
                                key={section.applicationlearningId}
                                disablePadding
                                selected={section.link === location.pathname}
                                classes={{ selected: { background: "#FFFFFF" } }}
                            >
                                <ListItemButton
                                    sx={{ height: '36px', background: "" }}
                                    onClick={() => navigate(section.link, { state: { id: section.applicationlearningId } })}
                                >
                                    <ListItemIcon sx={{ display: 'flex', justifyContent: "center", alignItems: 'center', flexWrap: 'wrap', background: "", height: "40px" }}>
                                        <Icon style={{ color: 'red', display: "flex", justifyContent: "center", alignItems: "center", width: "35px", height: "35px", background: "" }}>
                                            <img src={section?.imageurl} width="33px" alt="User Icon" />
                                        </Icon>
                                    </ListItemIcon>
                                    <ListItemText sx={{ background: "" }} primary={section.title} />
                                </ListItemButton>
                            </ListItem>
                        ))}

                        <ListSubheader
                            component="div"
                            sx={{
                                backgroundColor: "transparent",
                                color: "#4FAFCD",
                                lineHeight: "20px",
                                marginTop: "8px",
                                marginBottom: "8px",
                                fontSize: "16px",
                                marginLeft: "10px",
                            }}
                        >
                            {session.gameProgress === "LO" || session.gameProgress === "EV" ? 'LAW & ORDER' : ''}
                        </ListSubheader>
                        {session.gameProgress === "LO" || session.gameProgress === "EV" ? loData?.slice(0, 'LO_progress' in session ? session.LO_progress : 1)?.map(section => (
                            <ListItem
                                sx={{ background: "", color: grey ? '#2F4E91' : "#FFFFFF" }}
                                key={section.applicationlearningId}
                                disablePadding
                                selected={section.link === location.pathname}
                                classes={{ selected: { background: "#FFFFFF" } }}
                            >
                                <ListItemButton
                                    sx={{ height: '36px', background: "" }}
                                    onClick={() => {
                                        navigate(section.link, { state: { id: section.applicationlearningId } })
                                    }}
                                >
                                    <ListItemIcon sx={{ display: 'flex', justifyContent: "center", alignItems: 'center', flexWrap: 'wrap', background: "", height: "40px" }}>
                                        <Icon style={{ color: 'red', display: "flex", justifyContent: "center", alignItems: "center", width: "35px", height: "35px", background: "" }}>
                                            <img src={section?.imageurl} width="33px" alt="User Icon" />
                                        </Icon>
                                    </ListItemIcon>
                                    <ListItemText sx={{ background: "" }} primary={section.title} />
                                </ListItemButton>
                            </ListItem>
                        ))
                            :
                            <></>
                        }
                        <ListSubheader
                            component="div"
                            sx={{
                                backgroundColor: "transparent",
                                color: "#4FAFCD",
                                lineHeight: "20px",
                                marginTop: "8px",
                                marginBottom: "8px",
                                fontSize: "16px",
                                marginLeft: "10px",
                            }}
                        >
                            {session.gameProgress === "EV" ? 'EVALUATION QUESTIONNAIRE' : ''}
                        </ListSubheader>
                        <ListItem
                            sx={{ background: "", color: grey ? '#2F4E91' : "#FFFFFF" }}
                            disablePadding
                            // selected={section.link === location.pathname}
                            classes={{ selected: { background: "#FFFFFF" } }}
                        >
                            <ListItemButton
                                sx={{ height: '36px', background: "" }}
                                onClick={() => navigate('/players/final-evaluation')}
                            >
                                <ListItemIcon sx={{ display: 'flex', justifyContent: "center", alignItems: 'center', flexWrap: 'wrap', background: "", height: "40px" }}>
                                    <Icon style={{ color: 'red', display: session.gameProgress === "EV" ? "flex" : "none", justifyContent: "center", alignItems: "center", width: "35px", height: "35px", background: "" }}>
                                        <img src={IcReportDocs} width="33px" alt="User Icon" />
                                    </Icon>
                                </ListItemIcon>
                                <ListItemText sx={{ background: "" }} primary={session.gameProgress === "EV" ? "VI & LO Evaluation" : ""} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
                {/* <a
                    href="https://form.gov.sg/64eebbf58591b60012440bd4"
                    style={{
                        background: "transparent",
                        border: "none",
                        outline: "none",
                        cursor: "pointer",
                        color: "#4FAFCD",
                        fontSize: "14px",
                        marginLeft: 10
                    }}
                >Report an issue</a> */}
            </div>
        )

    return (
        <Box
            component="nav"
            sx={{ width: { md: props.aspectRatio < 1.439 ? 0 : drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="Navigation menus"
        >
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                PaperProps={{
                    sx: {
                        backgroundColor: "#193465",
                    },
                }}
                sx={{
                    display: props.aspectRatio < 1.439 ?
                        {
                            xs: "block",
                            // sm: "none",
                            sm: "block",
                            md: "block",
                        }
                        :
                        {
                            xs: "block",
                            // sm: "none",
                            sm: "block",
                            md: "none",
                        },
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: drawerWidth,
                    },
                }}
            >
                {drawer}
            </Drawer>
            <Drawer
                variant="permanent"
                PaperProps={{
                    sx: {
                        backgroundColor: "#193465",
                    },
                }}
                sx={{
                    display: props.aspectRatio > 1 ?
                        {
                            xs: "none",
                            sm: "none",
                            md: props.aspectRatio > 1.439 ? "block" : 'none',
                        }
                        :
                        {
                            xs: "none",
                            // sm: "block",
                            sm: "none",
                            md: props.aspectRatio > 1.439 ? "block" : 'none',
                        },
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: drawerWidth,
                    },
                }}
                open
            >
                {drawer}
            </Drawer>
        </Box>
    );
};

export default Sidebar;
