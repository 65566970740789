import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

import { TextField, Button, Box, Container, Typography, SliderValueLabel } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import useAlert from "src/helpers/useAlert";
import useModal from "src/helpers/useModal";
import { create2FARecovery, get2FARecoveryStatus, get2FASetUpStatus, login, setUp2FA, verify2FACode, verify2FARecovery } from "src/services/authServices";
import useAuth from "src/hooks/useAuth";
import { IcStopSign } from "src/assets/icons";
import { authenticator } from "otplib";
import QRCode from "qrcode";
import PasswordResetPopup from "./PasswordResetPopup";

const validationSchema = yup.object({
    username: yup
        .string("Enter your email")
        .email("Enter a valid email")
        .required("Email is required"),
    password: yup
        .string("Enter your password")
        .min(8, "Password should be of minimum 8 characters length")
        .required("Password is required"),
});

const validationSchema2FA = yup.object({
    code: yup
        .string("Enter your code")
        .min(6, "Code should be of 6 characters length")
        .required("Code is required"),
});

const validationSchemaRecovery = yup.object({
    recoveryCode: yup
        .string("Enter your recovery code")
        .min(6, "Recovery code should be of 6 characters length")
        .required("Recovery code is required"),
});


const OfficerLogin = () => {
    const { setAuth } = useAuth();
    const { setAlert } = useAlert();
    const { setModal } = useModal();
    const [pageState, setPageState] = useState("login") // login, setup2FA, verify2FA, setuprecovery, verifyrecovery
    const [secretKey, setSecretKey] = useState("")
    const [qrCodeURL, setQRCodeURL] = useState("")

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            username: "",
            password: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            console.log("request: officer")
            const response = await login(values);
            const { success, error, value } = response.data;
            console.log({success,error,value})
            if (success && (value.isSuperAdmin || value.officer.officerId != null)) {
                // console.log({ value })
                // if (value.isSuperAdmin) {
                    let response = await get2FASetUpStatus();
                    let { success, error, value } = response.data;
                    if (success) {
                        if (JSON.parse(value)) {

                            setPageState("verify2FA")
                        } else {
                            const secretKey = authenticator.generateSecret();
                            const username = values.username;
                            QRCode.toDataURL(
                                authenticator.keyuri(username, "MSF Probationer Journey", secretKey)
                            ).then((url) => {
                                setQRCodeURL(url);
                                setSecretKey(secretKey);
                            });
                            setPageState("setup2FA")
                        }
                    }
                // } else {
                //     setAuth({ accessToken: value.token, roles: [1] });
                //     navigate('/officers/clients')
                // }



            } else {
                setAlert({
                    icon: IcStopSign,
                    title: "Try Again",
                    // description: error,
                    buttonText: "TRY AGAIN",
                    type: "ERROR",
                });
            }
        },
    });

    const formik2FA = useFormik({
        initialValues: {
            code: "",
        },
        validationSchema: validationSchema2FA,
        onSubmit: async (values) => {
            const response = await setUp2FA({ secret_key: secretKey, code: values.code });
            const { success, error, value } = response.data;
            if (success) {
                setPageState("setuprecovery")
            } else {
                setAlert({
                    icon: IcStopSign,
                    title: "Try Again",
                    // description: error,
                    buttonText: "TRY AGAIN",
                    type: "ERROR",
                });
            }
        },
    });

    const formik2FAVerify = useFormik({
        initialValues: {
            code: "",
        },
        validationSchema: validationSchema2FA,
        onSubmit: async (values) => {
            const response = await verify2FACode({ code: values.code });
            const { success, error, value } = response.data;
            if (success) {
                setAuth({ accessToken: value.token, roles: [1] });
                navigate('/officers/clients')
            } else {
                setAlert({
                    icon: IcStopSign,
                    title: "Try Again",
                    // description: error,
                    buttonText: "TRY AGAIN",
                    type: "ERROR",
                });
            }
        },
    });

    const formikCreateRecovery = useFormik({
        initialValues: {
            recoveryCode: "",
        },
        validationSchema: validationSchemaRecovery,
        onSubmit: async (values) => {
            console.log(values)
            const response = await create2FARecovery({ recovery_code: values.recoveryCode });
            const { success, error, value } = response.data;
            console.log(response.data)
            if (success) {
                setAuth({ accessToken: value.token, roles: [1] });
                navigate('/officers/clients')
            } else {
                setAlert({
                    icon: IcStopSign,
                    title: "Try Again",
                    // description: error,
                    buttonText: "TRY AGAIN",
                    type: "ERROR",
                });
            }
        },
    });

    const formikVerifyRecovery = useFormik({
        initialValues: {
            recoveryCode: "",
        },
        validationSchema: validationSchemaRecovery,
        onSubmit: async (values) => {
            const response = await verify2FARecovery({ recovery_code: values.recoveryCode });
            const { success, error, value } = response.data;
            if (success) {
                setAuth({ accessToken: value.token, roles: [1] });
                navigate('/officers/clients')
            } else {
                setAlert({
                    icon: IcStopSign,
                    title: "Try Again",
                    // description: error,
                    buttonText: "TRY AGAIN",
                    type: "ERROR",
                });
            }
        },
    });


    return (
        <Container sx={styles.container}>
            <img
                width="300px"
                src={require("src/assets/common/msf-logo.png").default}
                alt="MSF Logo"
            />
            <Typography variant="h2" color="black" sx={{ mb: "20px" }}>
                Probation and Community Rehabilitation Service <br /> My
                Probation Journey
            </Typography>
            {pageState === "login" ? <Box sx={styles.box}>
                <Typography variant="h2" color="white" sx={{ mb: "10px" }}>
                    Officer Login
                </Typography>
                <form onSubmit={formik.handleSubmit}>
                    <TextField
                        fullWidth
                        id="email"
                        name="username"
                        label="Email"
                        variant="filled"
                        size="small"
                        sx={styles.input}
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.username && Boolean(formik.errors.username)
                        }
                        helperText={formik.touched.username && formik.errors.username}
                    />
                    <TextField
                        fullWidth
                        id="password"
                        name="password"
                        label="Password"
                        type="password"
                        variant="filled"
                        size="small"
                        sx={styles.input}
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.password &&
                            Boolean(formik.errors.password)
                        }
                        helperText={
                            formik.touched.password && formik.errors.password
                        }
                    />
                    <Button
                        color="green"
                        variant="contained"
                        fullWidth
                        type="submit"
                        sx={{ fontSize: '17px' }}
                    >
                        LOGIN
                    </Button>
                    <Button
                        sx={{
                            fontSize: '16px',
                            textTransform: 'none',
                            color: "#FFFFFF",
                            marginTop: "5px",
                            ml: '-5px',
                            display: "block",
                            textDecoration: "underline"
                        }}
                        onClick={async () => {
                            setModal(
                                <>
                                    <PasswordResetPopup />
                                </>
                                , '462px');
                        }}
                    >
                        Reset Password
                    </Button>
                </form>
                <Box sx={styles.illustration2}>
                    <img
                        style={{ width: "100%" }}
                        src={require("src/assets/illustration/Girl-Report.png").default}
                        alt="Girl Report"
                    />
                </Box>
                <Box sx={styles.illustration1}>
                    <img
                        style={{ width: "100%" }}
                        src={require("src/assets/illustration/boy-backpack.png").default}
                        alt="Boy Backpack"
                    />
                </Box>

            </Box> : null}

            {pageState === "setup2FA" ? <Box sx={{ ...styles.box, height: "auto" }}>
                <Typography variant="h2" color="white" sx={{ mb: "10px" }}>
                    Setup 2FA
                </Typography>
                <Typography variant="p" color="white" sx={{ mb: "10px" }}>
                    Scan the QR Code with your Authenticator App
                </Typography>
                <img src={qrCodeURL} alt="QR Code" style={{ width: "100%", marginTop: "10px" }} />
                <form onSubmit={formik2FA.handleSubmit}>

                    <TextField
                        fullWidth
                        id="code"
                        name="code"
                        label="Code"
                        variant="filled"
                        size="small"
                        sx={styles.input}
                        value={formik2FA.values.code}
                        onChange={formik2FA.handleChange}
                        error={
                            formik2FA.touched.code && Boolean(formik2FA.errors.code)
                        }
                        helperText={formik2FA.touched.code && formik2FA.errors.code}
                    />
                    <Button
                        color="green"
                        variant="contained"
                        fullWidth
                        type="submit"
                        sx={{ fontSize: '17px' }}
                    >

                        SUBMIT
                    </Button>


                </form>
                <Box sx={styles.illustration2}>
                    <img
                        style={{ width: "100%" }}
                        src={require("src/assets/illustration/Girl-Report.png").default}
                        alt="Girl Report"
                    />
                </Box>
                <Box sx={styles.illustration1}>
                    <img
                        style={{ width: "100%" }}
                        src={require("src/assets/illustration/boy-backpack.png").default}
                        alt="Boy Backpack"
                    />
                </Box>

            </Box> : null}

            {pageState === "verify2FA" ? <Box sx={{ ...styles.box, height: "auto" }}>
                <Typography variant="h2" color="white" sx={{ mb: "10px" }}>
                    Verify 2FA
                </Typography>
                <Typography variant="p" color="white" sx={{ mb: "10px" }}>
                    Enter the code from your Authenticator App or click <span><a style={{ textDecoration: "underline" }} onClick={() => setPageState("verifyrecovery")}>here</a></span> to use Recovery Key
                </Typography>
                <form onSubmit={formik2FAVerify.handleSubmit}>
                    <TextField
                        fullWidth
                        id="code"
                        name="code"
                        label="Code"
                        variant="filled"
                        size="small"
                        sx={styles.input}
                        value={formik2FAVerify.values.code}
                        onChange={formik2FAVerify.handleChange}
                        error={
                            formik2FAVerify.touched.code && Boolean(formik2FAVerify.errors.code)
                        }
                        helperText={formik2FAVerify.touched.code && formik2FAVerify.errors.code}
                    />
                    <Button
                        color="green"
                        variant="contained"
                        fullWidth
                        type="submit"
                        sx={{ fontSize: '17px' }}
                    >

                        SUBMIT
                    </Button>


                </form>
                <Box sx={styles.illustration2}>
                    <img
                        style={{ width: "100%" }}
                        src={require("src/assets/illustration/Girl-Report.png").default}
                        alt="Girl Report"
                    />
                </Box>
                <Box sx={styles.illustration1}>
                    <img
                        style={{ width: "100%" }}
                        src={require("src/assets/illustration/boy-backpack.png").default}
                        alt="Boy Backpack"
                    />
                </Box>

            </Box> : null}

            {pageState === "setuprecovery" ? <Box sx={{ ...styles.box, height: "auto" }}>
                <Typography variant="h2" color="white" sx={{ mb: "10px" }}>
                    Setup Recovery
                </Typography>
                <Typography variant="p" color="white" sx={{ mb: "10px" }}>
                    Enter any string of text you can remember, this is for recovery purposes
                </Typography>
                <form onSubmit={formikCreateRecovery.handleSubmit}>
                    <TextField
                        fullWidth
                        id="recoveryCode"
                        name="recoveryCode"
                        label="Recovery"
                        variant="filled"
                        size="small"
                        sx={styles.input}
                        value={formikCreateRecovery.values.recoveryCode}
                        onChange={formikCreateRecovery.handleChange}
                        error={
                            formikCreateRecovery.touched.recoveryCode && Boolean(formikCreateRecovery.errors.recoveryCode)
                        }
                        helperText={formikCreateRecovery.touched.recoveryCode && formikCreateRecovery.errors.recoveryCode}
                    />
                    <Button
                        color="green"
                        variant="contained"
                        fullWidth
                        type="submit"
                        sx={{ fontSize: '17px' }}
                    >
                        SUBMIT
                    </Button>
                </form>
                <Box sx={styles.illustration2}>
                    <img
                        style={{ width: "100%" }}
                        src={require("src/assets/illustration/Girl-Report.png").default}
                        alt="Girl Report"
                    />
                </Box>
                <Box sx={styles.illustration1}>
                    <img
                        style={{ width: "100%" }}
                        src={require("src/assets/illustration/boy-backpack.png").default}
                        alt="Boy Backpack"
                    />
                </Box>

            </Box> : null}

            {pageState === "verifyrecovery" ? <Box sx={{ ...styles.box, height: "auto" }}>
                <Typography variant="h2" color="white" sx={{ mb: "10px" }}>
                    Verify Recovery
                </Typography>
                <Typography variant="p" color="white" sx={{ mb: "10px" }}>
                    Enter your recovery code that you have previously setup
                </Typography>
                <form onSubmit={formikVerifyRecovery.handleSubmit}>
                    <TextField
                        fullWidth
                        id="recoveryCode"
                        name="recoveryCode"
                        label="Recovery"
                        variant="filled"
                        size="small"
                        sx={styles.input}
                        value={formikVerifyRecovery.values.recoveryCode}
                        onChange={formikVerifyRecovery.handleChange}
                        error={
                            formikVerifyRecovery.touched.recoveryCode && Boolean(formikVerifyRecovery.errors.recoveryCode)
                        }
                        helperText={formikVerifyRecovery.touched.recoveryCode && formikVerifyRecovery.errors.recoveryCode}
                    />
                    <Button
                        color="green"
                        variant="contained"
                        fullWidth
                        type="submit"
                        sx={{ fontSize: '17px' }}
                    >
                        SUBMIT
                    </Button>
                </form>
                <Box sx={styles.illustration2}>
                    <img
                        style={{ width: "100%" }}
                        src={require("src/assets/illustration/Girl-Report.png").default}
                        alt="Girl Report"
                    />
                </Box>
                <Box sx={styles.illustration1}>
                    <img
                        style={{ width: "100%" }}
                        src={require("src/assets/illustration/boy-backpack.png").default}
                        alt="Boy Backpack"
                    />
                </Box>

            </Box> : null}






        </Container>
    );
};

const styles = {
    container: {
        textAlign: "center",
        paddingTop: "40px",
    },
    box: {
        position: "relative",
        textAlign: "left",
        mx: "auto",
        borderRadius: "20px",
        maxWidth: "439px",
        height: { xs: "332px" },
        padding: { xs: "40px 40px", md: "40px 62px" },
        background:
            "transparent linear-gradient(180deg, #2F4D8F 0%, #231E54 100%) 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
    },
    illustration1: {
        // background: "pink",
        position: "absolute",
        left: { xs: "100px", md: "-180px" },
        bottom: { xs: "-220px", md: "0" },
        width: { xs: "94.5px", md: "120px" },
    },
    illustration2: {
        position: "absolute",
        left: { xs: "160px", md: "-100px" },
        bottom: { xs: "-220px", md: "0" },
        width: { xs: "115.36px", md: "150px" },
    },
    input: {
        mb: "13px",
        input: {
            borderRadius: "5px",
            color: "#000000",
            background: "#FFFFFF",
        },
    },
};

export default OfficerLogin;
