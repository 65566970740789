import { createContext, useState } from "react";

const initialState = {
    text: "",
    type: "",
};

const ModalContext = createContext({
    ...initialState,
    setModal: () => {},
});

export const ModalProvider = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [component, setComponent] = useState(null);
    const [maxWidth, setMaxWidth] = useState(null);

    const setModal = (component, maxWidth) => {
        setComponent(component);
        setMaxWidth(maxWidth);
        setOpen(true);
    };

    const closeModal = () => {
        setOpen(false);
        setTimeout(() => {
            setComponent(null);
        }, 0);
    };

    return (
        <ModalContext.Provider
            value={{
                component,
                maxWidth,
                open,
                closeModal,
                setModal,
            }}
        >
            {children}
        </ModalContext.Provider>
    );
};

export default ModalContext;
