import { React, useEffect, useState } from 'react'
import { Button, Box, Card, CardMedia, Divider, Typography } from "@mui/material";

import { useNavigate, useLocation } from "react-router-dom"
import useSession from 'src/hooks/useSession';
import useAuth from 'src/hooks/useAuth';

import { getLawAndOrderSection } from 'src/services/lawandorderServices';

import { IcLegalHammerBlack } from 'src/assets/icons'
import Header from '../../../../components/Header'

import ReactHlsPlayer from '@ducanh2912/react-hls-player';

const CostOfCrime1 = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const { session } = useSession();
    const { auth } = useAuth();
    const [showContinueButton, setShowContinueButton] = useState(false);

    const [ resources, setResources ] = useState([]);

    useEffect(() => {
        const fetchData = async() => {
            const response = await getLawAndOrderSection(3);
            console.log('response: ', response);
            setResources(response.data.value);
        }
        fetchData();
    }, []);

    return (
        <>
            <Header
                    title='Cost of Crime'
                    text='Find out what an ex-probationer has to say about his experience.'
                    icon={IcLegalHammerBlack}
                    iconSize='70%'/>
            <Divider sx={{ mt: {xs: '10px', md:"0px"}, mb: {xs: "20px", md: "40px"}}}/>
            <Box sx={{
                // background: "lightblue",
                display: "flex",
                flexDirection: "column",
                justifyContent: "top",
                alignItems: "center",
                fontSize: "20px",
                height: {xs: "90%", md: "90%"},
                width: {xs:"100%"},
                }}
                maxWidth="false">

                {resources.map((r, i) => {
                    return (
                        <Box key={i} sx={{width:{xs: "100%", md: "auto"}, background:"", display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                            <Typography sx={{fontSize:"20px"}}>
                                {r.title}
                            </Typography>
                            <Box
                            sx={{width:{xs: "100%", md: "720px"}, height:{xs: "100%", md: "405px"}, mb:'50px'}}
                            >
                            <ReactHlsPlayer
                                // src="https://unity-dashboard.s3.ap-southeast-1.amazonaws.com/videos/Live/CostofCrime_Ex-Probationer_Experience/CostofCrime_Ex-Probationer_Experiencemediaconvert_8500kbits.m3u8"
                                src={r.videourl}
                                autoPlay={true}
                                controls={false}
                                width="100%"
                                onEnded={() => {
                                    setShowContinueButton(true);
                                }}
                            />
                        </Box>
                        </Box>
                    )
                })}

                <Box sx={{ display:"flex", background:"", justifyContent:"center", mb:{xs:"30px"}}}>
                    {showContinueButton&&<Button
                        sx={{fontSize:"16px", width:"239px", height:"39px" }}
                        variant="rounded"
                        fullWidth
                        size="small"
                        onClick={async ()=> {
                            // if (session.sessions[2].sessionId == null) {
                            //     const response = await postLawAndOrderSession(auth.probationer.probationerId, 3);
                            //     console.log("RESPONSE: ", response);
                            // }
                            navigate("../cost-of-crime/2")
                        }}
                    >
                        CONTINUE
                    </Button>}
                </Box>
            </Box>
        </>
    )
}

export default CostOfCrime1