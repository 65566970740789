import React from 'react'
import {
    Box,
    Divider,
    Switch,
    Typography,
} from "@mui/material";

import { useState, useEffect } from 'react';
import { alpha, styled } from '@mui/material/styles';

const BlueSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#3276D2',
        '&:hover': {
        backgroundColor: alpha('#3276D2', theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#3276D2',
    },
}));

const CustomSwitch = ({ activated, callback, inactiveText, activeText, formikName, formikID}) => {
    return (
        <Box sx={{display:'flex', flexDirection:'row', alignItems:'center'}}>
            <Typography sx={{color: '#BABABA'}}>
                {inactiveText}
            </Typography>
            <BlueSwitch
                id={formikID}
                name={formikName}
                checked={activated}
                onChange={(event) => {
                    callback(event);
                }}
            />
            <Typography sx={{color: activated? 'black' : '#BABABA'}}>
                {activeText}
            </Typography>
        </Box>
    )
}

export default CustomSwitch