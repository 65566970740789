import React from 'react'
import {
    Button,
    Box,
    Typography,
    } from "@mui/material";

import useModal from 'src/helpers/useModal';
import { useNavigate } from 'react-router-dom';

import { default as checkLogo } from "src/assets/icons/ic-check.svg";

export const CongratulationsPopup = ({ text }) => {

    const navigate = useNavigate();
    const { closeModal } = useModal();

    return (
            <>
                <Box sx={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                    <Box sx={{background: "", display: "flex", justifyContent:"center", height:"60px", width:"60px", mb:'-20px'}}>
                        <img
                            src={checkLogo}
                            alt="Check mark"
                        />
                    </Box>
                    <br/>
                    <Typography variant='h2' align="center" fontSize="24px" sx={{mb:'10px'}}>
                        Congratulations!
                    </Typography>
                    <Typography align="center" fontSize="18px" sx={{color:"#000000"}}>
                        You have completed the programme.
                    </Typography>
                    <Typography align="center" fontSize="18px" sx={{color:"#000000"}}>
                        Please contact your Officer to discuss your responses.
                    </Typography>
                    
                    <br></br>
                    <Button
                        color="green"
                        variant="contained"
                        type="submit"
                        sx={{fontSize:"14pt", width:{ xs:"234px", md: "135px"}}}
                        onClick={() => {
                            closeModal();
                            navigate("players/home")
                        }}
                    >
                        OK
                    </Button>
                </Box>
            </>
    )
}

export default CongratulationsPopup