import React from 'react'
import {
    Button,
    Box,
    Divider,
    Typography,
    } from "@mui/material";
    
import useModal from "src/helpers/useModal";

const AnswersSavedPopup = () => {

    const { closeModal } = useModal();

    return (
        <Box sx={{height:"175px", display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
            <Typography variant="h2">
                Saved
            </Typography>
            Your responses have been saved.
            <Divider sx={{height:"30px"}}/>
            <Button
                color="green"
                variant="contained"
                type="submit"
                sx={{fontSize:"14pt", width: "90%"}}
                onClick={()=> {
                    closeModal();
                }}
            >
                OK
            </Button>
        </Box>
    )
}

export default AnswersSavedPopup