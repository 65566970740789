import axios from "../utils/axios";

// Get Law and Order Sidebar data: Icons/Links
export const getLawAndOrder = async () => {
    return await axios.get("/law-and-order");
};

// Get Law and Order Locked Status (for Switch)
export const getLawAndOrderStatus = async (probationer) => {
    return await axios.post(`/law-and-order/status`, probationer);
};

// Create game_probationer gameId=2 session
// probationer: {accountId, officer: {officerId, fullname}, probationerId, username}
export const unlockLawAndOrder = async (probationer) => {
    return await axios.post(`/law-and-order/unlock`, probationer);
};

// Get Law and Order Applearning/Section Question data
export const getQuestionData= async (applearningId) => {
    return await axios.get(`/law-and-order/question-data/${applearningId}`);
};

// Get Cost of Crime Resources/Videos
export const getLawAndOrderSection = async (sectionId) => {
    return await axios.get(`/law-and-order/${sectionId}`);
};


// Get active (enddate != null) session if exists, get, else create active session
export const getActiveSession = async (probationerId, applearningId) => {
    return await axios.get(`/law-and-order/sessionId/${probationerId}/${applearningId}`);
};


// format: { sessionId: int, isDraft: 0/1, questionIds: [], responses: [] }
export const postLawAndOrderResponses = async (probationerId, responses) => {
    return await axios.post(`/law-and-order/responses/${probationerId}`, responses); //some files use probationerId, some use sessionId, not sure why, shld be sessionId
};

// Get most recent draft answers by sessionId
export const getDraftAnswers = async (sessionId) => {
    return await axios.get(`/law-and-order/draft/${sessionId}`);
}

// If session exists + end_time is null, fill end_time
export const endLawAndOrderSession = async (sessionId) => {
    return await axios.post(`/law-and-order/end-session/${sessionId}`);
}

// If applearning session exists (active or not), set progress accordingly
// e.g. sessions with applearningId 1,2 exist but 3 doesn't, LO_progress: 1
export const getLawAndOrderProgress = async (probationerId) => {
    return await axios.get(`/law-and-order/progress/${probationerId}`);
}

export const getLawAndOrderProgressWithEva = async (probationerId) => {
    return await axios.get(`/law-and-order/progress-eva/${probationerId}`);
}


// Get Game Reflection Question Data
export const getLOGameReflectionQuestionData = async () => {
    return await axios.get(`/law-and-order/game-reflection`);
}

// Post Game Reflection Responses
// format: { questionIds: [], answers: [] } 
export const postGameReflectionResponses = async (responses, probationerId, categoryId) => {
    return await axios.post(`/law-and-order/game-reflection/responses/${probationerId}/${categoryId}`, responses);
}