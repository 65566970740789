import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
    Box,
    Button,
    IconButton,
    Popover,
    List,
    ListItemButton,
    ListItemText,
    Toolbar,
} from "@mui/material";

import MenuIcon from '@mui/icons-material/Menu';
import { IcArrowDown } from "src/assets/icons";

const LoginSwitcher = () => {
    const location = useLocation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    
    const selectedIndex =
        location.pathname === "/client-login"
            ? "client-login"
            : "officer-login";

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <div>
            <Box sx={{ display: {xs: "flex", md: "none"}, justifyContent: "right", background: "", margin: "10px", height: "55px"}}>
                <IconButton
                    onClick={handleClick}
                    // sx={{ background: "lightgreen]" }}
                    size="large" edge="start" color="inherit">
                    <MenuIcon sx={{ width: "100%", height: "100%"}}/>
                </IconButton>
            </Box>

            <Box sx={{ display: {xs: "none", md: "block"}}}>
                <Button
                    aria-describedby="login-type-selection"
                    variant="outlined"
                    color="black"
                    onClick={handleClick}
                    sx={styles.popoverButton}
                    endIcon={<img src={IcArrowDown} alt="arrow-down-icon" />}
                >
                    Login
                </Button>
            </Box>

            <Popover
                id="login-type-selection"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                onClick={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                sx={styles.popover}
                PaperProps={{
                    sx: { width: {md: "233px", xs: '100%'}},
                  }}
            >
                <List component="nav" aria-label="Login type selection">
                    <ListItemButton
                        sx={{ width: "100%"}}
                        component={Link}
                        to="/client-login"
                    >
                        <ListItemText primary="Client Login" />
                    </ListItemButton>
                    <ListItemButton
                        component={Link}
                        to="/officer-login"
                    >
                        <ListItemText primary="Officer Login" />
                    </ListItemButton>
                </List>
            </Popover>
        </div>
    );
};

const styles = {
    popoverButton: {
        // background: "lightgreen",
        position: "absolute",
        top: "14px",
        right: "14px",
        fontSize: "16px",
        textTransform: "none",
    },
    popover: {
        mt: 1,
        // background: "lightgreen",
    },
};

export default LoginSwitcher;
