import React from 'react'
import { Autocomplete } from '@mui/material'
import '../assets/css/Autocomplete.css';

export default function CustomAutocomplete(
        { id, options, getOptionLabel, renderInput, className, onChange, disabled, value }
    )
{
    return (
        <Autocomplete
            disableClearable
            clearOnBlur={false}
            value={value}
            disabled={disabled}
            className={className}
            disablePortal
            id={id}
            options={options}
            getOptionLabel={getOptionLabel}
            renderInput={renderInput}
            onChange={onChange}
            isOptionEqualToValue={(option, value) => option.officerId === value.officerId}
            />
    );
}