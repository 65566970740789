import React, { useState, useEffect } from "react";

import {
    Divider,
    Typography,
    TableContainer,
    Table,
    TableBody,
    styled,
    TableCell,
    TableRow,
    tableCellClasses,
    useTheme,
} from "@mui/material";
import ClientListActions from "./ClientListActions";
import { Link } from "react-router-dom";
import { getProbationers } from "src/services/probationerServices";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "td, th": {
        border: 0,
    },
}));

const ClientList = () => {
    const theme = useTheme();
    const [searchKeyWords, setSearchKeyWords] = useState("");
    const [clients, setClients] = useState([])
    const [filteredClients, setFilteredClients] = useState([]);

    const fetchClients = async () => {
        const response = await getProbationers();
        const { data } = response;
        if (data.success) {
            setClients(data.value)
        } else {
            // console.log(data.error)
        }
    }

    useEffect(()=>  {
        fetchClients()
    }, [])
    

    useEffect(() => {
        if (searchKeyWords){
            setFilteredClients(
                clients.filter((client) =>
                    client.crn
                        .toLowerCase()
                        .includes(searchKeyWords.toLowerCase())
                )
            );
        } else {
            setFilteredClients(clients)
        }
    }, [searchKeyWords, clients]);

    return (
        <div>
            <Typography variant="h2" color="secondary">
                Clients
            </Typography>
            <Divider sx={{ mt: "15px", mb: "25px" }} />
            <ClientListActions setSearchKeyWords={setSearchKeyWords} fetchClients={fetchClients} />
            <TableContainer sx={{ mt: "20px" }}>
                <Table aria-label="customized table">
                    <TableBody>
                        {filteredClients.map((client) => (
                            <StyledTableRow key={client.probationerId}>
                                <StyledTableCell component="th" scope="row">
                                    {client.crn}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <Link
                                        to={`/officers/clients/${client.probationerId}`}
                                        style={{
                                            color: theme.palette.primary.main,
                                        }}
                                    >
                                        View Details
                                    </Link>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default ClientList;
