import React from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import {
    Button,
    Box,
    FormControl,
    TextField,
    Typography,
    Divider,
    } from "@mui/material";

import { useState, useEffect } from 'react';
import useModal from "src/helpers/useModal";
import useAuth from "src/hooks/useAuth";
import { postProbationerProfileName } from "src/services/probationerServices";

import { default as accountCircle } from "src/assets/icons/account-circle-black.png";
import { useNavigate } from "react-router-dom";

const validationSchema = yup.object({
    name: yup.string("Enter profile name").required("Profile name is required"),
});

const ProfileNamePopup = ({probationerId}) => {
    const navigate = useNavigate();

    const { auth, setAuth } = useAuth();
    const { closeModal } = useModal();
    const [ textField, setTextField ] = useState("");

    const formik = useFormik({
        initialValues: {
            name: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const newAuth = structuredClone(auth);
            newAuth.probationer = {name: values.name, probationerId: probationerId};
            await setAuth(newAuth);

            let postObj = { probationerId: probationerId, profileName: values.name }
            const response = await postProbationerProfileName(postObj);
            console.log('Response: ', response);
            
            closeModal();
            navigate('/players/home');
        },
    });

    useEffect(() => console.log(textField), [textField])

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box sx={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                <img
                    style={{width:"59px"}}
                    src={accountCircle}
                    alt="Account Circle"
                />
                <Typography variant="h2">
                    Profile Name
                </Typography>
                <br></br>
                <Typography>
                    Choose your profile name.
                </Typography>
                <Divider sx={{height:"10px"}}></Divider>
                <TextField
                    id="name"
                    name="name"
                    label={
                        formik.values.name === ""?
                        "Enter Name"
                        : ""
                    }
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    InputLabelProps={{ shrink: false, sx: { color:"#0" }}}
                    sx={{ width:"90%", height:"45x", color:"blue"}}
                />
                <Divider sx={{height:"10px"}}></Divider>
                <Button
                    color="green"
                    variant="contained"
                    type="submit"
                    sx={{fontSize:"14pt", width: "90%"}}
                    // onClick={(values)=> {
                    //     console.log('values: ', values);
                    //     set profile name
                    //     console.log("profile name:", textField);
                    //     setProfileName(textField);
                    //     closeModal();
                    // }}
                >
                    Submit
                </Button>
            </Box>
        </form>
    );
};

export default ProfileNamePopup;
