import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
    Box,
    Button,
    Drawer,
    Icon,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Typography,
} from "@mui/material";
import {
    IcOfficer,
    IcReport,
    IcUser
} from "src/assets/icons";
import { TbAlertOctagon } from "react-icons/tb";
import { styled } from "@mui/system";
import { logout } from "src/services/authServices";
import useAuth from "src/hooks/useAuth";

const StyledListSubHeader = styled(ListSubheader)({
    backgroundColor: "transparent",
    color: "#4FAFCD",
    lineHeight: "20px",
    marginTop: "8px",
    marginBottom: "8px",
    fontSize: "14px",
    marginLeft: "10px",
});

const LogoutButton = styled(Button)({
    backgroundColor: "#2F4E91",
    textTransform: "capitalize",
    borderRadius: "50px",
    height: "26px",
    fontWeight: "600",
    marginTop: "10px",
    marginBottom: "30px",
});

const menus = [
    {
        name: "ACCOUNTS",
        menus: [
            {
                name: "Clients",
                icon: IcUser,
                link: "/officers/clients",
                height: "45px",
            },
            {
                name: "Officers",
                icon: IcOfficer,
                link: "/officers/officers",
                height: "45px",
            },
        ],
    },
    {
        name: "DOWNLOAD",
        menus: [
            {
                name: "Reports",
                icon: IcReport,
                link: "/officers/reports",
                height: "45px",
            },

            {
                name: "Report an issue",
                icon: <TbAlertOctagon color={'#4FAFCD'} size={20} />,
                link: "https://form.gov.sg/64eebbf58591b60012440bd4",
                height: "45px",
            }
        ],
    },
];

const OfficersSidebar = (props) => {
    const { auth } = useAuth();
    const navigate = useNavigate();
    const { drawerWidth, window, mobileOpen, handleDrawerToggle } = props;
    const location = useLocation();

    const container =
        window !== undefined ? () => window().document.body : undefined;

    const handleLogout = async () => {
        await logout()
        navigate("/officer-login");
    };

    const drawer = (
        <div>
            <Box sx={{ textAlign: "center", mt: "30px", mb: "40px" }}>
                <Typography sx={{ fontSize: "18px" }} variant="h3" color="white">
                    {auth.username}
                </Typography>
                <LogoutButton variant="contained" size="medium" onClick={handleLogout}>
                    Logout
                </LogoutButton>
                {menus.map((menu) => (
                    <List key={menu.name} sx={{ textAlign: "left" }} subheader={<StyledListSubHeader>{menu.name}</StyledListSubHeader>}>
                        {menu.menus.map((subMenu) => {
                            if (subMenu.name === "Officers" && auth.isSuperAdmin !== 1) return null;
                            return (
                                <ListItem key={subMenu.name} disablePadding selected={subMenu.link === location.pathname} style={{ marginTop: subMenu.name === "Report an issue" && '50px' }}>
                                    <ListItemButton sx={{ height: "36px", paddingLeft: '25px' }} component={Link} to={subMenu.link}
                                        onClick={() => {
                                            if (subMenu.name === "Report an issue"){
                                                window.open(subMenu.link, "_blank");
                                            }
                                        }}
                                    >
                                        <ListItemIcon sx={{ minWidth: "40px", paddingBottom: '5px' }}>
                                            <Icon>
                                                {subMenu.name === "Report an issue" ? subMenu.icon : <img src={subMenu.icon} />}
                                            </Icon>
                                        </ListItemIcon>
                                        <ListItemText sx={{ paddingTop: "5px", color: '#FFF' }} primary={subMenu.name} />
                                    </ListItemButton>
                                </ListItem>
                            )
                        })}
                    </List>
                ))}
            </Box>

            {/* <a
                href="https://form.gov.sg/64eebbf58591b60012440bd4"
                style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                    color: "#4FAFCD",
                    fontSize: "14px",
                    marginLeft: 10
                }}
            >Report an issue</a> */}
        </div>
    );

    return (
        <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="Navigation menus"
        >
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                PaperProps={{
                    sx: {
                        backgroundColor: "#193465",
                    },
                }}
                sx={{
                    display: {
                        xs: "block",
                        sm: "none",
                    },
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: drawerWidth,
                    },
                }}
            >
                {drawer}
            </Drawer>
            <Drawer
                variant="permanent"
                PaperProps={{
                    sx: {
                        backgroundColor: "#193465",
                    },
                }}
                sx={{
                    display: {
                        xs: "none",
                        sm: "block",
                    },
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: drawerWidth,
                    },
                }}
                open
            >
                {drawer}
            </Drawer>
        </Box>
    );
};

export default OfficersSidebar;
