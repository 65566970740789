import { createContext, useState } from "react";

const initialState = {
    text: "",
    type: "",
};

const AlertContext = createContext({
    ...initialState,
    setAlert: () => {},
});

export const AlertProvider = ({ children }) => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [buttonText, setButtonText] = useState("");
    const [type, setType] = useState("");
    const [open, setOpen] = useState(false);
    const [icon, setIcon] = useState();
    const [nextDest, setNextDest] = useState(undefined);

    const setAlert = ({title, description, buttonText, type, icon, nextDest}) => {
        setTitle(title);
        setDescription(description);
        setButtonText(buttonText);
        setType(type);
        setOpen(true);
        setIcon(icon);
        setNextDest(nextDest);
    };

    const closeAlert = () => {
        setTitle("");
        setDescription("");
        setButtonText("");
        setType(type);
        setOpen(false);
        setIcon(undefined);
        setNextDest(undefined);
    };

    return (
        <AlertContext.Provider
            value={{
                title,
                description,
                buttonText,
                type,
                open,
                closeAlert,
                setAlert,
                icon,
                nextDest
            }}
        >
            {children}
        </AlertContext.Provider>
    );
};

export default AlertContext;
