import React, {useState} from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import {
    Button,
    Box,
    Container,
    Divider,
    Icon,
    TextField,
    Typography,
} from "@mui/material";

import { postEvaluation } from "src/services/probationerServices";

import useModal from "src/helpers/useModal";
import useAlert from "src/helpers/useAlert";

import { IcReportDocsBlack } from 'src/assets/icons'

import Question from "src/components/Question";
import DraggableCard from "src/components/DraggableCard";
import CardReceiver from "src/components/CardReceiver";
import CongratulationsPopup from "../popups/CongratulationsPopup";

import { IcStopSign, IcCheck } from "src/assets/icons";

const FinalQuestionnaire = () => {

    const { setModal, closeModal } = useModal();
    const { setAlert } = useAlert();

    const questionData = [
        {
            title: <><strong>1. </strong>Which one of the following is not a hurt experienced by a victim?</>,
            options: [
                "Psychological",
                "Financial",
                'Educational',
                'Physical'
            ],
        },
        {
            title: <><strong>2. </strong>Peter noticed a stranger staring at him. He proceeded to confront the stranger. During the confrontation, Peter punched the stranger in the face and the stranger's face bled profusely. Which offence does the scenario describe?</>,
            options: [
                "Robbery",
                "Vandalism",
                'Sexual penetration of a minor',
                'Voluntarily causing hurt',
            ],
        },
        {
            title: <><strong>3. </strong>A group of boys saw an elderly couple and decided to steal from them. They went forward and snatched the elderly lady's purse. She fell and her husband shouted at the boys who had run off in different directions. Some people eating at the hawker centre nearby ran after the boys and helped the elderly couple to call the police and ambulance. <br/><br/> Based on the scenario above, drag and drop the characters to the types of victims that they belong to.</>,
            options: [
                "Elderly lady",
                "People eating at the hawker centre",
                "People staying in the neighbourhood"
            ],
            labels: [
                "Primary Victim",
                "Secondary Victim",
                "Hidden/Indirect Victim"
            ]
        },
        {
            title: <><strong>4. </strong>Which of the following is not a way to make amends when an offence is committed?</>,
            options: [
                "Apologise to the victim",
                "Pay the victim back for damages",
                'Confront the victim to justify my action',
                'Clean up the mess for the victim'
            ],
        },
        {
            title: <><strong>5. </strong>A person who successfully completed probation can lawfully declare that he does not have a conviction record.</>,
            options: ["True", "False"],
        },
        {
            title: <><strong>6. </strong>Which one of the following options accurately describes "forgery"?</>,
            options: [
                "Peter and his friends climbed over the school gates at 3am", 
                "Peter changed the dates of an existing Medical Certificate",
                'Peter took snacks and drinks from a convenience store without paying',
                'None of the above'
            ],
        },
        {
            title: <><strong>7. </strong>Peter took his friend's earphones from his bag as it was left unattended, and he really wanted the pair of earphones. Which offence does the scenario describe?</>,
            options: [
                "Theft",
                "Unlawful assembly",
                'Consumption of drugs',
                'Unlicensed moneylending'
            ],
        },
        {
            title: <><strong>8. </strong>All offences have victims. True or false?</>,
            options: ["True", "False"],
        },
        {
            title: <><strong>9. </strong>What is the purpose of having laws?</>,
            options: [
                "To maintain order",
                "To protect public safety",
                'To keep peace in a country',
                'All of the above'
            ],
        },
        {
            title: <><strong>10. </strong>Which of the following is not an example of financial hurt?</>,
            options: [
                "Loss of income",
                "Medical fees",
                'Feeling stressed',
                'Cost of replacing items stolen'
            ],
        },
    ]

    const [answers, setAnswers] = useState({
        mcq: {1: '', 2: '', 4: '', 5: '', 6: '', 7: '', 8: '', 9: '', 10: ''},
        dnd: [undefined, undefined, undefined],
        text: ''
    })

    const handleMoveDraggable = (receiverId, id) => {
        let newAnswers = structuredClone(answers);
        if (answers.dnd.includes(id)) {
            newAnswers.dnd[answers.dnd.indexOf(id)] = undefined;
            newAnswers.dnd[receiverId] = id;
            setAnswers(newAnswers);
        } else {
            newAnswers.dnd[receiverId] = id;
            setAnswers(newAnswers);
        }
    }

    const handleTextChange = (event) => {
        let newAnswers = structuredClone(answers);
        newAnswers.text = event.target.value
        setAnswers(newAnswers)
    }
    
    const handleSubmit = async() => {
        // check for empty answers
        for (const [key, value] of Object.entries(answers.mcq)) {
            if (value == '') {
                setAlert({
                    icon: IcStopSign,
                    title: "Submission Failed",
                    description: "Please do not leave any blanks.",
                    buttonText: "CLOSE",
                    type: "OK",
                });
                return
            }
        }
        if (answers.dnd.includes(undefined) || answers.text == '') {
            setAlert({
                icon: IcStopSign,
                title: "Submission Failed",
                description: "Please do not leave any blanks.",
                buttonText: "CLOSE",
                type: "OK",
            });
            return
        }

        const postObj = [
            { question: 'Which one of the following is not a hurt experienced by a victim?', answer: ''},
            { question: "Peter noticed a stranger staring at him. He proceeded to confront the stranger. During the confrontation, Peter punched the stranger in the face and the stranger's face bled profusely. Which offence does the scenario describe?" , answer: ''},
            { question: "A group of boys saw an elderly couple and decided to steal from them. They went forward and snatched the elderly lady's purse. She fell and her husband shouted at the boys who had run off in different directions. Some people eating at the hawker centre nearby ran after the boys and helped the elderly couple to call the police and ambulance. Based on the scenario above, drag and drop the characters to the types of victims that they belong to.", answer: ''},
            { question: 'Which of the following is not a way to make amends when an offence is committed?', answer:  ''},
            { question: 'A person who successfully completed probation can lawfully declare that he does not have a conviction record.', answer:  ''},
            { question: 'Which one of the following options accurately describes "forgery"?', answer:  ''},
            { question: "Peter took his friend's earphones from his bag as it was left unattended, and he really wanted the pair of earphones. Which offence does the scenario describe?", answer:  ''},
            { question: 'All offences have victims. True or false?', answer: ''},
            { question: 'What is the purpose of having laws?', answer: ''},
            { question: 'Which of the following is not an example of financial hurt?', answer: ''},
        ]
        // handle mcq
        for (var qNo of Object.keys(answers.mcq)) {
            postObj[qNo-1].answer = answers.mcq[qNo]
        }
        // handle dnd
        for (var box of answers.dnd) {
            if (box === undefined) {
                postObj[2].answer = postObj[2].answer.concat('undefined')
                postObj[2].answer = postObj[2].answer.concat('|||||')
            }
            else {
                postObj[2].answer = postObj[2].answer.concat(questionData[2].options[box])
                postObj[2].answer = postObj[2].answer.concat('|||||')
            }
        }
        postObj[2].answer = postObj[2].answer.slice(0, -5);
        // handle text
        postObj[7].answer = answers.mcq[8]
        postObj[7].answer = postObj[7].answer.concat('|||||')
        postObj[7].answer = postObj[7].answer.concat(answers.text)
        // console.log('post object:', postObj);
        const response = await postEvaluation({ evaluation : postObj });
        if (response.data.success) {
            setModal(
                <>
                    <CongratulationsPopup/>
                </>
            , '462px');
        }
    }

    return (
        <div>
           <Box sx={{ p:3, background: "", display:{xs: "none", md: "flex"}, alignItems:"center" }}>
                <Icon sx={{display: "flex", justifyContent: "center", background: "", height:"77px", width:"77px"}}>
                    <img src={IcReportDocsBlack} width='70%' alt="Icon" />
                </Icon>
                <Divider orientation='vertical' sx={{margin:'5px'}}/>
                <Box sx={{ background: "", display:"flex", flexDirection:"column" }}>
                    <Typography variant="h2" color="secondary"
                        sx={{ ml: "5px", background: "", fontSize: "32px" }}
                    >
                        VI & LO Evaluation Questionnaire
                    </Typography>
                </Box>
            </Box>

            <Container 
                maxWidth={false}
                sx={{
                    display: {xs: "flex", md: "none"},
                    alignItems: "left",
                    flexDirection:"column",
                    pt:"5px",
                    width: '100%',
                    mt: {xs:'20px', md:'0'},
                }}>
                <Box sx={{
                        // background: "lightgreen",
                        display: {xs: "flex", md: "none"},
                        alignItems: "left",
                        flexDirection:"column",
                        width:"100%",
                    }}>
                    <Box sx={{ background: "", display:"flex", flexDirection: "row", alignItems:"center"}}>
                        <Icon sx={{display: "flex", justifyContent: "center", background: "", height:"77px", width:"77px"}}>
                            <img src={IcReportDocsBlack} width='70%' alt="Icon" />
                        </Icon>
                        <Typography variant="h2" color="secondary"
                            sx={{ ml: "5px", background: "", fontSize: "28px" }}
                        >
                            VI & LO Evaluation Questionnaire
                        </Typography>
                    </Box>
                </Box>
            </Container>

            <Divider sx={{ mt: {xs: '20px', md: "0px"}, mb: {xs: "0px", md: "20px"}}}/>

            <Container maxWidth={false} sx={styles.whiteContainer}>

                <Question questionNumber={1} data={questionData} answers={answers} setAnswers={setAnswers}></Question>
                <Question questionNumber={2} data={questionData} answers={answers} setAnswers={setAnswers}></Question>
                    
                
                <Box sx={styles.dndBox}>
                    {questionData[2][0]}
                    {questionData[2].title}
                    <Box sx={{ width:'100%', background:'', display:"flex", alignItems:'center', justifyContent:"center"}}>
                        <Box sx={{ background:'', width:{xs: '300px', md: '350px'},}}>
                            <img
                            style={styles.victimRipple}
                            src={require("src/assets/illustration/Victims-Ripple.png").default}
                            alt="Victims Ripple"
                            />
                        </Box>
                    </Box>
                    <Box sx={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                        {questionData[2].labels.map((victim, index) => {
                            return (
                                <Box key={index} sx={{display:"flex", flexDirection:{xs: 'column', md: 'row'}, alignItems:{md:"center"}, margin:{md:"10px"}}}>
                                    <Box width={"200px"} fontWeight={"bold"}>{victim}</Box>
                                    <CardReceiver id={answers.dnd[index]} content={questionData[2].options[answers.dnd[index]]} receiverId={index} handleMoveDraggable={handleMoveDraggable}/>
                                </Box>
                            )
                        })}
                    </Box>
                    <br></br>
                    <Box sx={{display:"flex", flexWrap:"wrap",justifyContent:"space-around",backgroundColor:"",rp:"15px", }}>
                        {questionData[2].options.map((ans, index) => {
                            return <DraggableCard key={index} id={index} text={ans} css={styles.whitebox}/>
                        })}
                    </Box>
                </Box>

                <Question questionNumber={4} data={questionData} answers={answers} setAnswers={setAnswers}></Question>
                <Question questionNumber={5} data={questionData} answers={answers} setAnswers={setAnswers}></Question>
                <Question questionNumber={6} data={questionData} answers={answers} setAnswers={setAnswers}></Question>
                <Question questionNumber={7} data={questionData} answers={answers} setAnswers={setAnswers}></Question>
                <Question questionNumber={8} data={questionData} answers={answers} setAnswers={setAnswers}></Question>

                <Box sx={{display:"flex", justifyContent:"left", ml:{xs:"0", md:"100px"}}}>
                    Please explain.
                </Box>
                <Box sx={{display:"flex", justifyContent:{xs:"center", md:"left"}, ml:{xs:"0", md:"200px"}}}>
                    <TextField value={answers.text} onChange={handleTextChange} multiline={true} rows={4} sx={{width:{xs:'100%', md: "1100px"}, mt:"10px", backgroundColor:"white"}}></TextField>
                </Box>

                <Question questionNumber={9} data={questionData} answers={answers} setAnswers={setAnswers}></Question>
                <Question questionNumber={10} data={questionData} answers={answers} setAnswers={setAnswers}></Question>
            </Container>

            <Container sx={styles.whiteContainer}>
                <Box sx={{display:"flex", justifyContent:"center"}}>
                    <Button
                        variant="rounded"
                        sx={{fontSize:"16pt", width: "300px", m: {xs:'20px', md: "50px"}}}
                        size="large"
                        onClick={handleSubmit}
                    >
                        SUBMIT
                    </Button>
                </Box>
            </Container>
        </div>
        
    )
}

const styles = {
    whiteContainer: {
        textAlign: "center",
        // paddingTop: "40px",
        // paddingBottom:"40px",
        background: "white",
    },
    whitebox: {
        display:"flex",
        alignItems:"center",
        background: "var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        // background: "lightblue",
        boxShadow: "0px 3px 6px #00000029",
        border: "1px solid #AAAAAA",
        borderRadius: "8px",
        opacity: 1,
        width: "300px",
        height: "75px",
        margin:"10px",
    },
    dndBox: {
        position: "relative",
        textAlign: "left",
        right: "0px",
        mx: "auto",
        borderRadius: "20px",
        maxWidth: "1100px",
        padding: "15px 5px",
    },
    victimRipple: {
        position: "relative",
        paddingTop: "20px",
        paddingBottom:"20px",
        width: "100%",
        height: 'auto',
    }
}

export default FinalQuestionnaire;
