import { React, useEffect, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { TextField, Button, Box, Container, Typography, getPaperUtilityClass, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";

import useAlert from "src/helpers/useAlert";
import { getPasswordHistory, login } from "src/services/authServices";


import useAuth from "src/hooks/useAuth";
import useSession from "src/hooks/useSession";
import useModal from "src/helpers/useModal";
import ActivationPopUp from "./ActivationPopup";
import PasswordResetPopup from "./PasswordResetPopup";

import { IcStopSign, IcCheck } from "src/assets/icons";

const validationSchema = yup.object({
    username: yup
        .string("Enter your email")
        .email("Enter a valid email")
        .required("Email is required"),
    password: yup
        .string("Enter your password")
        .min(8, "Password should be of minimum 8 characters length")
        .required("Password is required"),
});



const LoginClient = () => {
    const { auth, setAuth } = useAuth();
    const { setSession } = useSession();

    const { setAlert } = useAlert();
    const { setModal } = useModal();
    const [showModal, setShowModal] = useState(false)
    const navigate = useNavigate();


    const formik = useFormik({
        initialValues: {
            username: "",
            password: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            console.log("request: client")
            const response = await login(values);
            const { success, error, value } = response.data;
            console.log('value: ', value);
            if (success && value.probationer && value.probationer.probationerId !== null) {
                
                localStorage.setItem('firstLogin', '1')
                // if (success) {
                if (!value.isActivated) {
                    setModal(
                        <>
                            <ActivationPopUp />
                        </>
                        , '462px');
                } else {
                    await setAuth({ accessToken: value.token, roles: [1] });
                    const histRes = await getPasswordHistory();
                    if (histRes.data.success) {
                        if (histRes.data.value.length === 0) {
                            // setAlert({
                            //     icon: IcStopSign,
                            //     title: "Please change your password",
                            //     description: "Password should be changed on first login.",
                            //     buttonText: "Change Password",
                            //     type: "OK",
                            // });
                        }
                    }
                    if (value.evaluation_attempted === 0 && value.probationer && value.probationer.probationerId) {
                        setAlert({
                            icon: IcCheck,
                            title: "Login Successful",
                            description: "Complete the evaluation questionnaire before you start.",
                            buttonText: "START",
                            type: "OK",
                        });
                        navigate('/players/evaluation')
                    } else {
                        navigate('/players/home')
                    }
                }
            }
            else {
                setAlert({
                    icon: IcStopSign,
                    title: "Try Again",
                    // description: error,
                    buttonText: "TRY AGAIN",
                    type: "ERROR",
                });
            }
        },
    });

    return (
        <Container sx={styles.container}>
            <img
                width="300px"
                src={require("src/assets/common/msf-logo.png").default}
                alt="MSF Logo"
            />
            <Typography align="center" variant="h2" color="black" sx={{ mb: "20px" }}>
                Probation and Community Rehabilitation Service
                <br />
                My Probation Journey
            </Typography>
            <Box sx={styles.box}>
                <Typography variant="h2" color="white" sx={{ mb: "10px" }}>
                    Client Login
                </Typography>
                <form onSubmit={formik.handleSubmit}>
                    <TextField
                        fullWidth
                        id="username"
                        name="username"
                        label="Email"
                        variant="filled"
                        size="small"
                        sx={styles.input}
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.username && Boolean(formik.errors.username)
                        }
                        helperText={formik.touched.username && formik.errors.username}
                    />
                    <TextField
                        fullWidth
                        id="password"
                        name="password"
                        label="Password"
                        type="password"
                        variant="filled"
                        size="small"
                        sx={styles.input}
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={
                            formik.touched.password &&
                            Boolean(formik.errors.password)
                        }
                        helperText={
                            formik.touched.password && formik.errors.password
                        }
                    />
                    <Button
                        color="green"
                        variant="contained"
                        fullWidth
                        type="submit"
                        sx={{ fontSize: '17px' }}
                    >
                        LOGIN
                    </Button>
                    <Button
                        sx={{
                            fontSize: '16px',
                            textTransform: 'none',
                            color: "#FFFFFF",
                            marginTop: "5px",
                            ml: '-5px',
                            display: "block",
                            textDecoration: "underline"
                        }}
                        onClick={async () => {
                            setModal(
                                <>
                                    <PasswordResetPopup />
                                </>
                                , '462px');
                        }}
                    >
                        Reset Password
                    </Button>

                </form>
                <Box sx={styles.illustration1}>
                    <img
                        style={{ width: "100%" }}
                        src={require("src/assets/illustration/teen-boy-mobilephone.png").default}
                        alt="Teen boy mobile phone"
                    />
                </Box>
                <Box sx={styles.illustration2}>
                    <img
                        style={{ width: "100%" }}
                        src={require("src/assets/illustration/teen-girls.png").default}
                        alt="Teen Girls"
                    />
                </Box>
            </Box>
        </Container>
    );
};

const styles = {
    container: {
        width: "100%",
        // background: "lightgrey", 
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: { md: "40px" },
    },
    box: {
        position: "relative",
        textAlign: "left",
        mx: "auto",
        borderRadius: "20px",
        maxWidth: "439px",
        height: { xs: "332px" },
        padding: { xs: "40px 40px", md: "40px 62px" },
        background:
            "transparent linear-gradient(180deg, #2F4D8F 0%, #231E54 100%) 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
    },
    illustration1: {
        position: "absolute",
        left: { xs: "10px", md: "-119px" },
        bottom: { xs: "-220px", md: "0" },
        width: { xs: "142px", md: "168px" },
        height: { xs: "252px", md: "301px" },
    },
    illustration2: {
        position: "absolute",
        right: { xs: "10px", md: "-160px" },
        bottom: { xs: "-220px", md: "0" },
        width: { xs: "165px", md: "196px" },
        height: { xs: "251px", md: "298px" },
    },
    input: {
        mb: "13px",
        input: {
            borderRadius: "5px",
            color: "#000000",
            background: "#FFFFFF",
        },
    },
};

export default LoginClient;
