import 'src/App.css';
import React, { Fragment, useState, useCallback, useEffect } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import { Box, Icon } from "@mui/material";
import { postPreference } from "src/services/probationerServices";
import { useNavigate } from 'react-router-dom';
import useAuth from "src/hooks/useAuth";
import useModal from "src/helpers/useModal";
import { endSessionByProbationerId, getPreference, getScene, postGameAnswers, postScene } from "src/services/gameServices";
import { FaCross, FaGamepad, FaInfoCircle } from "react-icons/fa"
import { MdClose } from "react-icons/md"

const GamePlay = ({ game_name }) => {
    // game_name will be from LO1, LO2, LO3 ... VI

    const navigate = useNavigate();

    const [gameType, setGameType] = useState(game_name.includes("LO") ? 2 : 1)

    const { auth } = useAuth();

    // Staging
    const { unityProvider, sendMessage, addEventListener, removeEventListener, isLoaded, loadingProgression } = useUnityContext({
        loaderUrl: (game_name === "VI") ? `https://msf-staging-unity.s3.ap-southeast-1.amazonaws.com/Unity/${game_name}/Build/${game_name}.loader.js` : `https://msf-staging-unity.s3.ap-southeast-1.amazonaws.com/Unity/${game_name}/Build/${game_name}.loader.js`,
        dataUrl: (game_name === "VI") ? `https://msf-staging-unity.s3.ap-southeast-1.amazonaws.com/Unity/${game_name}/Build/${game_name}.data` : `https://msf-staging-unity.s3.ap-southeast-1.amazonaws.com/Unity/${game_name}/Build/${game_name}.data.gz`,
        frameworkUrl: (game_name === "VI") ? `https://msf-staging-unity.s3.ap-southeast-1.amazonaws.com/Unity/${game_name}/Build/${game_name}.framework.js` : `https://msf-staging-unity.s3.ap-southeast-1.amazonaws.com/Unity/${game_name}/Build/${game_name}.framework.js.gz`,
        codeUrl: (game_name === "VI") ? `https://msf-staging-unity.s3.ap-southeast-1.amazonaws.com/Unity/${game_name}/Build/${game_name}.wasm` : `https://msf-staging-unity.s3.ap-southeast-1.amazonaws.com/Unity/${game_name}/Build/${game_name}.wasm.gz`
    });

    // Production
    // const { unityProvider, sendMessage, addEventListener, removeEventListener, isLoaded, loadingProgression } = useUnityContext({
    //     loaderUrl: (game_name==="VI")? `https://unity-web-bucket.s3.ap-southeast-1.amazonaws.com/Unity/${game_name}/Build/${game_name}.loader.js`: `https://unity-web-bucket.s3.ap-southeast-1.amazonaws.com/Unity/${game_name}/Build/${game_name}.loader.js`,
    //     dataUrl: (game_name==="VI")? `https://unity-web-bucket.s3.ap-southeast-1.amazonaws.com/Unity/${game_name}/Build/${game_name}.data`: `https://unity-web-bucket.s3.ap-southeast-1.amazonaws.com/Unity/${game_name}/Build/${game_name}.data.gz`,
    //     frameworkUrl: (game_name==="VI")? `https://unity-web-bucket.s3.ap-southeast-1.amazonaws.com/Unity/${game_name}/Build/${game_name}.framework.js`: `https://unity-web-bucket.s3.ap-southeast-1.amazonaws.com/Unity/${game_name}/Build/${game_name}.framework.js.gz`,
    //     codeUrl: (game_name==="VI")?`https://unity-web-bucket.s3.ap-southeast-1.amazonaws.com/Unity/${game_name}/Build/${game_name}.wasm`:`https://unity-web-bucket.s3.ap-southeast-1.amazonaws.com/Unity/${game_name}/Build/${game_name}.wasm.gz`
    // });

    // const { unityProvider, sendMessage, addEventListener, removeEventListener, isLoaded, loadingProgression } = useUnityContext({
    //     loaderUrl:["LO1","VI"].includes( game_name)?`../../../../../../Unity/${game_name}/Build/${game_name}.loader.js`:`../../../../../../Unity/${game_name}/Build/${game_name}.loader.js`,
    //     dataUrl:["LO1","VI"].includes( game_name)?`../../../../../../Unity/${game_name}/Build/${game_name}.data`:`../../../../../../Unity/${game_name}/Build/${game_name}.data.gz`,
    //     frameworkUrl:["LO1","VI"].includes( game_name)?`../../../../../../Unity/${game_name}/Build/${game_name}.framework.js`:`../../../../../../Unity/${game_name}/Build/${game_name}.framework.js.gz`,
    //     codeUrl:["LO1","VI]"].includes(game_name)?`../../../../../../Unity/${game_name}/Build/${game_name}.wasm`:`../../../../../../Unity/${game_name}/Build/${game_name}.wasm.gz`
    // });

    const [showControls, setShowControls] = useState(false)


    const loadingPercentage = Math.round(loadingProgression * 100);
    // console.log("loadingPercentage", loadingPercentage);

    /**
     * Change the scene in the game, from dashboard into the game. 
     */
    function LoadAScene() {
        // console.log("pressed\n");
        // var scene = this.Api.LoadScene(); // call to backend; 
        // sendMessage("SceneLoader", "LoadScene", "Scenes/Scenes_VI/VI_VoidDeck1");
        // var scene = getScene();
        // sendMessage("SceneLoader", "LoadScene", scene);
    };

    /**
     * Play background music choosen by player into the game
     */
    async function getMusic() {
        var music = (await getPreference()).data.value.musicId;
        console.log("music set", music);
        sendMessage("SceneLoader", "SetMusic", music !== null ? music : 1);
    };

    /**
     * Change game character outfit choosen by player
     */
    async function getOutfit() {
        var outfit = (await getPreference()).data.value.outfitId;
        console.log("outfit set", outfit);
        sendMessage("SceneLoader", "SetOutfit", outfit ? outfit : 20);
    };

    /**
     * Update backend of new selection by player in Victim Impact
     */
    const setChoice = async (question, choice) => {
        console.log("setChoice", question, choice);
        postGameAnswers(gameType, { qnum: question, answer: choice });
    }

    const handleChoice = useCallback((question, choice) => {
        setChoice(question, choice);
    }, []);

    const sendOutfitPreference = async (num) => {
        console.log("outfit selected:", num);
        postPreference({ outfitId: num });
    }

    const sendMusicPreference = async (num) => {
        postPreference({ musicId: num });
    }

    const endGame = async () => {
        if (game_name === "VI") {
            endSessionByProbationerId(auth.probationer.probationerId, gameType);
            window.location.href = "/players/victim-impact";
        } else {
            navigate("/players/game-reflection", { state: game_name.replace("LO", "") })
        }

        // endSessionByProbationerId(auth.probationer.probationerId, gameType);
    }

    const setSceneCheckPoint = async (sceneName) => {
        postScene({ 'scenename': sceneName });
    }

    /**
     * Update backend of the outfit selected by player
     */
    const handleOutfit = useCallback((num) => {
        sendOutfitPreference(num);
    }, []);

    const handleMusic = useCallback((num) => {
        sendMusicPreference(num);
    }, []);

    const handleEndGame = useCallback(() => {
        console.log("Game ended! \n");
        endGame();
    }, []);

    const handleScene = useCallback((sceneName) => {
        setSceneCheckPoint(sceneName);
    }, []);

    /**
     * Receive data from game upon player answering a question in Law and Order
     */
    useEffect(() => {
        addEventListener("LO", handleChoice);
        return () => {
            removeEventListener("LO", handleChoice);
        };
    }, [addEventListener, removeEventListener, handleChoice]);

    /**
     * Receive data from game upon player answering a question in Victim Impact
     */
    useEffect(() => {
        addEventListener("VI", handleChoice);
        return () => {
            removeEventListener("VI", handleChoice);
        };
    }, [addEventListener, removeEventListener, handleChoice]);

    /**
     * Receive data from game upon choosing outfit
     */
    useEffect(() => {
        // EDIT HERE:
        addEventListener("Outfit", handleOutfit);
        return () => {
            removeEventListener("Outfit", handleOutfit);
        };
    }, [addEventListener, removeEventListener, handleOutfit]);

    useEffect(() => {
        // EDIT HERE:
        addEventListener("Music", handleMusic);
        return () => {
            removeEventListener("Music", handleMusic);
        };
    }, [addEventListener, removeEventListener, handleMusic]);

    useEffect(() => {
        // EDIT HERE:
        addEventListener("LoadReflection", handleEndGame);
        return () => {
            removeEventListener("LoadReflection", handleEndGame);
        };
    }, [addEventListener, removeEventListener, handleEndGame]);

    useEffect(() => {
        addEventListener("SceneName", handleScene);
        return () => {
            removeEventListener("SceneName", handleScene);
        };
    }, [addEventListener, removeEventListener, handleScene]);

    /**
     * Load Outfit and Music and specific scene once the game finishes loading
     */
    useEffect(() => {
        if (isLoaded) {
            getOutfit();
            getMusic();
            // LoadAScene();
        }
        // console.log(JSON.stringify(auth.probationer.probationerId));
    }, [isLoaded])

    return (
        <>
            {/* <pre>{JSON.stringify({
                loaderUrl: (game_name === "VI") ? `https://msf-staging-unity.s3.ap-southeast-1.amazonaws.com/Unity/${game_name}/Build/${game_name}.loader.js` : `https://msf-staging-unity.s3.ap-southeast-1.amazonaws.com/Unity/${game_name}/Build/${game_name}.loader.js`,
                dataUrl: (game_name === "VI") ? `https://msf-staging-unity.s3.ap-southeast-1.amazonaws.com/Unity/${game_name}/Build/${game_name}.data` : `https://msf-staging-unity.s3.ap-southeast-1.amazonaws.com/Unity/${game_name}/Build/${game_name}.data.gz`,
                frameworkUrl: (game_name === "VI") ? `https://msf-staging-unity.s3.ap-southeast-1.amazonaws.com/Unity/${game_name}/Build/${game_name}.framework.js` : `https://msf-staging-unity.s3.ap-southeast-1.amazonaws.com/Unity/${game_name}/Build/${game_name}.framework.js.gz`,
                codeUrl: (game_name === "VI") ? `https://msf-staging-unity.s3.ap-southeast-1.amazonaws.com/Unity/${game_name}/Build/${game_name}.wasm` : `https://msf-staging-unity.s3.ap-southeast-1.amazonaws.com/Unity/${game_name}/Build/${game_name}.wasm.gz`
            }, null, 2)}</pre> */}

            {showControls &&
                <>
                    <div style={{ position: "fixed", top: 20, right: 20, zIndex: 2000, cursor: "pointer" }}
                        onClick={() => {
                            setShowControls(!showControls)
                        }}
                    >
                        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                            <MdClose style={{ fill: "white" }} size={20} color='white' />
                            <p style={{ color: "white", fontSize: 15 }}>Close</p>
                        </div>
                    </div>
                    <div style={{ backdropFilter: "blur(0.1)", backgroundColor: "black", position: "fixed", top: 0, left: 0, width: "100dvw", height: "100dvh", zIndex: 1000, display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <img src={"/img/controls.jpg"} alt="" style={{ width: "50dvw", objectFit: "contain" }} />
                    </div>
                </>
            }

            {gameType === 1 && !showControls && <div style={{ position: "fixed", top: 20, right: 20, }}
                onClick={() => {
                    setShowControls(!showControls)
                }}
            >
                <div style={{ display: "flex", alignItems: "center", gap: 10, flexDirection:"row", justifyContent:"center" }}>
                    <FaGamepad style={{ fill: "white" }} size={20} color='white' />
                    <p style={{ color: "white", fontSize: 15 }}>Controls</p>
                </div>
            </div>}

            <div style={{
                width: "100%",
                // height:`${window.innerHeight}`, 
                // height: '630px',
                display: "flex", justifyContent: "center", alignItems: "flex-start"
            }}>
                {!isLoaded ?
                    // We'll conditionally render the loading overlay if the Unity
                    // Application is not loaded.
                    <p style={{ textAlign: "center" }}>Loading... ({loadingPercentage}%)</p>
                    :
                    null}
                <Unity unityProvider={unityProvider} style={{ height: '88vh', width: '100%', display: isLoaded ? "block" : "none" }} />

            </div>
        </>
    )
}

export default GamePlay;
