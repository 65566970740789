import axios from "../utils/axios";

export const getOfficers = async () => {
    return await axios.get("/officers");
};

export const signupOfficer = async (data) => {
    return await axios.post("/officer/signup", data);
};

export const signupOfficerBulk = async (data) => {
    return await axios.post("/officer/signup/bulk", data);
};

export const postOfficer = async (data) => {
    // console.log('data: ', data);
    return await axios.post("/officer", data);
};

export const getOfficerById = async (officerId) => {
    return await axios.get(`/officer/${officerId}`);
};

export const getVILODates = async (probationerId) => {
    return await axios.get(`/VI-LO-dates/${probationerId}`);
};

