import React, { useState } from "react";

import { TextField, InputAdornment, IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

function ClientListSearchBar({ setSearchKeyWords }) {
    const [input, setInput] = useState("");

    const changeSearchBar = (event) => {
        event.preventDefault();
        const searchKeyWords = event.target.value;
        setSearchKeyWords(searchKeyWords);
        setInput(searchKeyWords);
    };

    const clearSearchBar = () => {
        setSearchKeyWords("");
        setInput("");
    };

    return (
        <TextField
            variant="outlined"
            placeholder="Search Case Reference Number"
            size="small"
            value={input}
            onChange={(event) => changeSearchBar(event)}
            fullWidth
            sx={{ maxWidth: "400px" }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={clearSearchBar}>
                            <CancelIcon />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
}

export default ClientListSearchBar;
