import { React, useEffect, useState, useRef } from 'react'
import { Button, Box, Card, CardMedia, Divider, Typography } from "@mui/material";

import { useNavigate, useLocation } from "react-router-dom"

import { getVISectionResources } from 'src/services/victimimpactServices';

import { IcBandagesBlack } from "src/assets/icons";
import Header from '../../../../components/Header';

import ReactHlsPlayer from '@ducanh2912/react-hls-player';

const TypesOfHurts1 = () => {
    
    const playerRef = useRef();
    const navigate = useNavigate();

    const [ resources, setResources ] = useState([]);
    const prog = localStorage.getItem('toh_prog');
    const currentVideoRef = useRef((prog == null || prog == 'null')? 0: prog);

    const [ currentVideo, setCurrentVideo ] = useState(0); // index 0-6
    const [ play, setPlay ] = useState(true);
    
    const [eventAdded, setEventAdded] = useState(false);

    useEffect(() => {
        const fetchData = async() => {
            setCurrentVideo(currentVideoRef.current);
            const response = await getVISectionResources(3);
            setResources(response.data.value);
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (playerRef && playerRef.current && !eventAdded) {
            setEventAdded(true); // ensure 1 listener/video
        }
        if (playerRef && playerRef.current && !eventAdded) {
            return () => {
                playerRef.current.removeEventListener("play", ()=>{});
                playerRef.current.removeEventListener("ended", ()=>{});
            }
        }
    })

    useEffect(() => {
        if (eventAdded) {
            // console.log('ref: ', playerRef);
            playerRef.current.addEventListener("play", () => console.log('play', currentVideo));
            playerRef.current.addEventListener("ended", () => {
                if (currentVideoRef.current < 6) {
                    currentVideoRef.current++;
                    setCurrentVideo(currentVideoRef.current);
                    localStorage.setItem('toh_prog', currentVideoRef.current);
                }
                setPlay(true);
                // console.log('end')
            });
        }
    }, [eventAdded])

    function playVideo() {
        playerRef.current.play();
    }

    function pauseVideo() {
        playerRef.current.pause();
    }

    return (
        <>
            {/* <Button 
                onClick={() => {
                    console.log('localstorage: ', localStorage.getItem('tov_prog'));
                    console.log('ref: ', playerRef)
                    console.log('currentVideo: ', currentVideo);
                }}
                sx={{background:'black'}}>PRINT
            </Button>
            <Button 
                onClick={() => {
                    localStorage.setItem('toh_prog', null);
                }}
                sx={{background:'black'}}>RESET
            </Button>
            CurrentVideoRef.current: {currentVideoRef.current} */}
            <Header
                title='Types of Hurts'
                text='Watch the following short videos to find out about the different types of hurts victims can experience.'
                icon={IcBandagesBlack}
                iconSize='70%'/>

            <Divider sx={{ mt: {xs: '10px', md:"0px"}, mb: {xs: "20px", md: "40px"}}}/>

            <Box sx={{
                // background: "lightblue",
                display: "flex",
                flexDirection: "column",
                justifyContent: "top",
                alignItems: "center",
                fontSize: "20px",
                height: {xs: "100%", md: '99%'},
                width: {xs: "100%"},
                }}
                maxWidth="false">

                {resources.slice(0,currentVideo).map((r, i) => {
                    return (
                        <Box key={i} sx={{width:{xs: "100%", md: "auto"}, background:"", display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                            <Typography sx={{fontSize:"20px"}}>
                                {r.title}
                            </Typography>
                            <Box
                                sx={{width:{xs: "100%", md: "720px"}, height:{xs: "100%", md: "405px"}, mb:'50px'}}
                                >
                                <ReactHlsPlayer
                                    src={r.videourl}
                                    autoPlay={false}
                                    controls={currentVideoRef.current > i}
                                    width="100%"
                                />
                            </Box>
                        </Box>
                    )
                })}

                {resources.length > 0 && currentVideo < 6?
                    <Box sx={{width:{xs: "100%", md: "auto"}, background:"", display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                        <Typography sx={{fontSize:"20px"}}>
                            {resources[currentVideo].title}
                        </Typography>
                        <Box
                            sx={{width:{xs: "100%", md: "720px"}, height:{xs: "100%", md: "405px"}, mb:'50px'}}
                            >
                            <ReactHlsPlayer
                                playerRef={playerRef}
                                src={resources[currentVideo].videourl}
                                autoPlay={false}
                                controls={currentVideoRef.current > 5}
                                width="100%"
                            />
                        </Box>
                    </Box>
                    :
                    <></>
                }


                <Box sx={{ flexDirection:'column', display:"flex", background:"", justifyContent:"center", mb:{xs:"30px"}}}>
                    <Button
                        sx={{fontSize:"16px", width:"239px", height:"39px" }}
                        variant="rounded"
                        fullWidth
                        size="small"
                        onClick={play?
                            () => {
                                playVideo();
                                setPlay(!play);
                            }
                        :
                            () => {
                                pauseVideo();
                                setPlay(!play);
                            }
                        }
                    >
                        {play ? 'PLAY' : 'PAUSE'}
                    </Button>
                    <Divider sx={{height: '15px', width: '0px'}}/>
                    {currentVideoRef.current >= 6 ? 
                        <Button
                        sx={{fontSize:"16px", width:"239px", height:"39px" }}
                        variant="rounded"
                        fullWidth
                        size="small"
                        onClick={()=> navigate("../types-of-hurts/2")}
                        >
                            CONTINUE
                        </Button>
                    :
                        <></>
                    }
                </Box>
            </Box>  
        </>
    )
}

export default TypesOfHurts1