import * as React from "react";
import { Backdrop, Box, Fade, Modal as MUIModal } from "@mui/material";
import useModal from "src/helpers/useModal";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "#fff",
    border: 0,
    borderRadius: "10px",
    outline: 0,
    boxShadow: 24,
    p: 4,
    maxHeight: '90vh',
    overflow: { md: 'auto', xs:'scroll' },
};

export default function Modal() {
    const { component, maxWidth, open, closeModal } = useModal();

    const handleClose = () => {
        closeModal();
    };

    return (
        <div>
            <MUIModal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={[style, { maxWidth: maxWidth || "500px" }]}>
                        {component}
                    </Box>
                </Fade>
            </MUIModal>
        </div>
    );
}
