import { Outlet } from "react-router-dom";
import LoginSwitcher from "src/components/LoginSwitcher";

const AuthenticationLayout = () => (
    <>
        <LoginSwitcher />
        <Outlet />
    </>
);

export default AuthenticationLayout;
