import React from 'react'

import { Box, Container, Typography, Icon } from "@mui/material";
import { IcPeoplesBlack } from "src/assets/icons";

const Header = ({title, text, icon, iconSize}) => {
    return (
        <>
            <Box sx={{ p:3, background: "", display:{xs: "none", md: "flex"}, alignItems:"center" }}>
                <Icon sx={{display: "flex", justifyContent: "center", background: "", height:"77px", width:"77px"}}>
                    <img src={icon} width={iconSize} alt="Icon" />
                </Icon>
                <Box sx={{ display:"flex", flexDirection:"column" }}>
                    <Typography variant="h2" color="secondary"
                        sx={{ ml: "5px", background: "", fontSize: "32px" }}
                    >
                        {title}
                    </Typography>
                    {text.split("\n").map((i, key) => {
                        return (
                            <Typography key={key} sx={{ height:"20px", background:"", ml:"7.5px" }}>
                                {i}
                            </Typography>
                        )
                    })}
                </Box>
            </Box>

            <Container sx={{
                // background: "grey",
                display: {xs: "flex", md: "none"},
                alignItems: "left",
                flexDirection:"column",
                pt:"5px",
                }}>
                <Box sx={{
                        // background: "lightgreen",
                        display: {xs: "flex", md: "none"},
                        alignItems: "left",
                        flexDirection:"column",
                        width:"100%",
                    }}>
                    <Box sx={{ background: "", display:"flex", flexDirection: "row", alignItems:"center"}}>
                        <Icon sx={{display: "flex", justifyContent: "center", background: "", height:"77px", width:"77px"}}>
                            <img src={icon} width={iconSize} alt="Icon" />
                        </Icon>
                        <Typography variant="h2" color="secondary"
                            sx={{ ml: "5px", background: "", fontSize: "28px" }}
                        >
                            {title}
                        </Typography>
                    </Box>
                    <Typography sx={{ ml: "10px", background: "" }}>
                        {text}
                    </Typography>
                </Box>
            </Container>
        </>
    )
}

export default Header