import React from "react";

const IcLaw = ({ size = 30, color = "#4fafcd" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={size}
            height={size}
            viewBox="0 0 30 30"
        >
            <defs>
                <clipPath id="clip-path">
                    <rect id="Rectangle_230" data-name="Rectangle 230" width="21" height="20.806" fill={color} />
                </clipPath>
            </defs>
            <g id="Types_Sentencing" data-name="Types Sentencing" transform="translate(-14 -434)">
                <g id="Group_483" data-name="Group 483" transform="translate(19 438.643)">
                    <path
                        id="Path_74"
                        data-name="Path 74"
                        d="M29.5,40.6V51.257h-1.37v1.7h-3.6V54H36.776V52.956h-3.6v-1.7H31.81V40.6Z"
                        transform="translate(-20.053 -33.191)"
                        fill={color}
                    />
                    <g id="Group_478" data-name="Group 478">
                        <g id="Group_477" data-name="Group 477" clipPath="url(#clip-path)">
                            <path
                                id="Path_75"
                                data-name="Path 75"
                                d="M17.65,24.8H3.35L0,31.777a3.366,3.366,0,0,0,3.347,3,3.414,3.414,0,0,0,3.354-3L4.049,26.257h12.9L14.3,31.777a3.367,3.367,0,0,0,3.347,3,3.414,3.414,0,0,0,3.354-3Zm-14.3,9.106a2.487,2.487,0,0,1-2.165-1.255h4.33a2.487,2.487,0,0,1-2.165,1.255M.98,31.777l2.37-4.955,2.4,4.955Zm16.666,2.13a2.487,2.487,0,0,1-2.165-1.255h4.33a2.487,2.487,0,0,1-2.165,1.255m-2.367-2.13,2.37-4.955,2.4,4.955Z"
                                transform="translate(0 -20.274)"
                                fill={color}
                            />
                            <path
                                id="Path_76"
                                data-name="Path 76"
                                d="M50.815,3.5l.929-1.936L50.815,0H48.958L48.03,1.561,48.958,3.5Z"
                                transform="translate(-39.262 0)"
                                fill={color}
                            />
                        </g>
                    </g>
                </g>
                <rect
                    id="Rectangle_791"
                    data-name="Rectangle 791"
                    width="30"
                    height="30"
                    transform="translate(14 434)"
                    fill="none"
                />
            </g>
        </svg>
    );
};

export default IcLaw;
