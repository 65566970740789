import React, { Fragment, useState, useCallback, useEffect } from "react";
import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import AppBar from 'src/components/AppBar';
import Footer from './Footer';

// const drawerWidth = 260;
const footerHeight = 100;

function MainLayout(props) {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [screenWidth, setScreenWidth] = React.useState(window.screen.width);
    const [drawerWidth, setDrawerWidth] =  React.useState(260);
    const [aspectRatio, setAspectRatio] = React.useState(window.screen.width/window.screen.height)

    useEffect(() => (
        window.addEventListener('resize', () => {
            if (screenWidth > 900  && screenWidth < 1300) {
                setDrawerWidth(0.2*window.screen.width);
            }
            setAspectRatio(window.screen.width/window.screen.height)
            setScreenWidth(window.screen.width)
        }),
        () => window.removeEventListener('resize', () => {
            if (screenWidth > 900  && screenWidth < 1300) {
                setDrawerWidth(0.17*window.screen.width);
            }
            setAspectRatio(window.screen.width/window.screen.height)
            setScreenWidth(window.screen.width)
        })
    ))

    return (
        <>
            <AppBar toggleSideBar={() => setMobileOpen(mobileOpen => !mobileOpen) } />
            {/* <Box sx={{ background: "", display: 'flex', mb: { xs: props.footer? '20px' : '0', md: props.footer? `${footerHeight}px`: '0'},}}> */}
            <Box sx={{ background: "", display: 'flex', mb: { sm: '0', md: props.footer? `${footerHeight}px`: '0'},}}>
                <Sidebar
                    aspectRatio={aspectRatio}
                    drawerWidth={drawerWidth}
                    mobileOpen={mobileOpen}
                    handleDrawerToggle={() => setMobileOpen(mobileOpen => !mobileOpen)}
                    user={props.user}
                    gameProgress={props.gameProgress}
                />
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        p: props.padding,
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        // width: { md: `calc(100% - ${drawerWidth}px)` },
                        // background: "lightgreen"
                    }}
                    >
                    <Outlet />
                </Box>
            </Box>
            {/* {displayFooter? <Footer officer={props.officer}/> : (<></>)} */}
            <Footer officer={props.officer}/>
        </>
    );
}

export default MainLayout;
