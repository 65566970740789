import React from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import useAlert from "src/helpers/useAlert";
import useModal from "src/helpers/useModal";
import {
    Button,
    Box,
    FormControl,
    TextField,
    Typography,
} from "@mui/material";

import { verifyProbationer } from "src/services/probationerServices";

import { IcCheck, IcStopSign } from "src/assets/icons";

const validationSchema = yup.object({
    email: yup
        .string("Enter officer email")
        .email("Enter a valid email")
        .required("Officer email is required"),
    isActivated: yup.boolean(),
});

const ActivationPopUp = (props) => {
    const { action } = props;
    const { setAlert } = useAlert();
    const { setModal, closeModal } = useModal();
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            email: "",
            isActivated: false,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            // console.log('VALUES:', values);
            const response = await verifyProbationer(values);
            // console.log('response:', response);
            if (response.data.success) {
                if (response.data.value) {
                    // console.log('success');
                    setAlert({
                        icon: IcCheck,
                        title: "Activation Successful",
                        description: "Complete the evaluation questionnaire before you start.",
                        buttonText: "START",
                        type: "OK",
                    });
                    closeModal();
                    navigate("/players/evaluation");
                    setTimeout(() => {
                        navigate("/players/evaluation");
                    }, 1000);
                } else {
                    setAlert({
                        title: "Invalid Officer Email",
                        icon: IcStopSign,
                        description: "Please try again",
                        buttonText: "TRY AGAIN",
                        type: "ERROR",
                    });
                }
            } else {
                setAlert({
                    title: "Invalid Probationer Account",
                    icon: IcStopSign,
                    description: "Please try again",
                    buttonText: "TRY AGAIN",
                    type: "ERROR",
                });
            }
        },
    });
    return (
        <>
            <Typography align="center" fontSize="14pt" sx={{color:"#000000"}}>
                To activate your login, please enter your officer’s email address
            </Typography>
            <form onSubmit={formik.handleSubmit}>
                <Box sx={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                    <FormControl sx={{  my: 1, width:{ xs:"234px", md: "345px"}, }}>
                        <TextField
                            fullWidth
                            id="email"
                            name="email"
                            label={
                                formik.values.email === ""?
                                "Officer's email address"
                                :""
                            }
                            InputLabelProps={{ shrink: false, sx: { color:"#0" }}}
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                            sx={{ maxWidth: "488px", color:"blue"}}
                        />
                    </FormControl>
                    <Button
                            color="green"
                            variant="contained"
                            type="submit"
                            sx={{fontSize:"14pt", width:{ xs:"234px", md: "345px"}}}
                        >
                            ACTIVATE
                    </Button>
                </Box>
            </form>
        </>
    );
};

export default ActivationPopUp;
