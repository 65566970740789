import React from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import {
    Button,
    Divider,
    FormControl,
    Grid,
    Stack,
    TextField,
    Typography,
} from "@mui/material";

import useAlert from "src/helpers/useAlert";
import CustomSwitch from "src/components/CustomSwitch";
import { postOfficer, signupOfficer } from "src/services/officerServices";
import { IcCheck, IcStopSign } from "src/assets/icons";
import useModal from "src/helpers/useModal";
import DeleteAccountPopup from "src/pages/auth/DeleteAccountPopup";
import useAuth from "src/hooks/useAuth";

const validationSchema = yup.object({
    email: yup
        .string("Enter officer email")
        .email("Enter a valid email")
        .required("Officer email is required"),
    fullname: yup.string("Enter officer name").required("Officer name is required"),
    isActivated: yup.boolean().nullable(),
});

const OfficerForm = (props) => {
    const { action, initialValue, closeModal, fetchOfficers } = props;
    // console.log('initial values:', initialValue);
    const { auth } = useAuth();

    const { setAlert } = useAlert();
    const { setModal } = useModal();
    const navigate = useNavigate();

    const handleCreate = async (values) => {
        // console.log('values: ', values);
        const response = await signupOfficer(values);
        const { success, error } = response.data;
        // console.log('response data: ', response.data);
        if (success) {
            setAlert({
                icon: IcCheck,
                title: "Done",
                description: "Officer account has been created",
                buttonText: "CLOSE",
                type: "SUCCESS",
            });
            fetchOfficers();
            closeModal();
        } else {
            setAlert({
                icon: IcStopSign,
                title: "Failed to create officer",
                description: error,
                buttonText: "TRY AGAIN",
                type: "ERROR",
            });
        }
    }

    const handleUpdate = async (values) => {
        const response = await postOfficer(values);
        // console.log('post officer: ', response);
        const { success, error } = response.data;
        if (success) {
            setAlert({
                icon: IcCheck,
                title: "Done",
                description: "Officer account has been saved",
                buttonText: "CLOSE",
                type: "SUCCESS",
            });
            navigate("/officers/officers");
        } else {
            setAlert({
                icon: IcStopSign,
                title: "Failed to update officer",
                description: error,
                buttonText: "TRY AGAIN",
                type: "ERROR",
            });
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            officerId: initialValue?.officerId || "",
            email: initialValue?.username || "",
            fullname: initialValue?.fullname || "",
            isActivated: initialValue?.isActivated === 1 ? true : false || false,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                if (action === "CREATE") {
                    handleCreate(values);
                } else if (action === "UPDATE") {
                    handleUpdate(values);
                } else {
                    alert("invalid action");
                }
            } catch (error) {
                alert("an error occured");
                // console.log(error);
            }
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Typography variant="h3" color="secondary" sx={{ mb: 2 }}>
                {action === "CREATE" ? "Create Officer" : "Edit Officer"}
            </Typography>
            <FormControl fullWidth sx={{ mb: 1 }}>
                <Typography variant="label">
                    Officer Name <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                    fullWidth
                    id="fullname"
                    name="fullname"
                    size="small"
                    value={formik.values.fullname}
                    onChange={formik.handleChange}
                    error={formik.touched.fullname && Boolean(formik.errors.fullname)}
                    helperText={formik.touched.fullname && formik.errors.fullname}
                    sx={{ maxWidth: "488px" }}
                    InputLabelProps={{ shrink: false }}
                />
            </FormControl>

            <FormControl fullWidth sx={{ my: 1 }}>
                <Typography variant="label">
                    Officer Email <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                    fullWidth
                    id="email"
                    name="email"
                    size="small"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    sx={{ maxWidth: "488px" }}
                    InputLabelProps={{ shrink: false }}
                />
            </FormControl>
            {action === "UPDATE" && (
                <FormControl fullWidth sx={{ my: 1 }}>
                    <Typography variant="label">Account Activation</Typography>
                    {formik.touched.isActivated && Boolean(formik.errors.isActivated) && <Typography>{formik.touched.isActivated && formik.errors.isActivated}</Typography>}
                    <Stack direction="row" alignItems="center">
                        <CustomSwitch
                            formikName="isActivated"
                            formikID="isActivated"
                            activated={formik.values.isActivated}
                            inactiveText="Disabled"
                            activeText="Active"
                            callback={(e) => formik.setFieldValue('isActivated', e.target.checked)}
                        />
                    </Stack>
                </FormControl>
            )}

            <Divider sx={{ mt: 6, mb: 3 }} />

            <Grid container rowSpacing={1} alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Typography>
                        <span style={{ color: "red" }}>*</span> Mandatory fields
                    </Typography>
                </Grid>
                <Grid item sx={{ width: '100%', mt: { xs: '20px', md: '0px' }, background: '', display: 'flex', justifyContent: { xs: 'center', md: "right" } }}>
                    <div
                        style={{ display: "flex", gap: 10, alignItems: "center", flexDirection: "row" }}
                    >
                        {action === "UPDATE" && auth?.isSuperAdmin === 1 && <Button
                            color="secondary"
                            variant="rounded"
                            sx={{ minWidth: "200px", backgroundColor: "red", fontSize: "16px" }}
                            onClick={() => {
                                setModal(
                                    <>
                                        <DeleteAccountPopup id={initialValue?.officerId} type={"officer"}/>
                                    </>
                                    , "462px")
                            }}
                        >
                            Archive Account
                        </Button>}
                        <Button
                            color="primary"
                            variant="rounded"
                            type="submit"
                            onClick={formik.handleSubmit}
                            sx={{ minWidth: "200px", fontSize: "16px" }}
                        >
                            {props.buttonText}
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </form>
    );
};

export default OfficerForm;
