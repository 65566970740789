import { createTheme } from "@mui/material/styles";

import FrutigerLTStdRoman from "src/assets/fonts/Frutiger LT Std/FrutigerLTStd-Roman.otf";
import FrutigerLTStdBold from "src/assets/fonts/Frutiger LT Std/FrutigerLTStd-Bold.otf";

export const appTheme = createTheme({
    typography: {
        fontFamily: "Frutiger",
        h2: {
            fontSize: 25,
            fontWeight: 600,
        },
        h3: {
            fontSize: 20,
            fontWeight: 600,
        },
        label: {
            fontSize: "16",
            fontWeight: 600,
        },
        button: {
            fontSize: 12,
        },
    },
    palette: {
        primary: {
            main: "#4FAFCD",
            contrastText: "#FFFFFF",
        },
        secondary: {
            main: "#2F4E91",
            contrastText: "#FFFFFF",
        },
        green: {
            main: "#1CBC90",
            contrastText: "#FFFFFF",
        },
        black: {
            main: "#000000",
            contrastText: "#FFFFFF",
        },
    },
    components: {
        MuiButton: {
            variants: [
                {
                    props: { variant: "rounded" },
                    style: {
                        textTransform: "none",
                        backgroundColor: "#4FAFCD",
                        color: "white",
                        fontSize: "13px",
                        fontWeight: "600",
                        borderRadius: "100px",
                        border: 0,
                        ":hover": {
                            backgroundColor: "#469db8",
                        },
                    },
                },
            ],
        },
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                    font-family: 'Frutiger';
                    font-style: normal;
                    font-display: swap;
                    font-weight: 400;
                    src: local('Frutiger'), local('Frutiger-Regular'), url(${FrutigerLTStdRoman}) format('truetype');
                }
                @font-face {
                    font-family: 'Frutiger';
                    font-style: normal;
                    font-display: swap;
                    font-weight: 600;
                    src: local('Frutiger'), local('Frutiger-Regular'), url(${FrutigerLTStdBold}) format('truetype');
                }
            `,
        },
    },
});
