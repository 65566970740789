import React from 'react'
import {
    Button,
    Box,
    Container,
    Divider,
    Icon,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";

import { IcPoliceWhite, IcHappyFace, IcSadFace, IcCircleCheck, IcCircleCross} from "src/assets/icons";
import IcLaw from './IcLaw';

const data = {
    postiveConsequences: [
        'Continue to have freedom in the community',
        'Achieve goals in my life',
        'Spend time with my family and loved ones',
        'No criminal record'
    ],
    negativeConsequences: [
        'Loss of freedom (Singapore Boys/Girls Home, Reformative Training Centre, Imprisonment)',
        'School/National Service/Employment is disrupted',
        'Loss of time with family and loved ones',
        'Criminal record (RTC, Imprisonment & Fine)'
    ],
    crimes: [
        '1. Theft',
        '2. Forgery',
        '3. Drug Consumption',
        '4. Voluntarily Causing Hurt',
        '5. Rioting'
    ]
}

const SummarySheet = () => {
  return (
    <>
        <Container
            maxWidth={false}
            sx={{
                background: '#011C50',
                m: 0,
                p: {xs: 0, md: 1}, 
                pb:'30px'
            }}>

            <Box sx={{width:'100%', background: "", display:{xs:'none', md: "flex"}, alignItems:'center', justifyContent:'space-between', flexDirection:{xs:"column", md:"row"}}}>
                <Box sx={{p:1, background: "", display:"flex", alignItems:"center", justifyContent:'space-between'}}>
                    <Icon sx={{background: "", height:"42px", width:"42px", display:"flex", justifyContent:"center"}}>
                        <img src={IcPoliceWhite} width="85%" alt="Law & Order Icon" />
                    </Icon>
                    <Typography variant="h2" color="secondary"
                        sx={{color: "white", fontSize: "25px", ml:'5px',}}
                    >
                        Law & Order
                    </Typography>
                </Box>
                <Typography variant='h2' sx={{fontSize:'18px', color:'white'}}>
                    Game Reflection
                </Typography>
            </Box>
            
            <Box sx={{p:1, background: "", display:{xs: "flex", md:'none'}, alignItems:"left", flexDirection: 'column', height: '110px'}}>
                <Box sx={{p:1, background: "", display:{xs: "flex", md:'none'}, alignItems:"center"}}>
                    <Icon sx={{background: "", height:"42px", width:"42px", display:"flex", justifyContent:"center"}}>
                        <img src={IcPoliceWhite} width="85%" alt="Law & Order Icon" />
                    </Icon>
                    <Typography variant="h2" color="secondary"
                        sx={{color: "white", fontSize: "25px", ml:'5px',}}
                    >
                        Law & Order
                    </Typography>
                </Box>
                <Typography variant='h2' sx={{ ml:'15px', fontSize:'18px', color:'white'}}>
                    Game Reflection
                </Typography>
            </Box>

            <Box sx={{ p: {xs: 0, md: 1}, background:'', display:'flex', flexDirection:{xs: 'column', md: 'row'}}}>
                <Box sx={{p: 3, background:'white', borderRadius: {md: '16px'}, display:'flex', flexDirection: 'column', height: '100%', width:{xs:'100%', md: '75%'}}}>
                    <Typography variant='h2' sx={{fontSize:{xs: '30px', md: '24px'}, mb:'15px'}}>
                        Impact On Your Life
                    </Typography>
                    <Box sx={{display:'flex', background:'', flexDirection:{xs: 'column', md: 'row'}, justifyContent:'space-between'}}>

                        <Box sx={{display:'flex', background:'', flexDirection:'column', width:{xs: '100%', md: '45%'}, }}>
                            <Typography variant='h2' sx={{color:'#259F00', fontSize:{xs: '22px', md: '20px'}, mb:'10px'}}>
                                Positive Consequences
                            </Typography>
                            <Box sx={{display:'flex', justifyContent:'center'}}>
                                <img
                                    style={{height:"240px"}}
                                    src={require("src/assets/illustration/Positive-Consequences.png").default}
                                    alt="Positive consequences"
                                />
                            </Box>
                            <Box sx={{ml:'-12px'}}>
                                <List dense={true} >
                                    {data.postiveConsequences.map((e, i) => {
                                        return (
                                            <ListItem sx={{ display:'flex', alignItems:'flex-start', background:''}}  key={i}>
                                                <ListItemIcon sx={{ minWidth:'35px', display:'flex', justifyContent:'left', alignItems:'center', background:''}}>
                                                    <Icon sx={{display: "flex", justifyContent: "center", background: "" }}>
                                                        <img src={IcHappyFace} width="100%" alt="Happy Face" />
                                                    </Icon>
                                                </ListItemIcon>
                                                <Typography sx={{fontSize:'16px', background:''}}>
                                                    {e}
                                                </Typography>
                                            </ListItem>
                                        )
                                    })}
                                </List>
                            </Box>
                        </Box>

                        <Divider orientation='vertical' sx={{ display:{xs:'none', md:'block'}, background:'#D8D8D8', width:'1px', height:'auto'}}/>
                        
                        <Box sx={{display:'flex', background:'', flexDirection:'column', width:{xs: '100%', md: '45%'}, mt:{xs:'20px', md:'0'} }}>
                            <Typography variant='h2' sx={{color:'#BC0202', fontSize:{xs: '22px', md: '20px'}, mb:'10px'}}>
                                Negative Consequences
                            </Typography>
                            <Box sx={{display:'flex', justifyContent:'center'}}>
                                <img
                                    style={{height:'240px'}}
                                    src={require("src/assets/illustration/Negative-Consequences.png").default}
                                    alt="Negative consequences"
                                />
                            </Box>
                            <Box sx={{background: '', ml:'-12px',}}>
                                <List dense={true} sx={{background:""}}>
                                    {data.negativeConsequences.map((e, i) => {
                                        return (
                                            <ListItem sx={{ display:'flex', alignItems:'flex-start', background:''}} key={i}>
                                                <ListItemIcon sx={{ minWidth: '35px', display:'', justifyContent:'left', alignItems:'center', background:''}}>
                                                    <Icon sx={{display: "flex", justifyContent: "center", background: "" }}>
                                                        <img src={IcSadFace} width="100%" alt="Sad Face" />
                                                    </Icon>
                                                </ListItemIcon>
                                                <Typography sx={{fontSize:'16px', background:''}}>
                                                    {e}
                                                </Typography>
                                            </ListItem>
                                        )
                                    })}
                                </List>
                            </Box>
                        </Box>

                    </Box>

                    <Divider sx={{ display: {xs:"none", md:'block'}, mt: '15px', mb:'40px'}}/>

                    <Box sx={{ display:"flex", background:"", justifyContent:{xs: 'center', md: "right"}, mb:{xs:"30px"}, mt: {xs: '15px', md: '0px'}}}>
                        <Button
                            sx={{fontSize:"16px", width:"239px", height:"39px" }}
                            variant="rounded"
                            fullWidth
                            size="small"
                            onClick={()=> console.log("Continue")}
                        >
                            CONTINUE
                        </Button>
                    </Box>
                </Box>
                
                <Divider sx={{ display: {xs:'none', md:'block'}, m:1}}/>
                <Divider sx={{display: {xs: 'block', md: 'none', height:'12px'}}}/>

                <Box sx={{p: 0, background:'#4FAFCD', borderRadius: {md: '16px'}, display:'flex', flexDirection: 'column', height: 'auto', width:{xs:'100%', md: '25%'}}}>
                    <Box sx={{p: 2, background: "", display:"flex", alignItems:"center",}}>
                        <IcLaw size={70} color="white" />
                        <Typography variant="h2" color="secondary"
                            sx={{color: "white", fontSize: "18px", ml:'5px',}}
                        >
                            SUMMARY
                        </Typography>
                    </Box>
                    {data.crimes.map((e, i) => {
                        return (
                            <div key={i}>
                                <Divider sx={{background: 'white', width:'100%', mt:''}}/>
                                <Box sx={{p:2, display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                    <Typography
                                        sx={{color: "white", fontSize: "16px", ml:'5px',}}
                                    >
                                        {e}
                                    </Typography>
                                    <Icon sx={{background: "", height:"35px", width:"35px", display:"flex", justifyContent:"center"}}>
                                        <img src={IcCircleCheck} width="85%" alt="Check" />
                                    </Icon>
                                </Box>
                            </div>
                        )
                    })}

                    <Divider sx={{background: 'white', width:'100%', mt:''}}/>

                </Box>
            </Box>

        </Container>
        <Container maxWidth={false} sx={styles.blackContainer}>
            <Box sx={{fontSize:"12pt"}}>
                The content on this website is not intended to constitute or be relied upon as legal advice. You should consult a qualified lawyer if you require legal advice.
                <br></br>
                ©{new Date().getFullYear()} Ministry of Social and Family Development
            </Box>

            <Box sx={{ width: {xs:"117px", md: "140px"}, height: {xs:"98px", md: "118px"}}}>
                <img
                    style={{width:"100%", height:"100%"}}
                    src={require("src/assets/illustration/MSF-logo-white-highres.png").default}
                    alt="White MSF Logo"
                />
            </Box>
        </Container>
    </>
  )
}

const styles = {
    blackContainer: {
        display: "flex",
        flexDirection: {xs:"column", md:"row"},
        justifyContent: {xs:"left", md:"space-between"},
        alignItems: {md:"center"},
        textAlign: 'left',
        paddingTop: {xs:"20px", md:"0px"},
        background: "#000000 0% 0% no-repeat padding-box",
        height:{xs:"205px", md:"125px"},
        font: "var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 12px/16px var(--unnamed-font-family-frutiger-lt-std)",
        letterSpacing: "var(--unnamed-character-spacing-0)",
        font: "normal normal normal 12px/16px Frutiger LT Std",
        letterSpacing: "0px",
        color: "#A2A2A2",
        opacity: 1,
    }
}

export default SummarySheet;