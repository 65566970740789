import axios from "../utils/axios";

export const getProbationers = async () => {
    return await axios.get("/probationers");
};

export const signupProbationer = async (data) => {
    return await axios.post("/probationer/signup", data);
};

export const signupProbationerBulk = async (data) => {
    return await axios.post("/probationer/signup/bulk", data);
};

export const postProbationer = async (data) => {
    return await axios.post("/probationer", data);
};

export const postProbationerProfileName = async (data) => {
    return await axios.post("/probationer/profile-name", data);
};

export const getProbationerById = async (probationerId) => {
    return await axios.get(`/probationer/${probationerId}`);
};

export const postEvaluation = async (data) => {
    return await axios.post("/evaluation", data);
};

export const postPreference = async (data) => {
    return await axios.post("/preference", data);
};

export const verifyProbationer = async (data) => {
    return await axios.post("/verify/probationer", data);
};

export const getProbationerProfile = async (data) => {
    return await axios.get("/probationer/profile", data);
};

export const clearPasswordAttemptsById = async (probationerId) => {
    return await axios.post(`/password/clear-attempts`, { probationerId:probationerId });
}
