import * as React from 'react';
import { Box, Slider } from '@mui/material';
import '../assets/css/Slider.css';

export default function CustomSlider({
        sx={sx},
        className={className},
        onChange={onChange},
        valueLabelFormat={valueLabelFormat},
        valueLabelDisplay={valueLabelDisplay},
        max={max}, step={step},
        marks={marks}
    })
{
    return (
        <Box sx={{width:'100%'}}>
            <Slider
                sx={sx}
                className={className}
                onChange={onChange}
                valueLabelFormat={valueLabelFormat}
                valueLabelDisplay={valueLabelDisplay}
                max={max}
                step={step}
                marks={marks}
            />
        </Box>
    );
}