import { React, useEffect } from 'react'
import { Button, Box, Container, Divider, Typography, Icon } from "@mui/material";
import { IcPoliceBlack } from "src/assets/icons";

import { useNavigate } from "react-router-dom"

import useModal from "src/helpers/useModal";
import useSession from 'src/hooks/useSession';
import useAuth from 'src/hooks/useAuth';

import LockedPopup from '../popups/LockedPopup';
import {
    getLawAndOrderProgress,
    endLawAndOrderSession,
    getActiveSession,
} from 'src/services/lawandorderServices';

const LawOrder = () => {

    const navigate = useNavigate();
    const { setModal } = useModal();
    const { session, setSession } = useSession();
    const { auth } = useAuth();
    var count = 0;

    useEffect(() => {
        // get active session
        const fetchData = async() => {
            count ++;
            const res = await getActiveSession(auth.probationer.probationerId, 1);
            console.log('getActiveSession: ', res);
            if (res.data.success) {
                const newSession = structuredClone(session);
                newSession.LO_sessionId = res.data.value.sessionId;
                console.log('setSession: ', newSession);
                await setSession(newSession);
            }
        } // call useEffect once only
        // if (count < 1) {
            fetchData();
        // }
    }, []);

    return (
        <div>
            <Box sx={{ p: 3, background: "", display: "flex", alignItems: "center" }}>
                <Icon sx={{ background: "", height: "77px", width: "77px", display: "flex", justifyContent: "center" }}>
                    <img src={IcPoliceBlack} width="85%" alt="Law & Order Icon" />
                </Icon>
                <Typography variant="h2" color="secondary"
                    sx={{ background: "", fontSize: "32px" }}
                >
                    Law & Order
                </Typography>
            </Box>

            <Divider />

            <Container maxWidth={false} sx={{ background: "", width: "100%", display: "flex", justifyContent: "center" }}>
                <Box
                    sx={styles.outerBox}>

                    <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                        <img
                            style={{ width: "240px" }}
                            src={require("src/assets/illustration/gamer.png").default}
                            alt="Gamer"
                        />
                    </Box>
                    <Divider sx={{ display: { xs: 'block', md: 'none' }, height: "5px" }}></Divider>

                    <Box
                        sx={styles.innerBox}
                        width="500px" display="flex" flexDirection="column" justifyContent="left" alignItems="left" >
                        <Typography variant="h2" color="black" sx={{ fontSize: "26px" }}>
                            Play
                        </Typography>
                        <Typography variant="body" color="black" sx={styles.textBox}>
                            Find out more about the laws in Singapore and the consequences.
                        </Typography>
                        <br></br>
                        <Box sx={{ display: "flex", justifyContent: { xs: "center", md: "left" } }}>
                            <Button
                                onClick={async () => {
                                    navigate("scene-selection");
                                    // // TO BE CHANGED LATER
                                    // await endLawAndOrderSession(session.LO_sessionId);
                                    // // Get Sidebar LO Progress
                                    // const loProgress = await getLawAndOrderProgress(auth.probationer.probationerId);
                                    // const newSession = structuredClone(session);
                                    // newSession.LO_progress = loProgress.data.value.LO_progress;
                                    // await setSession(newSession);
                                    // // navigate("play");
                                }}
                                sx={{ background: "black", fontSize: "16px", height: "39px", width: { xs: "310px", md: "181px" } }} variant="rounded" fullWidth size="small">
                                START
                            </Button>
                        </Box>
                    </Box>

                    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                        <img
                            style={{ width: "240px" }}
                            src={require("src/assets/illustration/gamer.png").default}
                            alt="Gamer"
                        />
                    </Box>

                    <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, width: "5px", mr: "50px", ml: "15px" }}></Divider>
                    <Divider orientation="horizontal" sx={{ display: { xs: "block", md: "none" }, height: "5px", width: "100%", mt: "30px" }}></Divider>

                    <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                        <img
                            style={{ width: "201px" }}
                            src={require("src/assets/illustration/App-Learning.png").default}
                            alt="App Learning"
                        />
                    </Box>

                    <Box sx={styles.innerBox}>
                        <Typography variant="h2" color="black" sx={{ fontSize: "26px" }}>
                            Learn
                        </Typography>
                        <Typography variant="body" color="black" sx={styles.textBox}>
                            Find out more about the laws in Singapore and the consequences.
                        </Typography>
                        <br></br>
                        <Box sx={{ display: "flex", justifyContent: { xs: "center", md: "left" } }}>
                            <Button
                                onClick={() => {
                                    console.log({ session: session.LO_progress })
                                    if (session.LO_progress == 1) {
                                        setModal(
                                            <>
                                                <LockedPopup text="Play" />
                                            </>
                                            , '462px');
                                    }
                                    else {
                                        navigate('types-of-sentencing');
                                    }
                                }}
                                sx={{ background: "black", fontSize: "16px", height: "39px", width: { xs: "310px", md: "181px" } }} variant="rounded" fullWidth size="small">
                                START
                            </Button>
                        </Box>
                    </Box>

                    <Divider sx={{ display: { xs: "block", md: "none" }, mb: "20px" }} />

                    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                        <img
                            style={{ width: "201px" }}
                            src={require("src/assets/illustration/App-Learning.png").default}
                            alt="App Learning"
                        />
                    </Box>
                </Box>
            </Container>
        </div>
    )
}

const styles = {
    outerBox: {
        width: { xs: "100%", md: "90%" },
        flexDirection: { xs: 'column', md: 'row' },
        // background:"lightgreen",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mt: "5%",
    },
    innerBox: {
        // background:"lightblue",
        width: { xs: "300px", md: "100%" },
        height: { md: "75%" },
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "left",
    },
    textBox: {
        // background: "pink",
        fontSize: "16px",
        width: "100%",
    }
}


export default LawOrder