import React from "react";
import { Box, Checkbox, FormGroup, FormControl, FormLabel, FormControlLabel } from "@mui/material";
import { useEffect } from "react";

const Question = ({ questionNumber, data, answers, setAnswers }) => {

    const questionIndex = questionNumber - 1;
    const question = data[questionIndex].title;
    const options = data[questionIndex].options

    const handleChange = (checkboxIndex) => (event) => {
        let newAnswers = structuredClone(answers);
        newAnswers.mcq[questionNumber] = options[checkboxIndex];
        setAnswers(newAnswers);
    };

    return (
        <Box sx={styles.selectionBox}>
            <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend" sx={{color:"black !important"}}>{question}</FormLabel>
                <FormGroup sx={{ml:"20px",}}>
                    {options.map((e, i) => {
                        return (
                            <FormControlLabel
                                key={i}
                                control={<Checkbox checked={e === answers.mcq[questionNumber]} onChange={handleChange(i)} name={e} />}
                                label={e}
                            />
                        )
                    })}
                </FormGroup>
            </FormControl>
        </Box>
  )
}

const styles = {
    selectionBox: {
        position: "relative",
        color:"black",
        textAlign: "left",
        right: "0px",
        mx: "auto",
        borderRadius: "20px",
        maxWidth: "1100px",
        padding: "15px 5px",
    },
}

export default Question;