import React from "react";
import { useDrop } from "react-dnd";
import DraggableCard from "./DraggableCard";

const CardReceiver = ({ id, content, receiverId, handleMoveDraggable, width }) => {

    const [{ isOver }, drop] = useDrop({
        accept: "draggable",
        drop: item => handleMoveDraggable(receiverId, item.id),
        collect: monitor => ({
            isOver: !!monitor.isOver()
        })
    });

  return (
    <>
        <div ref={drop} style={styles.answerBox}>
            <DraggableCard id={id} text={content} css={
                {
                    display:"flex",
                    alignItems:"center",
                    background: "var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box",
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    boxShadow: "inset 0px 3px 6px #00000029",
                    border: "1px solid #DEDEDE",
                    borderRadius: "8px",
                    opacity: 1,
                    width: width? width: "300px",
                    height: "75px",
                }
            }></DraggableCard>
        </div>
        <br></br>
    </>
  );
};

const styles = {
    answerBox: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: 'center',
        backgroundColor: "#F5F5F5",
        rp:"15px",
    },
    whitebox: {
        display:"flex",
        alignItems:"center",
        background: "var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "inset 0px 3px 6px #00000029",
        border: "1px solid #DEDEDE",
        borderRadius: "8px",
        opacity: 1,
        width: "300px",
        height: "75px",
    }
}

export default CardReceiver;
