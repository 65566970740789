import { React, useEffect } from 'react'
import { Button, Box, Container, Divider, Grid, TextField, Typography, Icon } from "@mui/material";

import { useNavigate ,redirect} from "react-router-dom"
import useModal from "src/helpers/useModal";
import useSession from 'src/hooks/useSession';
import useAuth from 'src/hooks/useAuth';

import { IcPsychologyBlack } from "src/assets/icons";

import LockedPopup from '../popups/LockedPopup';

import {
    getActiveSession,
    endVictimImpactSession,
    getVictimImpactProgress
} from 'src/services/victimimpactServices';

const VictimImpact = () => {
    const navigate = useNavigate();
    const { setModal } = useModal();

    const locked = true;

    const { session, setSession } = useSession();
    const { auth } = useAuth();
    var count = 0;

    // useEffect(() => {
    //     // get active session
    //     const fetchData = async() => {
    //         count ++;
    //         const res = await getActiveSession(auth.probationer.probationerId, 1);
    //         if (res.data.success) {
    //             const newSession = structuredClone(session);
    //             // console.log(res.data.value);
    //             newSession.VI_sessionId = res.data.value;
    //             await setSession(newSession);
    //         }
    //     } // call useEffect once only
    //     if (count < 1) {
    //         fetchData();
    //     }
    // }, []);

    return (
        <div>
            <Box sx={{p:3, background: "", display:"flex", alignItems:"center"}}>
                <Icon sx={{background: "", height:"77px", width:"77px"}}>
                    <img src={IcPsychologyBlack} width="100%" alt="Psychology Black" />
                </Icon>
                <Typography variant="h2" color="secondary"
                    sx={{background: "", fontSize: "32px"}}
                >
                    Victim Impact
                </Typography>
            </Box>

            <Divider/>

            <Container maxWidth={false} sx={{ background: "", width: "100%",  display: "flex", justifyContent: "center"}}>
                <Box sx={styles.outerBox}>
                    <Box sx={{ display: { xs: 'block', md: 'none'}}}>
                        <img
                            style={{width:"240px"}}
                            src={require("src/assets/illustration/gamer.png").default}
                            alt="Gamer"
                        />
                    </Box>
                    <Divider sx={{ display: { xs: 'block', md: 'none'}, height: "5px"}}></Divider>
                    <Box
                        sx={styles.innerBox}
                        width="500px" display="flex" flexDirection="column" justifyContent="left" alignItems="left" >
                        <Typography variant="h2" color="black" sx={{fontSize:"26px"}}>
                            Play
                        </Typography>
                        <Typography variant="body" color="black" sx={styles.textBox}>
                            Experience a real-life scenario with your friends. What will you choose to do?
                        </Typography>
                        <br></br>
                        <Box sx={{display: "flex", justifyContent:{xs: "center", md: "left"}}}>
                            <Button onClick={async () => {
                                // // TO BE CHANGED LATER
                                // await endVictimImpactSession(session.VI_sessionId);
                                // // Get Sidebar VI Progress
                                // const viProgress = await getVictimImpactProgress(auth.probationer.probationerId);
                                // // console.log('vi progress: ', viProgress);
                                // const newSession = structuredClone(session);
                                // newSession.VI_progress = viProgress.data.value.VI_progress;
                                // await setSession(newSession);
                                // // redirect("/players/victim-impact/play")
                                window.location.href = "/players/victim-impact/play";
                                }} sx={{background:"black", fontSize:"16px", height:"39px", width:{xs: "310px", md: "181px"}}} variant="rounded" fullWidth size="small">
                                START
                            </Button>
                        </Box>
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'block'}}}>
                        <img
                            style={{width:"240px"}}
                            src={require("src/assets/illustration/gamer.png").default}
                            alt="Gamer"
                        />
                    </Box>

                    <Divider orientation="vertical" sx={{ display: {xs: "none", md:"block"}, width:"5px", mr: "50px", ml: "15px"}}></Divider>
                    <Divider orientation="horizontal" sx={{ display: {xs: "block", md: "none"},  height:"5px", width:"100%", mt:"30px"}}></Divider>

                    <Box sx={{ display: { xs: 'block', md: 'none'}}}>
                        <img
                            style={{width:"201px"}}
                            src={require("src/assets/illustration/App-Learning.png").default}
                            alt="App Learning"
                        />
                    </Box>

                    <Box sx={styles.innerBox}>
                        <Typography variant="h2" color="black" sx={{fontSize:"26px"}}>
                            Learn
                        </Typography>
                        <Typography variant="body" color="black" sx={styles.textBox}>
                            Find out more about the victims and what they experienced.
                        </Typography>
                        <br></br>
                        <Box sx={{display:"flex", justifyContent: {xs:"center", md:"left"}}}>
                            <Button 
                                onClick={() => {
                                    if (session.VI_progress == 1) {
                                        setModal(
                                            <>
                                                <LockedPopup text="Play"/>
                                            </>
                                        , '462px');
                                    }
                                    else {
                                        navigate("types-of-victims/1");
                                    }
                                }}
                                sx={{background:"black", fontSize:"16px", height:"39px", width:{xs: "310px", md: "181px"}}} variant="rounded" fullWidth size="small">
                                START
                            </Button>
                        </Box>
                    </Box>

                    <Divider sx={{display: { xs:"block", md: "none" }, mb: "20px"}}/>

                    <Box sx={{ display: { xs: 'none', md: 'block'}}}>
                        <img
                            style={{width:"201px"}}
                            src={require("src/assets/illustration/App-Learning.png").default}
                            alt="App Learning"
                        />
                    </Box>
                </Box>
            </Container>
        </div>
    )
}

const styles = {
    outerBox: {
		width:{xs:"100%", md:"90%"},
		flexDirection:{xs: 'column', md: 'row'},
		// background:"lightgreen",
		display:"flex",
		justifyContent:"space-between",
		alignItems:"center",
        mt:"5%",
	}, 
	innerBox: {
		// background:"lightblue",
		width:{xs:"300px", md:"100%"},
        height: {md:"75%"},
		display:"flex",
		flexDirection:"column",
		justifyContent:"space-between",
		alignItems: "left",
	},
    textBox: {
        // background: "pink",
        fontSize:"16px",
        width: "100%",
    }
}


export default VictimImpact