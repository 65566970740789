import React from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { Button, Stack, Typography, TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";

const validationSchema = yup.object({
    startDate: yup.date().nullable(),
    endDate: yup.date().nullable(),
});

const ReportForm = (props) => {
    const { name, onSubmit, noDates } = props;

    const formik = useFormik({
        initialValues: {
            startDate: null,
            endDate: null,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            onSubmit(values)
        },
    });

    return (
        <div>
            <Typography variant="h3" sx={{ mt: "20px", mb: "10px" }}>
                {name}
            </Typography>
            <form onSubmit={formik.handleSubmit}>
                <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                    { noDates?
                        <></>
                    :
                        <Stack direction="row" spacing={2}>
                            <DesktopDatePicker
                                onChange={(value) =>
                                    formik.setFieldValue("startDate", value, true)
                                }
                                value={formik.values.startDate}
                                label="Start Date"
                                renderInput={(params) => (
                                    <TextField
                                        error={Boolean(
                                            formik.touched.startDate &&
                                                formik.errors.startDate
                                        )}
                                        helperText={
                                            formik.touched.startDate &&
                                            formik.errors.startDate
                                        }
                                        name="startDate"
                                        size="small"
                                        {...params}
                                    />
                                )}
                            />
                            <DesktopDatePicker
                                onChange={(value) =>
                                    formik.setFieldValue("endDate", value, true)
                                }
                                value={formik.values.endDate}
                                label="End Date"
                                renderInput={(params) => (
                                    <TextField
                                        error={Boolean(
                                            formik.touched.endDate &&
                                                formik.errors.endDate
                                        )}
                                        helperText={
                                            formik.touched.endDate &&
                                            formik.errors.endDate
                                        }
                                        name="endDate"
                                        size="small"
                                        {...params}
                                    />
                                )}
                            />
                        </Stack>
                    }
                    <Button
                        variant="contained"
                        size="medium"
                        sx={styles.button}
                        type="submit"
                    >
                        PRINT REPORT
                    </Button>
                </Stack>
            </form>
        </div>
    );
};

const styles = {
    button: {
        minWidth: "180px",
        height: "40px",
        boxShadow: "0",
        fontWeight: "600",
    },
};

export default ReportForm;
