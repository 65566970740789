import axios from "../utils/axios";

// revised
export const getSuperadminAttendanceReport = async (startDate, endDate) => {
    return await axios.get(`/reports/superadmin-attendance-report/${startDate}/${endDate}`, { responseType: "blob" });
};

export const getSuperadminEvaluationReport = async (startDate, endDate) => {
    return await axios.get(`/reports/superadmin-evaluation-report/${startDate}/${endDate}`, { responseType: "blob" });
};

export const getSuperadminVILOResponsesReport = async (startDate, endDate) => {
    return await axios.get(`/reports/superadmin-vilo-responses-report/${startDate}/${endDate}`, { responseType: "blob" });
};

export const getOfficerVIResponsesReport = async () => {
    return await axios.get(`/reports/officer-vi-responses-report`, { responseType: "blob" });
};

export const getOfficerVIResponsesByProbationerId = async (probationerId) => {
    return await axios.get(`/reports/officer-vi-responses-report/${probationerId}`, { responseType: "blob" });
};

export const getOfficerLOResponsesReport = async () => {
    return await axios.get(`/reports/officer-lo-responses-report`, { responseType: "blob" });
};

export const getOfficerLOResponsesByProbationerId = async (probationerId) => {
    return await axios.get(`/reports/officer-lo-responses-report/${probationerId}`, { responseType: "blob" });
};

export const getOfficerEvaluationReport = async () => {
    return await axios.get(`/reports/officer-evaluation-report`, { responseType: "blob" });
};


