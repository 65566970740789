import React from "react";
import moment from "moment";

import { Button, Grid, Typography } from "@mui/material";

import { useState, useEffect, useRef } from "react";
import useMediaQuery from '@mui/material/useMediaQuery';

import ClientListSearchBar from "src/pages/officers/clients/ClientListSearchBar";
import ClientForm from "src/pages/officers/clients/ClientForm";
import useModal from "src/helpers/useModal";
import useAlert from "src/helpers/useAlert";

import Papa from "papaparse";
import readXlsxFile from 'read-excel-file'
import { getOfficerById } from "src/services/officerServices";
import { signupProbationerBulk } from "src/services/probationerServices";
import { useNavigate } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import { IcCheck, IcStopSign } from "src/assets/icons";

function ClientListActions({ setSearchKeyWords }) {
    const { auth } = useAuth();

    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const { setModal, closeModal } = useModal();
    const { setAlert } = useAlert(); 

    const navigate = useNavigate();

    const [accounts, setAccounts] = useState('');
    const [taggedOfficer, setTaggedOfficer] = useState({ accountId: '', username: '', fullname: '', officerId:'',});

    const inputFile = useRef(null);


    const openCreateAccountModal = () => {
        setModal(
            <>
                <ClientForm createNew={true} closeModal={closeModal} action="CREATE" buttonText="CREATE ACCOUNT" />
            </>
        , '800px');
    };

    const parseXlsx = async file => {
        let results = []
        const rows = await readXlsxFile(file, { sheet: 1 });
        rows.slice(1,).forEach(row => {
            results.push({
                crn: row[0],
                email: row[1],
                startdate: moment(row[2], 'DD MM YYYY'),
                enddate: moment(row[3], 'DD MM YYYY'),
                officerEmail: taggedOfficer.username,
                probationerId: ''
            })
        })
        return results;
    }

    const handleOnChange = async e => {
        const postObj = await parseXlsx(e.target.files[0]);
        const response = await signupProbationerBulk(postObj);
        if (response.data.success) {
            if (response.data.value.rejected.length > 0) {
                // console.log('Rejected entries: ', response.data.value.rejected);
                setAlert({
                    icon: IcStopSign,
                    title: "Error",
                    description: "Some accounts were not uploaded properly.",
                    buttonText: "CLOSE",
                    type: "ERROR",
                });
            } else {
                setAlert({
                    icon: IcCheck,
                    title: "Done",
                    description: "Accounts were uploaded successfully.",
                    buttonText: "DONE",
                    type: "SUCCESS",
                });
            }
        } else {
            setAlert({
                icon: IcStopSign,
                title: "Error",
                description: "Error uploading accounts.",
                buttonText: "CLOSE",
                type: "ERROR",
            });
        }
    }
    
    useEffect(() => {
        const fetchData = async () => {
            const officerResponse = await getOfficerById(auth.officer.officerId);
            const value = officerResponse.data.value;
            if (officerResponse.data.success && value != null) {
                delete value.isActivated;
                setTaggedOfficer(value);
            }
        }
        fetchData();
    }, []);

    return (
        <>
            <Grid
                container
                spacing={2}
                direction={{ xs: "column-reverse", md: "row" }}
                justifyContent="space-between"
            >
                <Grid item xs={12} md={6}>
                    <ClientListSearchBar
                        setSearchKeyWords={setSearchKeyWords}
                    />
                </Grid>
                <Grid
                    sx={{background:"", display:"flex", justifyContent:"right"}}
                    container item md={6} spacing={1} alignContent="center">

                    <input type='file' id='file' accept='.xlsx' ref={inputFile} style={{display: 'none'}} onChange={handleOnChange}/>

                    <Grid sx={{background:"", width:"100px"}}
                        item xs={12} md={5.5}>
                        <Button
                            sx={{fontSize:"16px"}}
                            variant="rounded"
                            fullWidth
                            size="small"
                            onClick={() => {
                                setAccounts([]);
                                inputFile.current.click()
                            }}
                        >
                            CREATE BULK ACCOUNTS
                        </Button>
                    </Grid>
                    <Grid
                        sx={{background:""}} 
                        item xs={12} md={5.5}>
                        <Button
                            sx={{fontSize:"16px", width:"100%"}}
                            variant="rounded"
                            fullWidth
                            size="small"
                            onClick={isSmallScreen? () => {navigate('create-new-account')} :  openCreateAccountModal}
                        >
                            CREATE NEW ACCOUNT
                        </Button>
                    </Grid>
                </Grid>

                {/* <Grid container item md={6} spacing={1} alignContent="center">
                    <Grid item xs={12} md={6}>
                        <Button variant="rounded" fullWidth size="small">
                            CREATE BULK ACCOUNTS
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button
                            variant="rounded"
                            fullWidth
                            size="small"
                            onClick={openCreateAccountModal}
                        >
                            CREATE NEW ACCOUNT
                        </Button>
                    </Grid>
                </Grid> */}

            </Grid>
        </>
    );
}

export default ClientListActions;
