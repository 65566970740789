import axios from "../utils/axios";

export const getHome = async () => {
    return await axios.get(`/player/home`);
};

export const getGameProgressByProbationerId = async (probationerId) => {
    return await axios.get(`/game-progress/${probationerId}`);
};

export const endSessionByProbationerId = async (probationerId, gameId) => {
    return await axios.post(`/end-session/${probationerId}/${gameId}`);
};

// answers format: {qnum: int, answer: string}
export const postGameAnswers = async (gameId, answers) => {
    return await axios.post(`/game/response/${gameId}`, answers);
};
export const getScene = async () => {
    return await axios.get(`/scene`); 
};
export const postScene = async (scenename) => {
    return await axios.post(`/scene`, scenename);
};
export const getPreference = async () => {
    return await axios.get(`/preference`);
};
export const endSession = async (gameId) => {
    return await axios.get(`/end-session/${gameId}`);
};
export const getGameReflectionProgress = async (probationerId) => {
    return await axios.get(`/game-reflection-progress/${probationerId}`);
};