import { React, useEffect, useState } from 'react'
import { Button, Box, Card, CardMedia, Divider, Typography } from "@mui/material";

import { useNavigate, useLocation } from "react-router-dom"

import { getVISectionResources } from 'src/services/victimimpactServices';

import { IcShakeHandBlack } from "src/assets/icons";
import Header from '../../../../components/Header';

import ReactHlsPlayer from '@ducanh2912/react-hls-player';

const MakingAmends1 = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [ resources, setResources ] = useState([{title:'', videourl:''}]);

    useEffect(() => {
        // console.log('MAKING AMENDS ID: ', location.state.id);
        const fetchData = async() => {
            const response = await getVISectionResources(4);
            // console.log('response: ', response);
            setResources(response.data.value);
        }
        fetchData();
    }, []);

    return (
        <>
            <Header
                title='Making Amends'
                text='Watch the following short videos to find out about the different ways you can make amends to the victims.'
                icon={IcShakeHandBlack}
                iconSize='85%'/>

            <Divider sx={{ mt: {xs: '10px', md:"0px"}, mb: {xs: "20px", md: "40px"}}}/>

            <Box sx={{
                // background: "lightblue",
                display: "flex",
                flexDirection: "column",
                justifyContent: "top",
                alignItems: "center",
                fontSize: "20px",
                height: {xs: "90%", md: "90%"},
                width: {xs:"100%"},
                }}
                maxWidth="false">

                <Box sx={{width:{xs: "100%", md: "auto"}, background:"", display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                    <Typography sx={{fontSize:"20px"}}>
                        {resources[0].title}
                    </Typography>
                    <Box
                        sx={{width:{xs: "100%", md: "720px"}, height:{xs: "100%", md: "405px"}, mb:'50px'}}
                        >
                        <ReactHlsPlayer
                            // src="https://unity-dashboard.s3.ap-southeast-1.amazonaws.com/videos/Live/CostofCrime_Ex-Probationer_Experience/CostofCrime_Ex-Probationer_Experiencemediaconvert_8500kbits.m3u8"
                            src={resources[0].videourl}
                            autoPlay={true}
                            controls={false}
                            width="100%"
                        />
                    </Box>
                </Box>

                <Box sx={{ display:"flex", background:"", justifyContent:"center", mb:{xs:"30px"}}}>
                    <Button
                        sx={{fontSize:"16px", width:"239px", height:"39px" }}
                        variant="rounded"
                        fullWidth
                        size="small"
                        onClick={()=> navigate("../making-amends/2")}
                    >
                        CONTINUE
                    </Button>
                </Box>

            </Box>
        </>
    )
}

export default MakingAmends1