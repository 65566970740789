import axios from "../utils/axios";

export const login = async (data) => {
    return await axios.post("/login", data);
};

export const verify = async () => {
    return await axios.post("/token/verify");
};

export const logout = async (data) => {
    return await axios.post("/logout", data);
};

export const requestPasswordResetEmail = async (data) => {
    return await axios.post("/password/reset", data);
};

export const resetPassword = async (data) => {
    return await axios.post("/password/reset/change", data);
};

export const getPasswordHistory = async () => {
    return await axios.get("/password-history");
};

export const get2FASetUpStatus = async () => {
    return await axios.get("/2fa/setup-status");
}

export const setUp2FA = async (data) => {
    return await axios.post("/2fa/setup", data);
}

export const verify2FACode = async (data) => {
    return await axios.post("/2fa/verify", data);
}

export const get2FARecoveryStatus = async () => {
    return await axios.post("/2fa/recovery-status");
}

export const create2FARecovery = async (data) => {
    return await axios.post("/2fa/set-recovery-key",data);
}

export const verify2FARecovery = async (data) => {
    return await axios.post("/2fa/verify-recovery-key",data);
}

export const deleteAccountByProbationerId = async (probationerId) => {
    return await axios.post(`/account/delete/probationer` , {probationerId:probationerId});
}

export const deleteAccountByOfficerId = async (officerId) => {
    return await axios.post(`/account/delete/officer` , {officerId:officerId});
}