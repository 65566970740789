import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from 'react-dnd-touch-backend'
import { CssBaseline, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AlertProvider } from "./context/AlertContext";
import { ModalProvider } from "./context/ModalContext";
import Alert from "src/components/Alert";
import Modal from "src/components/Modal";

import { appTheme } from "./themes/theme";
import { AuthProvider } from "./context/AuthProvider";
import { SessionProvider } from "./context/SessionContext";

var userAgent = navigator.userAgent || navigator.vendor || window.opera;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <DndProvider backend={/android/i.test(userAgent)? TouchBackend : HTML5Backend}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <ThemeProvider theme={appTheme}>
                    <BrowserRouter>
                    <SessionProvider>
                        <AuthProvider>
                            <AlertProvider>
                                <ModalProvider>
                                    <CssBaseline enableColorScheme />
                                    <App />
                                    <Alert />
                                    <Modal />
                                </ModalProvider>
                            </AlertProvider>
                        </AuthProvider>
                    </SessionProvider>
                    </BrowserRouter>
                </ThemeProvider>
            </LocalizationProvider>
        </DndProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
