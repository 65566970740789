import React from 'react'
import { Button, Box, Divider } from "@mui/material";
import { IcBandagesBlack, IcCheck, IcStopSign } from "src/assets/icons";

import { useNavigate } from "react-router-dom"
import { useState, useEffect } from 'react';
import useModal from "src/helpers/useModal";
import useAlert from 'src/helpers/useAlert';
import useSession from 'src/hooks/useSession';
import useAuth from 'src/hooks/useAuth';

import Question from './Question';
import Header from '../../../../components/Header';
import AnswersSavedPopup from '../../popups/AnswersSavedPopup';

import {
    getActiveSession,
    getVISectionQuestionData,
    postVictimImpactResponses,
    endVictimImpactSession,
    getDraftAnswers,
    getVictimImpactProgress,
    getVISectionResources
} from 'src/services/victimimpactServices';

import ReactHlsPlayer from '@ducanh2912/react-hls-player';

const TypesOfHurts2 = () => {
    const navigate = useNavigate();
    const { setModal } = useModal();
    const { setAlert } = useAlert();

    const { session, setSession } = useSession();
    const { auth } = useAuth();
    var count = 0;

    const [ resources, setResources ] = useState([]);
    const [ questionData, setQuestionData ] = useState([]);
    const [ answers, setAnswers ] = useState({
        checkboxes: [
            [[ false, false, false], [false, false, false]],
            [[ false, false, false], [false, false, false]],
            [[ false, false, false], [false, false, false]],
        ],
        textfields: [
            [ ['', '', ''], ['', '', ''] ],
            [ ['', '', ''], ['', '', ''] ],
            [ ['', '', ''], ['', '', ''] ],
        ]    
    });

    const handleSubmit = async () => {
        // check if enough boxes filled
        let p_count = 0;
        p_count += answers.textfields[0][0].filter(x=> x==='' ).length;
        p_count += answers.textfields[0][1].filter(x=> x==='' ).length;
        if (p_count > 3) {
            setAlert({
                title: "Error",
                icon: IcStopSign,
                description: "Please fill in the required number of boxes.",
                buttonText: "OK",
                type: "OK",
            });
            return
        }
        for (const ans of answers.textfields) {
            let count = 0;
            count += ans[0].filter(x=> x==='' ).length;
            count += ans[1].filter(x=> x==='' ).length;
            if (count > 5) {
                setAlert({
                    title: "Error",
                    icon: IcStopSign,
                    description: "Please fill in the required number of boxes.",
                    buttonText: "OK",
                    type: "OK",
                });
                return
            }
        }
        // post responses
        const postObj = {isDraft: 0, response: []};
        const victim_types = ['primary', 'secondary', 'indirect'];
        for (let v = 0; v < victim_types.length; v++ ) { // victim types
            for (let i = 0; i < 2; i++) { // 2 rows
                for (let j = 0; j < 3; j++) { // 3 columns
                    let r = [];
                    r.push(victim_types[v]);
                    r.push(questionData[i][j]);
                    r.push(answers.textfields[v][i][j])
                    postObj.response.push(r);
                }
            }
        }
        const response = await postVictimImpactResponses(3, session.ToH_sessionId, postObj);
        if (response.data.success) {
            setAlert({
                title: "Success",
                icon: IcCheck,
                description: "Answers have been successfully submitted.",
                buttonText: "OK",
                type: "OK",
            });
            const res = await endVictimImpactSession(session.ToH_sessionId);
            // Get Sidebar VI Progress
            const viProgress = await getVictimImpactProgress(auth.probationer.probationerId);
            const newSession = structuredClone(session);
            newSession.VI_progress = viProgress.data.value.VI_progress;
            await setSession(newSession);
            await setAnswers({
                checkboxes: [
                    [[ false, false, false], [false, false, false]],
                    [[ false, false, false], [false, false, false]],
                    [[ false, false, false], [false, false, false]],
                ],
                textfields: [
                    [ ['', '', ''], ['', '', ''] ],
                    [ ['', '', ''], ['', '', ''] ],
                    [ ['', '', ''], ['', '', ''] ],
                ]    
            })
            navigate('/players/victim-impact/making-amends/1');
        } else {
            setAlert({
                title: "Error",
                icon: IcStopSign,
                description: "Answers have not been submitted.",
                buttonText: "OK",
                type: "OK"
            });
        }
    }
    
	useEffect(() => {
        const fetchData = async() => {
            count++;
            const resourceRes = await getVISectionResources(3);
            setResources(resourceRes.data.value);
            // get Types of Hurts questions
            const response = await getVISectionQuestionData(2);
            if (response.data.success) {
                // Get ToH sessionId
                const res = await getActiveSession(auth?.probationer?.probationerId, 3);
                if (res.data.success) {
                    const newSession = structuredClone(session);
                    newSession.ToH_sessionId = res.data.value;
                    await setSession(newSession);
                }
                // Set Question Data
                const val = response.data.value;
                let qd = [];
                for (const hurt of val) {
                    qd.push(hurt.typeId);
                }
                let qd_final = [];
                let qd0 = [];
                let qd1 = [];
                for (let i = 0; i < qd.length; i++) {
                    if (i%2===0) {
                        qd0.push(qd[i]);
                    } else {
                        qd1.push(qd[i]);
                    }
                }
                qd_final.push(qd0);
                qd_final.push(qd1);
                await setQuestionData(qd_final);
            }
        }
        if (count < 1) {
            fetchData();
        }
    }, []);

    useEffect(() => {
        const fetchDraft = async() => {
            if ('ToH_sessionId' in session && questionData.length !== 0) {
                // console.log('sessionId: ', session.ToH_sessionId);
                const res = await getDraftAnswers(3, session.ToH_sessionId);
                // console.log('res: ', res);
                if (res.data.success) {
                    const draft = res.data.value;
                    let newAnswers = structuredClone(answers);
                    for (const ans of draft) {
                        if (ans.victim_type === 'primary') {
                            newAnswers.textfields[0][(ans.typeId-1) % 2][Math.floor((ans.typeId-1)/2)] = ans.answer;
                            if (ans.answer !== '') {
                                newAnswers.checkboxes[0][(ans.typeId-1) % 2][Math.floor((ans.typeId-1)/2)] = true;
                            }
                        }
                        if (ans.victim_type === 'secondary') {
                            newAnswers.textfields[1][(ans.typeId-1) % 2][Math.floor((ans.typeId-1)/2)] = ans.answer;
                            if (ans.answer !== '') {
                                newAnswers.checkboxes[1][(ans.typeId-1) % 2][Math.floor((ans.typeId-1)/2)] = true;
                            }
                        }
                        if (ans.victim_type === 'indirect') {
                            newAnswers.textfields[2][(ans.typeId-1) % 2][Math.floor((ans.typeId-1)/2)] = ans.answer;
                            if (ans.answer !== '') {
                                newAnswers.checkboxes[2][(ans.typeId-1) % 2][Math.floor((ans.typeId-1)/2)] = true;
                            }
                        }
                    }
                    // console.log('answers: ', answers);
                    // console.log('newAnswers: ', newAnswers);
                    setAnswers(newAnswers);
                }
            }
        }
        fetchDraft();
    }, [session]);

    return (
        <>
            <Header
                title='Types of Hurts'
                text='Identify what types of hurt each victim experienced. Give an example of how the victim suffered each hurt.'
                icon={IcBandagesBlack}
                iconSize='70%'/>
            
            <Divider sx={{ width:"0px", mt: {xs: '10px', md:"0px"}, mb: {xs: "10px", md: "0px"}}}/>

            {resources?.length > 1? 
            <>
                <Question
                    id={0}
                    title={resources[6].title}
                    victimType="Primary Victim"
                    color="#F5F5F5"
                    height="1075px"
                    noBoxes={3}
                    answers={answers}
                    setAnswers={setAnswers}
                    src={resources[6].videourl}
                />
                <Divider sx={{display:{md:'none', xs:'block'}, height:'20px', width:'0px', mb:'30px'}}/>
                <Question
                    id={1}
                    title={resources[7].title}
                    victimType="Secondary Victim"
                    color="#FFFFFF"
                    height="1100px"
                    noBoxes={1}
                    answers={answers}
                    setAnswers={setAnswers}
                    src={resources[7].videourl}
                />
                <Divider sx={{display:{md:'none', xs:'block'}, height:'20px', width:'0px', mb:'20px'}}/>
                <Question
                    id={2}
                    title={resources[8].title}
                    victimType="Indirect/Hidden victims"
                    color="#F5F5F5"
                    height="1100px"
                    noBoxes={1}
                    answers={answers}
                    setAnswers={setAnswers}
                    src={resources[8].videourl}
                />
            </>
            : <></>}

            <Divider sx={{display:{xs:"none", md:"block"}, width:"0px", height:"50px"}}/>
            <Divider sx={{display:{xs:"block", md:"none"}, width:"0px", height:"30px"}}/>

            <Box sx={{ display:'flex', height:{xs: "120px", md:"130px"}, background:"", justifyContent:"center", flexDirection:{xs:"column", md:"row"}, alignItems:{xs:"center", md:"stretch"}, }}>
                <Button
                    sx={{background:'white', color: "#4FAFCD", border: '1px solid #4FAFCD', fontSize:"16px", width:{xs:"90%", md:"239px"}, height:"39px", }}
                    variant="rounded"
                    fullWidth
                    size="small"
                    onClick={async () => {
                        const postObj = {isDraft: 1, response: []};
                        const victim_types = ['primary', 'secondary', 'indirect'];
                        for (let v = 0; v < victim_types.length; v++ ) { // victim types
                            for (let i = 0; i < 2; i++) { // 2 rows
                                for (let j = 0; j < 3; j++) { // 3 columns
                                    let r = [];
                                    r.push(victim_types[v]);
                                    r.push(questionData[i][j]);
                                    r.push(answers.textfields[v][i][j])
                                    postObj.response.push(r);
                                }
                            }
                        }
                        const response = await postVictimImpactResponses(3, session.ToH_sessionId, postObj);
                        if (response.data.success) {
                            setModal(
                                <>
                                    <AnswersSavedPopup/>
                                </>
                            , '462px');
                        } else {
                            setAlert({
                                title: "Error",
                                icon: IcStopSign,
                                description: response.data.error,
                                buttonText: "OK",
                                type: "OK"
                            });
                        }

                    }}
                >
                    SAVE & CONTINUE LATER
                </Button>

                <Divider sx={{ display: {xs:"none", md:"block"}, height:"0", width:"10px" }}/>
                <Divider sx={{ display: {xs:"block", md:"none"}, height:"10px", width:"0px" }}/>

                <Button
                    sx={{fontSize:"16px", width:{xs:"90%", md:"239px"}, height:"39px" }}
                    variant="rounded"
                    fullWidth
                    size="small"
                    onClick={handleSubmit}
                >
                    SUBMIT
                </Button>
            </Box>
        </>
    )
}

export default TypesOfHurts2