import React from 'react'
import { useEffect, useState } from 'react';
import {
	Button,
	Box,
	Container,
	Typography,
	Divider,
} from "@mui/material";

import { useNavigate } from "react-router-dom"
import useModal from "src/helpers/useModal";
import useAuth from "src/hooks/useAuth";
import useSession from 'src/hooks/useSession';

import { getHome } from 'src/services/gameServices';
import { getLawAndOrderStatus } from 'src/services/lawandorderServices';

import LockedPopup from '../popups/LockedPopup';
import ProfileNameModal from '../popups/ProfileNamePopup';
import { getPasswordHistory } from 'src/services/authServices';
import useAlert from 'src/helpers/useAlert';
import { IcStopSign } from 'src/assets/icons';

const PlayerLandingPage = () => {

	const navigate = useNavigate();
	const { setModal } = useModal();
	const { setAlert } = useAlert();
	const { auth, setAuth } = useAuth();
	const { session, setSession } = useSession();

	const [resources, setResources] = useState([{ imageUrl: '', description: '' }, { imageUrl: '', description: '' }]);

	const [loUnlocked, setLoUnlocked] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			const response = await getHome();
			setResources(response.data.value);
			const postObj = structuredClone(auth);
			postObj.probationerId = auth.probationer.probationerId
			const loStatus = await getLawAndOrderStatus(postObj);
			// console.log('status: ', loStatus);
			setLoUnlocked(loStatus.data.value);
		}
		fetchData();
	}, []);

	useEffect(() => {

		const fetchHistory = async () => {
			let firstLogin = localStorage.getItem('firstLogin');
			if (firstLogin !== '1') return 
			const histRes = await getPasswordHistory();
			if (histRes.data.success) {
				if (histRes.data.value.length === 0) {
					// setAlert({
					// 	icon: IcStopSign,
					// 	title: "Please change your password",
					// 	description: "Password should be changed on first login.",
					// 	buttonText: "Change Password",
					// 	type: "OK",
					// });
					navigate('/players/profile', {state: false});
					
				}
			}
		}
		fetchHistory();
	}, []);

	return (
		<div>
			<Container sx={styles.whiteContainer}>
				<Box
					sx={styles.headerBox}>
					<img
						style={{ width: "226px" }}
						src={require("src/assets/common/msf-logo.png").default}
						alt="MSF Logo"
					/>
					<Divider sx={{ mb: '20px' }} />
					<Typography align='center' sx={{ fontSize: { xs: "24px", md: "25px" }, flexWrap: "wrap" }} variant="h2" color="black">
						Probation and Community Rehabilitation Service
					</Typography>
					<Divider sx={{ margin: "1px" }}></Divider>
					<Typography align='center' sx={{ fontSize: { xs: "24px", md: "25px" }, flexWrap: "wrap" }} variant="h2" color="black">
						My Probation Journey
					</Typography>
				</Box>
				<br></br>

				<Box
					sx={styles.outerBox}>
					<Box sx={{ display: { xs: 'block', md: 'none' } }}>
						<img
							style={{ width: "160px" }}
							src={resources[0]?.imageUrl === '' ? <></> : resources[0]?.imageUrl}
							alt="victim-impact"
						/>
					</Box>

					<Box
						sx={styles.innerBox}
						width="500px" display="flex" flexDirection="column" justifyContent="left" alignItems="left" >
						<Typography variant="h2" color="black" sx={{ fontSize: "26px" }}>
							{resources.length === 0 ? '' : resources[0].name}
						</Typography>
						<Typography variant="body" color="black" sx={{ fontSize: "16px" }}>
							{resources[0].description}
						</Typography>
						<br></br>
						<Box sx={{ display: "flex", justifyContent: "center" }}>
							<Button onClick={async () => {
								const newAuth = structuredClone(auth);
								newAuth.gameId = resources[0].gameId;
								// console.log('newAuth: ', newAuth);
								await setAuth(newAuth);
								navigate("../victim-impact")
							}}
								sx={{ background: "black", fontSize: "16px", height: "39px", width: "181px" }} variant="rounded" fullWidth size="small">
								ENTER
							</Button>
						</Box>
					</Box>

					<Divider sx={{ display: { xs: 'none', md: 'block' }, margin: "10px" }} ></Divider>

					<Box sx={{ display: { xs: 'none', md: 'block' } }}>
						<img
							style={{ width: "160px" }}
							src={resources[0]?.imageUrl === '' ? <></> : resources[0]?.imageUrl}
							alt="victim-impact"
						/>
					</Box>
					<Divider sx={styles.desktopDivider} flexItem={true}></Divider>
					<Divider sx={styles.mobileDivider}></Divider>

					<Box sx={{ display: { xs: 'block', md: 'none' } }}>
						<img
							style={{ width: "160px" }}
							src={resources[1]?.imageUrl === '' ? <></> : resources[1]?.imageUrl}
							alt="victim-impact"
						/>
					</Box>

					<Box sx={styles.innerBox}>
						<Typography variant="h2" color="black" sx={{ fontSize: "26px" }}>
							{resources.length === 0 ? '' : resources[1].name}
						</Typography>
						<Typography variant="body" color="black" sx={{ fontSize: "16px" }}>
							{resources[1].description}
						</Typography>
						<br></br>
						<Box sx={{ display: "flex", justifyContent: "center" }}>
							<Button
								onClick={async () => {
									if (!loUnlocked) {
										setModal(
											<>
												<LockedPopup text="Victim Impact" />
											</>
											, '462px');
									}
									else {
										const newAuth = structuredClone(auth);
										newAuth.gameId = resources[1].gameId;
										await setAuth(newAuth);
										navigate("../law-and-order")
									}
								}}
								sx={{ background: "black", fontSize: "16px", height: "39px", width: "181px" }} variant="rounded" fullWidth size="small">
								ENTER
							</Button>
						</Box>
					</Box>

					<Box sx={{ display: { xs: 'none', md: 'block' } }}>
						<img
							style={{ width: "160px" }}
							src={resources[1]?.imageUrl === '' ? <></> : resources[1]?.imageUrl}
							alt="victim-impact"
						/>
					</Box>
				</Box>
			</Container>
		</div>
	)
}

const styles = {
	whiteContainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		width: { md: "90%", xs: "100%" },
		paddingTop: { md: "40px", xs: "0px" },
		paddingBottom: { md: "100px", xs: "0px" },
		marginBottom: "0px",
		flex: "1",
		// background: "lightgrey",
	},
	headerBox: {
		// background:"pink",
		width: { xs: "300px", md: "100%" },
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	},
	outerBox: {
		width: { xs: "300px", md: "100%" },
		flexDirection: { xs: 'column', md: 'row' },
		// background:"lightgreen",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
	innerBox: {
		// background:"lightblue",
		width: { xs: "300px", md: "500px" },
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		alignItems: "left",
	},
	desktopDivider: {
		marginLeft: { md: "25px" },
		marginRight: { md: "65px" },
		background: "#C6C6C6",
		height: { xs: "0px", md: "200px" },
		width: "2px",
	},
	mobileDivider: {
		display: { md: "none" },
		background: "#C6C6C6",
		height: "2px",
		width: "120%",
		marginTop: "24px",
		marginBottom: "48px",
	},
}

export default PlayerLandingPage