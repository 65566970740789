import React from "react";
import { Button, Divider, Typography } from "@mui/material";
import ReportForm from "src/pages/officers/reports/ReportForm";
import {
    getOfficerVIResponsesReport,
    getSuperadminAttendanceReport,
    getSuperadminEvaluationReport,
    getSuperadminVILOResponsesReport,
    getOfficerLOResponsesReport,
    getOfficerEvaluationReport
} from "src/services/reportServices";

import moment from "moment";
import generateCSV from "src/utils/generateCSV";
import useAlert from "src/helpers/useAlert";
import { IcStopSign } from "src/assets/icons";
import useAuth from "src/hooks/useAuth";


const Reports = () => {
    const { setAlert } = useAlert();
    const { auth } = useAuth();

    // revised
    const downloadAttendanceReport = async (values) => {
        const { startDate, endDate } = values;
        const response = await getSuperadminAttendanceReport(
            moment(startDate).format("DD-MM-YYYY"),
            moment(endDate).format("DD-MM-YYYY")
        );
        if (auth.isSuperAdmin) {
            generateCSV(response.data, `Attendance Report Super Admin ${startDate} - ${endDate}`);
        } else {
            setAlert({
                title: "Error",
                icon: IcStopSign,
                description: "You do not have superadmin rights.",
                buttonText: "OK",
                type: "OK",
            });
        }
    };
    const downloadVIResponsesReport = async (values) => {
        const response = await getOfficerVIResponsesReport();
        generateCSV(response.data, "VI Officer");
    };
    const downloadLOResponsesReport = async (values) => {
        const { startDate, endDate } = values;
        const response = await getOfficerLOResponsesReport();
        generateCSV(response.data, "LO Officer");
    };
    const downloadEvaluationResponsesReport = async (values) => {
        const { startDate, endDate } = values;
        const response = await getOfficerEvaluationReport();
        generateCSV(response.data, "Pre Post test Officer");
    };
    const downloadProgrameCompletionReport = async (values) => {
        const { startDate, endDate } = values;
        const response = await getSuperadminEvaluationReport(
            moment(startDate).format("DD-MM-YYYY"),
            moment(endDate).format("DD-MM-YYYY")
        );
        if (auth.isSuperAdmin) {
            generateCSV(response.data, `Pre Post Test Super Admin Data ${startDate} - ${endDate}`);
        } else {
            setAlert({
                title: "Error",
                icon: IcStopSign,
                description: "You do not have superadmin rights.",
                buttonText: "OK",
                type: "OK",
            });
        }
    };
    const downloadVILOCompletionReport = async (values) => {
        const { startDate, endDate } = values;
        const response = await getSuperadminVILOResponsesReport(
            moment(startDate).format("DD-MM-YYYY"),
            moment(endDate).format("DD-MM-YYYY")
        );
        if (auth.isSuperAdmin) {
            generateCSV(response.data, `VI&LO Super Admin Data ${startDate} - ${endDate}`);
        } else {
            setAlert({
                title: "Error",
                icon: IcStopSign,
                description: "You do not have superadmin rights.",
                buttonText: "OK",
                type: "OK",
            });
        }
    };

    return (
        <div>
            <Typography variant="h2" color="secondary">
                Reports
            </Typography>
            <Divider sx={{ mt: "15px" }} />

            {auth.isSuperAdmin?
                <>
                    <ReportForm onSubmit={downloadAttendanceReport} name="Client Attendance" />
                    <Divider sx={{ mt: "30px" }} />
                    <ReportForm onSubmit={downloadProgrameCompletionReport} name="Pre & Post Evaluation Results" />
                    <Divider sx={{ mt: "30px" }} />
                    <ReportForm onSubmit={downloadVILOCompletionReport} name="VI/LO Responses" />
                </>
            :
                <>
                    <ReportForm noDates={true} onSubmit={downloadVIResponsesReport} name="VI Responses" />
                    <Divider sx={{ mt: "30px" }} />
                    <ReportForm noDates={true} onSubmit={downloadLOResponsesReport} name="LO Responses" />
                    <Divider sx={{ mt: "30px" }} />
                    <ReportForm noDates={true} onSubmit={downloadEvaluationResponsesReport} name="Evaluation Responses" />
                </>}
        </div>
    );
};

const styles = {
    button: {
        minWidth: "180px",
        height: "40px",
        boxShadow: "0",
        fontWeight: "600",
    },
};

export default Reports;
