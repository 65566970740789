import React, { useEffect, useState } from "react";
import { Divider, Typography } from "@mui/material";
import OfficerForm from "./OfficerForm";
import { useParams } from "react-router-dom";
import { getOfficerById } from "src/services/officerServices";

const OfficerProfile = () => {
    const { officerId } = useParams();
    const [ officer, setOfficer ] = useState({});

    useEffect(() => {
        const fetchOfficer = async () => {
            const response = await getOfficerById(officerId);
            const { data } = response;
            setOfficer(data.value);
        }
        fetchOfficer();
    }, []);
    
    return (
        <div>
            <Typography variant="h2" color="secondary">
                Officer Profile
            </Typography>
            <Divider sx={{ mt: "15px", mb: "25px" }} />
            <OfficerForm initialValue={officer} action="UPDATE" buttonText="SAVE CHANGES" />
        </div>
    );
};

export default OfficerProfile;
