import axios from "../utils/axios";

// Get VI Sidebar data: Icons/Links
export const getVictimImpact = async () => {
    return await axios.get("/victim-impact");
};

// Create game_probationer gameId=1 session
// probationer: {accountId, officerId, probationerId, username (email)}
export const unlockVictimImpact = async (probationer) => {
    return await axios.post(`/victim-impact/unlock`, probationer);
};

// Get VI Section Question Data
// sectionId: TypesOfVictims : 1, TypesOfHurts : 2, ...
export const getVISectionQuestionData = async (sectionId) => {
    return await axios.get(`/victim-impact/question-data/${sectionId}`);
};

// Get VI Resources/Videos  
// sectionId: TypesOfVictims : 1, TypesOfHurts : 2, ...
export const getVISectionResources = async (sectionId) => {
    return await axios.get(`/victim-impact/resources/${sectionId}`);
};

// Get active (enddate != null) session if exists, get, else create active session
export const getActiveSession = async (probationerId, applearningId) => {
    return await axios.get(`/victim-impact/sessionId/${probationerId}/${applearningId}`);
};

// responses format: { isDraft: 0/1, responses: []}
export const postVictimImpactResponses = async (applearningId, sessionId, responses) => {
    return await axios.post(`/victim-impact/responses/${applearningId}/${sessionId}`, responses);
};

// Get most recent draft answers by sessionId
// applearningId: ToV: 2, ToH: 3, ...
export const getDraftAnswers = async (applearningId, sessionId) => {
    return await axios.get(`/victim-impact/draft/${applearningId}/${sessionId}`);
}

// If session exists + end_time is null, fill end_time
export const endVictimImpactSession = async (sessionId) => {
    return await axios.post(`/victim-impact/end-session/${sessionId}`);
}

// Get progress for displaying sidebar icons progressively
export const getVictimImpactProgress = async (probationerId) => {
    return await axios.get(`/victim-impact/progress/${probationerId}`);
}

export const getVictimImpactProgressWithEva = async (probationerId) => {
    return await axios.get(`/victim-impact/progress-eva/${probationerId}`);
}


// TEMPORARY API: Create session w end_time = NOW()
export const postVictimImpactGameSession = async (probationerId) => {
    return await axios.post(`/victim-impact/game/${probationerId}`);
}
