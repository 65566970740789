import React, { useEffect, useState } from "react";
import { Box, IconButton, Divider, Grid, Typography } from "@mui/material";
import ClientForm from "./ClientForm";
import { useParams } from "react-router-dom";
import { getProbationerById } from "src/services/probationerServices";
import { IcDownload } from "src/assets/icons";
import useAuth from "src/hooks/useAuth";
import useAlert from "src/helpers/useAlert";
import generateCSV from "src/utils/generateCSV";

import {
    getOfficerVIResponsesByProbationerId,
    getOfficerLOResponsesByProbationerId
} from "src/services/reportServices";


const ClientProfile = () => {
    const { clientId } = useParams();
    const [ probationer, setProbationer] = useState({probationerId:''});
    const { auth } = useAuth();
    const { setAlert, closeAlert } = useAlert();

    useEffect(() => {
        const fetchProbationer = async () => {
            // console.log('id:', clientId);
            const response = await getProbationerById(clientId)
            // console.log('probationer/initialValue:', response);
            const { data } = response;
            setProbationer(data.value);
        }
        fetchProbationer();

        
    }, []);

    return (
        <Box sx={{background: '', width:'100%'}}>
            <Grid container sx={{width:'100%', display:'flex', justifyContent: 'space-between', alignItems:'center', background:"", }}>
                <Grid item>
                    <Typography variant="h2" color="secondary">
                        Client Profile
                    </Typography>
                </Grid>

                <Divider sx={{display:{xs:'block', md:'none'}, mt:'20px', mb:'20px', width:'100%'}}/>
                <Grid item sx={{width: {xs:'100%', md: '40%'}, background: "", display: 'flex', flexDirection: 'row', justifyContent: {xs: 'center', md: 'right'} }}>
                    <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '3px', display:'flex', alignItems: 'center', justifyContent:'space-around', background: '#F5F5F5', width:{xs:'50%', md: '160px'}, height:'42px'}}>
                        <Typography sx={{ml:'5px', color:'#2F4E91'}}>
                            VI Responses
                        </Typography>
                        <IconButton
                            onClick={async values => {
                                const response = await getOfficerVIResponsesByProbationerId(clientId);
                                generateCSV(response.data, `Probationer ID ${clientId} VI Responses`);
                            }}
                        >
                            <img src={IcDownload}/>
                        </IconButton>
                    </Box>

                    <Divider sx={{margin:'10px', height:'0px'}}/>
                    
                    <Box sx={{ border: '1px solid #E0E0E0',  borderRadius: '3px', display:'flex', alignItems: 'center', justifyContent:'space-around', background: '#F5F5F5', width:{xs:'50%', md: '160px'}, height:'42px'}}>
                        <Typography sx={{ml:'5px', color:'#2F4E91'}}>
                            LO Responses
                        </Typography>
                        <IconButton
                            onClick={async values => {
                                const response = await getOfficerLOResponsesByProbationerId(clientId);
                                generateCSV(response.data, `Probationer ID ${clientId} LO Responses`);
                            }}
                        >
                            <img src={IcDownload}/>
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>

            <Divider sx={{ width: {xs:'0', md:'100%'}, mt: "15px", mb: {xs:'10px', md: "25px"} }} />
            <ClientForm createNew={false} initialValue={probationer} action="UPDATE" buttonText='SAVE CHANGES' />
        </Box>
    );
};

export default ClientProfile;
