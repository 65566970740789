import * as React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from "@mui/material";

import useAlert from "src/helpers/useAlert";
import { useNavigate } from "react-router-dom";

const Alert = () => {
    const { title, description, buttonText, open, nextDest, closeAlert, icon } = useAlert();
    const navigate = useNavigate();

    const handleClose = () => {
        closeAlert();
        if ( nextDest !== undefined){
            navigate(nextDest);
        }
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                fullWidth
                maxWidth="xs"
                aria-describedby="alert-dialog-description"
                sx={{ textAlign: "center" }}
                PaperProps={{
                    style: {
                        borderRadius: "13px",
                        padding: "30px 30px",
                    },
                }}
            >
                <DialogTitle id="alert-dialog-title" sx={{ paddingBottom: 0 }}>
                    <img src={icon} width="61px" alt="Stop Sign Icon" />
                    <Typography sx={{ mt: 0, fontWeight: 'bold', fontSize: '24px' }}>
                        {title}
                    </Typography>
                </DialogTitle>
                <DialogContent sx={{ paddingBottom: "10px" }}>
                    <DialogContentText sx={{ width:'100%', background: '', fontSize: "18px"}}id="alert-dialog-description">
                        {description}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        sx={{fontSize: "18px"}}
                        fullWidth
                        color="green"
                        variant="contained"
                        type="submit"
                        onClick={handleClose}
                    >
                        {buttonText}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Alert;
