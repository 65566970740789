import React from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';
import { AppBar as MUIAppBar, Toolbar, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

function AppBar({ toggleSideBar }) {
  const isSmallScreen = useMediaQuery('(max-width:1025px)');

  return isSmallScreen ? (
    <MUIAppBar position="static" sx={{ backgroundColor: '#193465', paddingBlock: '10px' }}>
      <Toolbar>
        <IconButton size="large" edge="start" color="inherit" onClick={toggleSideBar}>
          <MenuIcon />
        </IconButton>
        <span style={{ flexGrow: 1 }} />
        <img
          width="114px"
          src={require('src/assets/illustration/MSF-logo-white-highres.png').default}
          alt="MSF logo in white"
        />
      </Toolbar>
    </MUIAppBar>
  ) : (
    <></>
  );
}

export default AppBar;
