import React from "react";
import { useDrag } from "react-dnd";

function DraggableCard({ id, text, css }) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "draggable",
    item: { id: id },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));
  return (
    <div ref={drag} style={css}>
        <div style={styles.textbox}>{text}</div>
    </div>
  );
}

const styles = {
    textbox: {
      display:"flex",
      position:"relative",
      left:"10px",
      alignItems:"center",
    },
}

export default DraggableCard;
