import React from 'react'
import {
    Box,
    Divider,
    Typography,
} from "@mui/material";

const FinesPopup = ({title, description}) => {

    const lines = description.split('|||');
    // console.log('lines: ', lines);

    return (
        <Box sx={{height:"fit-content", width: '100%', display:"flex", overflow: 'auto', justifyContent:"center", alignItems:"left", flexDirection:"column"}}>
            <Typography sx={{fontWeight: 'bold', fontSize: '22px'}}>
                {title}
            </Typography>
            <Divider sx={{margin:'5px', width:'0px'}}/>
            {lines.length === 1? 
                <Typography sx={{fontSize: {xs: '18px', md: '16px'}}}>
                    {description}
                </Typography>
            : lines.map(line => {
                    return(
                        <Typography sx={{fontSize: {xs: '18px', md: '16px'}}}>
                            {line}
                        </Typography>
                    )
                })
            }

        </Box>
    )
}

export default FinesPopup