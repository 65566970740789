import React from 'react'
import {
    Button,
    Box,
    Container,
    Divider,
    Icon,
    Slider,
    Switch,
    TextField,
    Typography,
} from "@mui/material";

import { useState, useEffect } from 'react';
import useModal from "src/helpers/useModal";
import useAlert from "src/helpers/useAlert";
import useAuth from 'src/hooks/useAuth';
import useSession from 'src/hooks/useSession';

import { useLocation } from 'react-router-dom';

import {
    getLOGameReflectionQuestionData,
    postGameReflectionResponses,
} from 'src/services/lawandorderServices';

import {
    getGameReflectionProgress,
    endSessionByProbationerId
} from 'src/services/gameServices';

import { IcPoliceWhite, IcCircleCheck, IcStopSign } from "src/assets/icons";
import IcLaw from './IcLaw';

import SceneCompletedPopup from '../popups/SceneCompletedPopup';

import CustomSlider from 'src/components/CustomSlider';
import CustomSwitch from 'src/components/CustomSwitch';

const data = {
    postiveConsequences: [
        'Continue to have freedom in the community',
        'Achieve goals in my life',
        'Spend time with my family and loved ones',
        'No criminal record'
    ],
    negativeConsequences: [
        'Loss of freedom (Singapore Boys/Girls Home, Reformative Training Centre, Imprisonment)',
        'School/National Service/Employment is disrupted',
        'Loss of time with family and loved ones',
        'Criminal record (RTC, Imprisonment & Fine)'
    ],
    crimes: [
        '1. Theft',
        '2. Forgery',
        '3. Drug Consumption',
        '4. Voluntarily Causing Hurt',
        '5. Rioting'
    ]
}

function valueLabelFine(value) {
    return `$${value / 1000},000`;
}

const GameReflection = () => {
    const { setAlert } = useAlert();
    const { state } = useLocation();

    let subId = state

    const { setModal } = useModal();
    const { auth } = useAuth();
    const { session } = useSession();

    const [displayQuestionIndex, setdisplayQuestionIndex] = useState(subId - 1); // categoryId
    const [reflectionProgress, setReflectionProgress] = useState([]);
    const [questionData, setQuestionData] = useState({});
    const [answers, setAnswers] = useState([0, 0, false, 0, ''])
    const [prog, setProg] = useState([])

    const handleFineSlider = (event, newValue) => {
        const newAnswers = structuredClone(answers);
        newAnswers[0] = newValue;
        setAnswers(newAnswers);
    };

    const handleTermSlider = (event, newValue) => {
        const newAnswers = structuredClone(answers);
        newAnswers[1] = newValue;
        setAnswers(newAnswers);
    };

    const handleSwitch = event => {
        const newAnswers = structuredClone(answers);
        newAnswers[2] = event.target.checked;
        setAnswers(newAnswers);
    };

    const handleAgreeSlider = (event, newValue) => {
        const newAnswers = structuredClone(answers);
        newAnswers[3] = newValue;
        setAnswers(newAnswers);
    };

    const handleText = event => {
        let newAnswers = structuredClone(answers);
        newAnswers[4] = event.target.value;
        setAnswers(newAnswers);
    }

    useEffect(() => {
        const reloadCount = sessionStorage.getItem('reloadCount');
        if (reloadCount == null) {
            sessionStorage.setItem('reloadCount', 1);
            window.location.reload();
        }
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            const prog = localStorage.getItem('reflection_progress');
            if (prog != null) {
                setProg(prog);
            }
            const response = await getLOGameReflectionQuestionData();
            if (response.data.success) {
                setQuestionData(response.data.value)
            }
            const gameProgressResponse = await getGameReflectionProgress(auth.probationer.probationerId);
            if (gameProgressResponse.data.success) {
                setReflectionProgress(gameProgressResponse.data.value);
            }
        }
        fetchData();
    }, []);

    // {prog.map((subId, i) => {
    //     return (
    //         <div key={subId}>
    //             <Divider sx={{background: 'white', width:'100%', mt:''}}/>
    //             <Box sx={{p:2, display:'flex', justifyContent:'space-between', alignItems:'center'}}>
    //                 <Typography
    //                     sx={{color: "white", fontSize: "16px", ml:'5px',}}
    //                 >
    //                     {i+1}. {questionData.categories[subId].title}
    //                 </Typography>
    //                 <Icon sx={{background: "", height:"35px", width:"35px", display:"flex", justifyContent:"center"}}>
    //                     {/* logic for displaying icon here */}
    //                     <img src={IcCircleCheck} width="85%" alt="Check" />
    //                 </Icon>
    //             </Box>
    //         </div>
    //     )
    // })}

    // {Object.keys(questionData).length === 0? <></> : questionData.categories.slice(0, displayQuestionIndex).map((cat, i) => {
    //     return (
    //         <div key={i}>
    //             <Divider sx={{background: 'white', width:'100%', mt:''}}/>
    //             <Box sx={{p:2, display:'flex', justifyContent:'space-between', alignItems:'center'}}>
    //                 <Typography
    //                     sx={{color: "white", fontSize: "16px", ml:'5px',}}
    //                 >
    //                     {cat.categoryId}. {cat.title}
    //                 </Typography>
    //                 <Icon sx={{background: "", height:"35px", width:"35px", display:"flex", justifyContent:"center"}}>
    //                     {/* logic for displaying icon here */}
    //                     <img src={IcCircleCheck} width="85%" alt="Check" />
    //                 </Icon>
    //             </Box>
    //         </div>
    //     )
    // })}

    return (
        <>
            <Container
                maxWidth
                sx={{
                    background: '#011C50',
                    m: 0,
                    p: { xs: 0, md: 2 },
                    pb: '30px'
                }}>

                <Box sx={{ width: '100%', background: "", display: { xs: 'none', md: "flex" }, alignItems: 'center', justifyContent: 'space-between', flexDirection: { xs: "column", md: "row" } }}>
                    <Box sx={{ p: 1, background: "", display: "flex", alignItems: "center", justifyContent: 'space-between' }}>
                        <Icon sx={{ background: "", height: "42px", width: "42px", display: "flex", justifyContent: "center" }}>
                            <img src={IcPoliceWhite} width="85%" alt="Law & Order Icon" />
                        </Icon>
                        <Typography variant="h2" color="secondary"
                            sx={{ color: "white", fontSize: "25px", ml: '5px', }}
                        >
                            Law & Order
                        </Typography>
                    </Box>
                    <Typography variant='h2' sx={{ fontSize: '18px', color: 'white' }}>
                        Game Reflection
                    </Typography>
                </Box>

                <Box sx={{ p: 1, background: "", display: { xs: "flex", md: 'none' }, alignItems: "left", flexDirection: 'column', height: '110px' }}>
                    <Box sx={{ p: 1, background: "", display: { xs: "flex", md: 'none' }, alignItems: "center" }}>
                        <Icon sx={{ background: "", height: "42px", width: "42px", display: "flex", justifyContent: "center" }}>
                            <img src={IcPoliceWhite} width="85%" alt="Law & Order Icon" />
                        </Icon>
                        <Typography variant="h2" color="secondary"
                            sx={{ color: "white", fontSize: "25px", ml: '5px', }}
                        >
                            Law & Order
                        </Typography>
                    </Box>
                    <Typography variant='h2' sx={{ ml: '15px', fontSize: '18px', color: 'white' }}>
                        Game Reflection
                    </Typography>
                </Box>

                <Box sx={{ p: { xs: 0, md: 1 }, background: '', display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
                    <Box sx={{ p: 0, background: 'white', borderRadius: { md: '16px' }, display: 'flex', flexDirection: 'column', height: 'auto', width: { xs: '100%', md: '27.5%' } }}>
                        <Box sx={{ p: 3, background: "", display: "flex", alignItems: { xs: 'left', md: "left" }, flexDirection: 'column' }}>
                            <Typography variant='h2' sx={{ fontSize: '32px' }}>
                                {Object.keys(questionData).length === 0 ? '' : questionData?.categories?.[displayQuestionIndex]?.title}
                            </Typography>
                            <Divider sx={{ margin: '10px', width: '0px' }} />
                            <Typography fontWeight='bold' fontSize='18px'>
                                {Object.keys(questionData).length === 0 ? '' : questionData?.categories?.[displayQuestionIndex]?.bold_description}
                            </Typography>
                            <br />
                            <Typography fontSize='18px'>
                                {Object.keys(questionData).length === 0 ? '' : questionData?.categories?.[displayQuestionIndex]?.description}
                            </Typography>
                        </Box>
                    </Box>

                    <Divider sx={{ display: { xs: 'none', md: 'block' }, m: 1 }} />
                    <Divider sx={{ display: { xs: 'block', md: 'none', height: '12px' } }} />

                    <Box sx={{ p: 4, background: 'white', borderRadius: { md: '16px' }, display: 'flex', flexDirection: 'column', height: '100%', width: { xs: '100%', md: '45%' } }}>
                        <Typography variant='h2' fontSize='20px' sx={{ mb: { xs: '10px', md: '0' } }}>
                            WHAT IS THE PUNISHMENT?
                        </Typography>
                        <Typography variant='h2' fontSize='18px' sx={{ mt: '3%', mb: '2%' }}>
                            {Object.keys(questionData).length === 0 ? '' : questionData?.questions?.[0]?.question}
                        </Typography>
                        <Box sx={{ background: '', display: 'flex', justifyContent: 'center' }}>
                            <CustomSlider
                                sx={{ color: '#EF9654' }}
                                className='orangeSlider'
                                onChange={handleFineSlider}
                                valueLabelFormat={valueLabelFine}
                                valueLabelDisplay="auto"
                                max={100000}
                                step={1000}
                                marks={[{ value: 0, label: '0' }, { value: 100000, label: '$100,000' }]}
                            />
                        </Box>
                        <Typography variant='h2' fontSize='18px' sx={{ mt: '3%', mb: '2%' }}>
                            {Object.keys(questionData).length === 0 ? '' : questionData?.questions?.[1]?.question}
                        </Typography>
                        <Box sx={{ background: '', display: 'flex', justifyContent: 'center' }}>
                            <CustomSlider
                                sx={{ color: '#5BC73A' }}
                                className='greenSlider'
                                onChange={handleTermSlider}
                                valueLabelFormat={(value) => { return value }}
                                valueLabelDisplay="auto"
                                max={10}
                                step={1}
                                marks={[{ value: 0, label: '0' }, { value: 10, label: '10 years' }]}
                            />
                        </Box>
                        <Box sx={{ background: '', height: '90px', mt: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant='h2' fontSize='18px'>
                                {Object.keys(questionData).length === 0 ? '' : questionData?.questions?.[2]?.question}
                            </Typography>
                            <Box>
                                <CustomSwitch
                                    activated={answers[2]}
                                    inactiveText='No'
                                    activeText='Yes'
                                    callback={handleSwitch}
                                />
                            </Box>
                        </Box>
                        <Typography variant='h2' fontSize='18px' sx={{ mt: '0%' }}>
                            {Object.keys(questionData).length === 0 ? '' : questionData?.questions?.[3]?.question}
                        </Typography>
                        <Box sx={{ background: '', display: 'flex', justifyContent: 'center' }}>
                            <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center', background: '', width: '100%', mb: '20px' }}>
                                <CustomSlider
                                    sx={{ display: { xs: 'none', md: 'block' }, color: '#3276D2', width: '100%', mt: '3%' }}
                                    className='blueSlider'
                                    onChange={handleAgreeSlider}
                                    valueLabelFormat={(value) => { return }}
                                    valueLabelDisplay="off"
                                    max={3}
                                    step={1}
                                    marks={[
                                        { value: 0, label: <>Strongly<br />Disagree</> },
                                        { value: 1, label: window.screen.width > 1450 ? 'Disagree' : '' },
                                        { value: 2, label: window.screen.width > 1450 ? 'Agree' : '' },
                                        { value: 3, label: <>Strongly<br />Agree</> }
                                    ]}
                                />
                            </Box>

                            <Slider
                                sx={{ display: { xs: 'block', md: 'none' }, color: '#3276D2', width: '100%', mt: '3%' }}
                                className='blueSlider'
                                onChange={handleAgreeSlider}
                                valueLabelFormat={(value) => { return }}
                                valueLabelDisplay="off"
                                max={3}
                                step={1}
                                marks={[
                                    { value: 0, label: 'Strongly Disagree' },
                                    { value: 1 },
                                    { value: 2 },
                                    { value: 3, label: 'Strongly Agree' }
                                ]}
                            />
                        </Box>
                        <Typography variant='h2' fontSize='18px' sx={{ mt: { xs: '30px', md: '6%' } }}>
                            {Object.keys(questionData).length === 0 ? '' : questionData?.questions?.[4]?.question}
                        </Typography>
                        <TextField value={answers[4]} onChange={handleText} multiline={true} rows='4' sx={{ mt: '10px' }} />
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: '10px' }}>
                            <Button
                                sx={{ fontSize: "16px", width: "181px", height: "39px" }}
                                variant="rounded"
                                fullWidth
                                size="small"
                                onClick={async () => {
                                    if (answers[4].length == 0) {
                                        setAlert({
                                            title: "Error",
                                            icon: IcStopSign,
                                            description: "Do not leave any blanks.",
                                            buttonText: "OK",
                                            type: "OK",
                                        });
                                        return;
                                    }
                                    const newAnswers = structuredClone(answers);
                                    newAnswers[2] = answers[2] ? 'true' : 'false';
                                    await setAnswers(newAnswers);
                                    const responses = { questionIds: [1, 2, 3, 4, 5], answers: answers };
                                    const res = await postGameReflectionResponses(responses, auth.probationer.probationerId, displayQuestionIndex + 1);
                                    if (res.data.success) {
                                        if (res.data.value.endSession) {
                                            endSessionByProbationerId(auth.probationer.probationerId, 2);
                                        }
                                        setModal(
                                            <>
                                                <SceneCompletedPopup
                                                    title={questionData?.categories?.[displayQuestionIndex]?.title}
                                                    text={questionData?.categories?.[displayQuestionIndex]?.popup_text}
                                                    allCompleted={res.data.value.endSession}
                                                />
                                            </>
                                            , '500px');
                                    } else {
                                        console.log(res.data);
                                    }
                                }}
                            >
                                SUBMIT
                            </Button>
                        </Box>
                    </Box>

                    <Divider sx={{ display: { xs: 'none', md: 'block' }, m: 1 }} />
                    <Divider sx={{ display: { xs: 'block', md: 'none', height: '12px' } }} />

                    <Box sx={{ p: 0, background: '#4FAFCD', borderRadius: { md: '16px' }, display: 'flex', flexDirection: 'column', height: 'auto', width: { xs: '100%', md: '27.5%' } }}>
                        <Box sx={{ p: 2, background: "", display: "flex", alignItems: "center", }}>
                            <IcLaw size={70} color="white" />
                            <Typography variant="h2" color="secondary"
                                sx={{ color: "white", fontSize: "18px", ml: '5px', }}
                            >
                                YOUR PROGRESS
                            </Typography>
                        </Box>
                        {/* prog.map here */}
                        {reflectionProgress.map((o, i) => {
                            return (
                                <div key={i}>
                                    <Divider sx={{ background: 'white', width: '100%', mt: '' }} />
                                    <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography
                                            sx={{ color: "white", fontSize: "16px", ml: '5px', }}
                                        >
                                            {i + 1}. {o.title}
                                        </Typography>
                                        <Icon sx={{ background: "", height: "35px", width: "35px", display: "flex", justifyContent: "center" }}>
                                            {/* logic for displaying icon here */}
                                            <img src={IcCircleCheck} width="85%" alt="Check" />
                                        </Icon>
                                    </Box>
                                </div>
                            )
                        })}

                        <Divider sx={{ background: 'white', width: '100%', mt: '' }} />
                        <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography
                                sx={{ color: "white", fontSize: "16px", ml: '5px', }}
                            >
                                {Object.keys(questionData).length === 0 ?
                                    ''
                                    :
                                    reflectionProgress.length + 1 + '. ' + questionData?.categories?.[displayQuestionIndex]?.title
                                }
                            </Typography>
                        </Box>

                        <Divider sx={{ background: 'white', width: '100%', mt: '' }} />
                    </Box>
                </Box>

            </Container>
            <Container maxWidth={false} sx={styles.blackContainer}>
                <Box sx={{ fontSize: "12pt" }}>
                    The content on this website is not intended to constitute or be relied upon as legal advice. You should consult a qualified lawyer if you require legal advice.
                    <br></br>
                    ©{new Date().getFullYear()} Ministry of Social and Family Development
                </Box>

                <Box sx={{ width: { xs: "117px", md: "140px" }, height: { xs: "98px", md: "118px" } }}>
                    <img
                        style={{ width: "100%", height: "100%" }}
                        src={require("src/assets/illustration/MSF-logo-white-highres.png").default}
                        alt="White MSF Logo"
                    />
                </Box>
            </Container>
        </>
    )
}

const styles = {
    blackContainer: {
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: { xs: "left", md: "space-between" },
        alignItems: { md: "center" },
        textAlign: 'left',
        paddingTop: { xs: "20px", md: "0px" },
        background: "#000000 0% 0% no-repeat padding-box",
        height: { xs: "205px", md: "125px" },
        font: "var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 12px/16px var(--unnamed-font-family-frutiger-lt-std)",
        letterSpacing: "var(--unnamed-character-spacing-0)",
        font: "normal normal normal 12px/16px Frutiger LT Std",
        letterSpacing: "0px",
        color: "#A2A2A2",
        opacity: 1,
    }
}

export default GameReflection