import React from "react";
import moment from "moment";

import { Button, Grid, Typography } from "@mui/material";

import { useState, useEffect, useRef } from "react";
import useMediaQuery from '@mui/material/useMediaQuery';

import OfficerListSearchBar from "src/pages/officers/officers/OfficerListSearchBar";
import OfficerForm from "src/pages/officers/officers/OfficerForm";
import useModal from "src/helpers/useModal";

import Papa from "papaparse";
import { signupOfficerBulk } from "src/services/officerServices";
import { useNavigate } from "react-router-dom";

function OfficerListActions(props) {
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
    const { setSearchKeyWords, fetchOfficers } = props;
    const { setModal, closeModal } = useModal();
    const navigate = useNavigate();

    const [accounts, setAccounts] = useState([]);
    const inputFile = useRef(null);

    const openCreateAccountModal = () => {
        setModal(
            <>
                <OfficerForm createNew={true} closeModal={closeModal} action="CREATE" buttonText='CREATE ACCOUNT' fetchOfficers={fetchOfficers} />
            </>
        , '500px');
    };

    const parseCSV = file => {
        return new Promise((resolve, reject) => {
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete: function (results) {
                    let officers = structuredClone(results.data);
                    officers.forEach((account, index) => {
                        officers[index].isActivated = false;
                        officers[index].officerId = '';
                    })
                    results.data = officers;
                    return resolve(results);
                },
                error: (error) => {
                    return reject(error);
                },
            });
        })
    }

    const handleOnChange = async e => {
        const postObj = await parseCSV(e.target.files[0]);
        // console.log('postObj:', postObj.data);
        const response = await signupOfficerBulk(postObj.data);
        // console.log('RESPONSE DATA:', response.data);
    }

    // useEffect(() => console.log('accounts: ', accounts), [accounts]);

    return (
        <>
            <Grid
                container
                spacing={2}
                direction={{ xs: "column-reverse", md: "row" }}
                justifyContent="space-between"
            >
                <Grid item xs={12} md={6}>
                    <OfficerListSearchBar
                        setSearchKeyWords={setSearchKeyWords}
                    />
                </Grid>
                <Grid
                    sx={{display:"flex", justifyContent:"right"}}
                    container item md={6} spacing={1} alignContent="center">
                    
                    <input type='file' id='file' accept='.csv' ref={inputFile} style={{display: 'none'}} onChange={handleOnChange}/>
                    
                    <Grid item xs={12} md={5.5}>
                        <Button
                            sx={{fontSize:"16px", width:"100%"}}
                            variant="rounded"
                            fullWidth
                            size="small"
                            onClick={() => {
                                setAccounts([]);
                                inputFile.current.click()
                            }}
                        >
                            CREATE BULK ACCOUNTS
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={5.5}>
                        <Button
                            sx={{fontSize:"16px", width:"100%"}}
                            variant="rounded"
                            fullWidth
                            size="small"
                            onClick={isSmallScreen? () => {navigate('create-new-account')} :  openCreateAccountModal}
                        >
                            CREATE NEW ACCOUNT
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default OfficerListActions;
