import * as React from "react";
import Box from "@mui/material/Box";
import { Outlet } from "react-router-dom";
import AppBar from "src/components/AppBar";
import OfficersSidebar from "./OfficersSidebar";
import { Typography } from "@mui/material";

const drawerWidth = 260;
const footerHeight = 100;

const OfficersLayout = (props) => {
    const [mobileOpen, setMobileOpen] = React.useState(false);

    return (
        <>
            <AppBar toggleSideBar={() => setMobileOpen((mobileOpen) => !mobileOpen)} />
            <Box sx={{ display: "flex" }}>
                <OfficersSidebar
                    drawerWidth={drawerWidth}
                    mobileOpen={mobileOpen}
                    handleDrawerToggle={() => setMobileOpen((mobileOpen) => !mobileOpen)}
                    user={props.user}
                    gameProgress={props.gameProgress}
                />
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        padding: "40px 30px",
                        height: { xs: "calc(100vh - 60px - 76px)", sm: "calc(100vh - 60px)" },
                        overflow: "scroll",
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                    }}
                >
                    <Outlet />
                </Box>
            </Box>
            <Box
                sx={{
                    backgroundColor: "#000000",
                    color: "#A2A2A2",
                    paddingInline: "17px",
                    height: "60px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    position: { xs: "relative", sm: "fixed" },
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: "9999",
                }}
            >
                <Typography>©{new Date().getFullYear()} Ministry of Social and Family Development</Typography>
                <img src={require("src/assets/illustration/MSF-logo-white-highres.png").default} height="40px" />
            </Box>
        </>
    );
};

export default OfficersLayout;
