import { React, useEffect, useState } from 'react'
import {
    Button,
    Box,
    Card,
    CardMedia,
    Divider,
    FormControl,
    MenuItem,
    InputLabel,
    Select,
    TextField,
    Typography
    } from "@mui/material";
import useModal from "src/helpers/useModal";
import useAlert from 'src/helpers/useAlert';

import { useNavigate } from "react-router-dom"
import useSession from 'src/hooks/useSession';
import useAuth from 'src/hooks/useAuth';
import { IcShakeHandBlack, IcCheck, IcStopSign } from "src/assets/icons";

import { endSessionByProbationerId } from 'src/services/gameServices';
import { getVISectionResources } from 'src/services/victimimpactServices';

import Header from '../../../../components/Header';
import AnswersSavedPopup from '../../popups/AnswersSavedPopup';
import WellDonePopup from '../../popups/WellDonePopup';

import {
    getActiveSession,
    getVISectionQuestionData,
    postVictimImpactResponses,
    endVictimImpactSession,
    getDraftAnswers,
    getVictimImpactProgress
} from 'src/services/victimimpactServices';

import ReactHlsPlayer from '@ducanh2912/react-hls-player';

const MakingAmends2 = () => {
    const navigate = useNavigate();
    const { session, setSession } = useSession();
    const { auth } = useAuth();
    var count = 0;

    const { setModal } = useModal();
    const { setAlert } = useAlert();

    const [ resources, setResources ] = useState([{}, {videourl:''}]);
    const [ questionData, setQuestionData ] = useState([]);
    const [ answers, setAnswers ] = useState({
        select: ['', ''],
        text: '',
    });

    const handleSelect = (i) => event => {
        let newAnswers = structuredClone(answers);
        newAnswers.select[i] = event.target.value;
        if (!answers.select.includes(event.target.value)) {
            setAnswers(newAnswers);
        }
    };

    const handleText = event => {
        let newAnswers = structuredClone(answers);
        newAnswers.text = event.target.value;
        setAnswers(newAnswers);
    }

    const handleSubmit = async() => {
        // ensure answers filled
        if (answers.select.includes('') || answers.text === '') {
            setAlert({
                title: "Error",
                icon: IcStopSign,
                description: "Do not leave any blanks.",
                buttonText: "OK",
                type: "OK"
            });
            return
        }
        // console.log('answers: ', answers);
        let postObj = {isDraft: 0, response: {primary_amendment: '', secondary_amendment: '', answer: ''}}
        postObj.response.primary_amendment = answers.select[0]+1;
        postObj.response.secondary_amendment = answers.select[1]+1;
        postObj.response.answer = answers.text;

        const response = await postVictimImpactResponses(4, session.MA_sessionId, postObj);
        if (response.data.success) {
            setModal(
                <>
                    <WellDonePopup/>
                </>
            , '462px');
            await endSessionByProbationerId(auth.probationer.probationerId, 1);
            await endVictimImpactSession(session.MA_sessionId);
            // Get Sidebar VI Progress
            const viProgress = await getVictimImpactProgress(auth.probationer.probationerId);
            const newSession = structuredClone(session);
            newSession.VI_progress = viProgress.data.value.VI_progress;
            await setSession(newSession);
            await setAnswers({ select: ['', ''], text: '', })
            navigate('/players/home');
        } else {
            setAlert({
                title: "Error",
                icon: IcStopSign,
                description: "Answers have not been submitted.",
                buttonText: "OK",
                type: "OK"
            });
        }
    }

	useEffect(() => {
        const fetchData = async() => {
            count++;
            const resourceRes = await getVISectionResources(4);
            setResources(resourceRes.data.value);
            // get Types of Hurts questions
            const response = await getVISectionQuestionData(3);
            if (response.data.success) {
                // Get ToV sessionId
                const res = await getActiveSession(auth.probationer.probationerId, 4);
                if (res.data.success) {
                    const newSession = structuredClone(session);
                    newSession.MA_sessionId = res.data.value;
                    await setSession(newSession);
                }
                // Set Question Data
                const val = response.data.value;
                let qd = [];
                for (const a of val) {
                    qd.push(a.amendment);
                }
                await setQuestionData(qd);
            }
        }
        if (count < 1) {
            fetchData();
        }
    }, []);

    useEffect(() => {
        const fetchDraft = async() => {
            if ('MA_sessionId' in session && questionData.length !== 0) {
                const res = await getDraftAnswers(4, session.MA_sessionId);
                if (res.data.value.length !== 0) {
                    const draft = res.data.value;
                    let newAnswers = structuredClone(answers);
                    newAnswers.select[0] = Number(draft.primary_amendment) - 1;
                    newAnswers.select[1] = Number(draft.secondary_amendment) - 1;
                    newAnswers.text = draft.answer;
                    setAnswers(newAnswers);
                }
            }
        }
        fetchDraft();
    }, [session]);

    return (
        <>
            <Header
                title='Making Amends'
                text='Suggest ways to show the victims that you are sorry for your actions'
                icon={IcShakeHandBlack}
                iconSize='85%'/>

           <Divider sx={{ mt: {xs: '10px', md:"0px"}, mb: {xs: "20px", md: "40px"}}}/>

            <Box sx={{
                    paddingTop: 0,
                    paddingLeft: 2,
                    paddingRight: 2,
                    // background: "lightblue",
                    display:"flex",
                    alignItems:"center",
                    flexDirection:"column",
                    height: {xs: "600px", md: "850px"},
                    width: {xs:"100%"},
                    }}
                    maxWidth="false">
                <Box sx={{ background: "", }}>
                    <Box sx={{ display: "flex", alignItems:"center", flexDirection:"column", mt: "20px" }}>
                        <Box
                            sx={{width:{xs: "100%", md: "720px"}, height:{xs: "100%", md: "405px"}, mb:'50px'}}
                            >
                            <ReactHlsPlayer
                                // src="https://unity-dashboard.s3.ap-southeast-1.amazonaws.com/videos/Live/CostofCrime_Ex-Probationer_Experience/CostofCrime_Ex-Probationer_Experiencemediaconvert_8500kbits.m3u8"
                                src={resources[1].videourl}
                                autoPlay={false}
                                controls={false}
                                width="100%"
                            />
                        </Box>
                    </Box>

                    <Box sx={{ width:"100%", background:"", display:'flex', flexDirection:"column", justifyContent:"left", alignItems:"left"}}>
                        <Typography textAlign={'left'} sx={{mb:"15px", fontSize:"16px"}}>
                            Suggest ways to show the victims that you are sorry for your actions<span style={{ color: "red" }}>*</span>
                        </Typography>
                    </Box>
                    <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={answers.select[0]}
                                label="Select"
                                onChange={handleSelect(0)}
                            >
                                {questionData.map((e, i) => 
                                    <MenuItem key={i} value={i}>{e}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        <Divider sx={{height:"15px", width:'0px'}}/>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={answers.select[1]}
                                label="Select"
                                onChange={handleSelect(1)}
                            >
                                {questionData.map((e, i) => 
                                    <MenuItem key={i} value={i}>{e}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Box>

                    <Typography textAlign={'left'} sx={{mt:"15px", fontSize:"16px"}}>
                        What is another way you can make amends to the victim?<span style={{ color: "red" }}>*</span>
                    </Typography>
                    
                    <TextField fullWidth value={answers.text} onChange={handleText} multiline={true} rows='4'></TextField>

                    <Typography textAlign={'left'} sx={{mt:"15px", fontSize:"16px"}}>
                        <span style={{ color: "red" }}>*</span>Mandatory
                    </Typography>
                    
                </Box>
            </Box>



            <Divider sx={{display:{xs:"none", md:"block"}, width:"0px", height:"50px"}}/>
            <Divider sx={{display:{xs:"block", md:"none"}, width:"0px", height:"90px"}}/>
            <Box sx={{ display:'flex', height:{xs: "120px", md:"100px"}, background: "", justifyContent:"center", flexDirection:{xs:"column", md:"row"}, alignItems:{xs:"center", md:"stretch"}, }}>
                <Button
                    sx={{background:'white', color: "#4FAFCD", border: '1px solid #4FAFCD', fontSize:"16px", width:{xs:"90%", md:"239px"}, height:"39px", }}
                    variant="rounded"
                    fullWidth
                    size="small"
                    onClick={async () => {
                        let postObj = {isDraft: 1, response: {primary_amendment: '', secondary_amendment: '', answer: ''}}
                        postObj.response.primary_amendment = answers.select[0]+1;
                        postObj.response.secondary_amendment = answers.select[1]+1;
                        postObj.response.answer = answers.text;
                
                        const response = await postVictimImpactResponses(4, session.MA_sessionId, postObj);
                        if (response.data.success) {
                            setModal(
                                <>
                                    <AnswersSavedPopup/>
                                    {/* <WellDonePopup/> */}
                                </>
                            , '462px');
                        } else {
                            setAlert({
                                title: "Error",
                                icon: IcStopSign,
                                description: response.data.error,
                                buttonText: "OK",
                                type: "OK"
                            });
                        }

                    }}
                >
                    SAVE & CONTINUE LATER
                </Button>

                <Divider sx={{ display: {xs:"none", md:"block"}, height:"0", width:"10px" }}/>
                <Divider sx={{ display: {xs:"block", md:"none"}, height:"10px", width:"0px" }}/>

                <Button
                    sx={{fontSize:"16px", width:{xs:"90%", md:"239px"}, height:"39px" }}
                    variant="rounded"
                    fullWidth
                    size="small"
                    onClick={handleSubmit}
                >
                    SUBMIT
                </Button>
            </Box>
        </>
    )
}

export default MakingAmends2