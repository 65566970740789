import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RequireAuth = ({ userType }) => {
    const { auth } = useAuth();
    const location = useLocation();

    if (auth.isActivated) {
        return <Outlet />; 
    }
    if (auth.officer) {
        if (auth.officer.officerId) {
            return <Outlet />; 
        }
    }
    if (userType==="Player") {
        return <Navigate to="/client-login" state={{ from: location }} replace />;
    }
    else {
        return <Navigate to="/officer-login" state={{ from: location }} replace />;
    }
}

export default RequireAuth;