import React, { useState, useEffect } from "react";

import {
    Divider,
    Typography,
    TableContainer,
    Table,
    TableBody,
    styled,
    TableCell,
    TableRow,
    tableCellClasses,
    useTheme,
} from "@mui/material";
import OfficerListActions from "./OfficerListActions";
import { Link } from "react-router-dom";
import { getOfficerById, getOfficers } from "src/services/officerServices";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "td, th": {
        border: 0,
    },
}));

const OfficerList = () => {
    const theme = useTheme();
    const [searchKeyWords, setSearchKeyWords] = useState("");
    const [officers, setOfficers] = useState([])
    const [filteredOfficers, setFilteredOfficers] = useState([]);

    const fetchOfficers = async () => {
        const response = await getOfficers();
        const { data } = response;
        // console.log('data:', data)
        setOfficers(data.value)
    }
    useEffect(()=>  {
        fetchOfficers()
    }, [])
    

    useEffect(() => {
        if (searchKeyWords){
            setFilteredOfficers(
                officers.filter((officer) =>
                    officer.fullname
                        .toLowerCase()
                        .includes(searchKeyWords.toLowerCase())
                )
            );
        } else {
            setFilteredOfficers(officers)
        }
    }, [searchKeyWords, officers]);

    return (
        <div>
            <Typography variant="h2" color="secondary">
                Officers
            </Typography>
            <Divider sx={{ mt: "15px", mb: "25px" }} />
            <OfficerListActions setSearchKeyWords={setSearchKeyWords} fetchOfficers={fetchOfficers} />
            <TableContainer sx={{ mt: "20px" }}>
                <Table aria-label="customized table">
                    <TableBody>
                        {filteredOfficers.map((officer) => (
                            <StyledTableRow key={officer.officerId}>
                                <StyledTableCell component="th" scope="row">
                                    {officer.fullname}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <Link
                                        to={`/officers/officers/${officer.officerId}`}
                                        style={{
                                            color: theme.palette.primary.main,
                                        }}
                                    >
                                        View Details
                                    </Link>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default OfficerList;
