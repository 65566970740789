import React from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import useAlert from "src/helpers/useAlert";
import useModal from "src/helpers/useModal";
import {
    Button,
    Box,
    FormControl,
    TextField,
    Typography,
} from "@mui/material";

import { requestPasswordResetEmail, deleteAccountByProbationerId,deleteAccountByOfficerId } from 'src/services/authServices';
import { verifyProbationer } from "src/services/probationerServices";

import { IcCheck, IcStopSign } from "src/assets/icons";

const validationSchema = yup.object({
    confirmation: yup
        .string("Enter 'ARCHIVE' to confirm")
        .required("Please enter 'ARCHIVE' to confirm")
        .matches(/ARCHIVE/, "Please enter 'ARCHIVE' to confirm"),
});

const DeleteAccountPopup = (props) => {
    const { action } = props;
    const { closeModal } = useModal();
    const { setAlert } = useAlert();
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            confirmation: ""
        },
        // validationSchema: validationSchema,
    });

    const handleSubmit = async (values) => {
        // console.log(values);
        // call request password-reset email api
        let response;
        if (props.type === "probationer") {
            response = await deleteAccountByProbationerId(props.id);
        } else {
            response = await deleteAccountByOfficerId(props.id);
        }

        // console.log('response: ', response);
        closeModal();
        // const response = {}
        if (response.data.success) {
            setAlert({
                icon: IcCheck,
                title: "Request Successful",
                description: "Account Archived",
                buttonText: "OK",
                type: "OK",
            });
            closeModal();
        } else {
            setAlert({
                icon: IcStopSign,
                title: "Request Failed",
                description: response.data.error,
                buttonText: "OK",
                type: "OK",
            });
        }
    }

    return (
        <>
            <Typography align="center" fontSize="14pt" sx={{ color: "#000000" }}>
                To archive the account, please type 'ARCHIVE' in the box below. {JSON.stringify(action)}
            </Typography>
            <div>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <FormControl sx={{ my: 1, width: { xs: "234px", md: "345px" }, }}>
                        <TextField
                            fullWidth
                            id="confirmation"
                            name="confirmation"
                            label={
                                formik.values.confirmation === "" ?
                                    "Type 'ARCHIVE' to confirm"
                                    : ""
                            }
                            InputLabelProps={{ shrink: false, sx: { color: "#0" } }}
                            value={formik.values.confirmation}
                            onChange={formik.handleChange}
                            error={formik.touched.confirmation && Boolean(formik.errors.confirmation)}
                            helperText={formik.touched.confirmation && formik.errors.confirmation}
                            sx={{ maxWidth: "488px", color: "blue" }}
                        />
                    </FormControl>
                    <Button
                        color="green"
                        variant="contained"
                        sx={{ fontSize: "14pt", width: { xs: "234px", md: "345px" } }}
                        onClick={handleSubmit}
                    >
                        ARCHIVE ACCOUNT
                    </Button>
                </Box>
            </div>
        </>
    );
};

export default DeleteAccountPopup;
